import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type GetFaxOrderSeedQueryVariables = Types.Exact<{
  orderSeedId: Types.Scalars['ID']['input'];
}>;

export type GetFaxOrderSeedQuery = {
  __typename?: 'Query';
  orderSeed?: {
    __typename?: 'OrderSeedType';
    id: string;
    status: Types.AppOrderSeedStatusChoices;
    primaryExtractor: Types.AppOrderSeedPrimaryExtractorChoices;
    extractionResultsSet?: {
      __typename?: 'ExtractionResultsSetType';
      id: string;
      synthpopClinicalSummary?: string | null;
      orderFieldRequirements: string;
      patientFirstNameRaw?: string | null;
      patientFirstNameCorrected?: string | null;
      patientFirstNameConfidence?: Types.AppExtractionResultsSetPatientFirstNameConfidenceChoices | null;
      patientMiddleNameRaw?: string | null;
      patientMiddleNameCorrected?: string | null;
      patientMiddleNameConfidence?: Types.AppExtractionResultsSetPatientMiddleNameConfidenceChoices | null;
      patientLastNameRaw?: string | null;
      patientLastNameCorrected?: string | null;
      patientLastNameConfidence?: Types.AppExtractionResultsSetPatientLastNameConfidenceChoices | null;
      patientDateOfBirthRaw?: string | null;
      patientDateOfBirthCorrected?: string | null;
      patientDateOfBirthConfidence?: Types.AppExtractionResultsSetPatientDateOfBirthConfidenceChoices | null;
      patientSexRaw?: string | null;
      patientSexCorrected?: string | null;
      patientSexConfidence?: Types.AppExtractionResultsSetPatientSexConfidenceChoices | null;
      patientEmailAddressRaw?: string | null;
      patientEmailAddressCorrected?: string | null;
      patientEmailAddressConfidence?: Types.AppExtractionResultsSetPatientEmailAddressConfidenceChoices | null;
      patientHeightInInchesRaw?: number | null;
      patientHeightInInchesCorrected?: number | null;
      patientHeightInInchesConfidence?: Types.AppExtractionResultsSetPatientHeightInInchesConfidenceChoices | null;
      patientWeightInPoundsRaw?: number | null;
      patientWeightInPoundsCorrected?: number | null;
      patientWeightInPoundsConfidence?: Types.AppExtractionResultsSetPatientWeightInPoundsConfidenceChoices | null;
      patientCellPhoneRaw?: string | null;
      patientCellPhoneCorrected?: string | null;
      patientCellPhoneConfidence?: Types.AppExtractionResultsSetPatientCellPhoneConfidenceChoices | null;
      patientHomePhoneRaw?: string | null;
      patientHomePhoneCorrected?: string | null;
      patientHomePhoneConfidence?: Types.AppExtractionResultsSetPatientHomePhoneConfidenceChoices | null;
      providerFirstNameRaw?: string | null;
      providerFirstNameCorrected?: string | null;
      providerFirstNameConfidence?: Types.AppExtractionResultsSetProviderFirstNameConfidenceChoices | null;
      providerLastNameRaw?: string | null;
      providerLastNameCorrected?: string | null;
      providerLastNameConfidence?: Types.AppExtractionResultsSetProviderLastNameConfidenceChoices | null;
      providerNpiRaw?: string | null;
      providerNpiCorrected?: string | null;
      providerNpiConfidence?: Types.AppExtractionResultsSetProviderNpiConfidenceChoices | null;
      supplierNameRaw?: string | null;
      supplierNameCorrected?: string | null;
      supplierNameConfidence?: Types.AppExtractionResultsSetSupplierNameConfidenceChoices | null;
      referralDateRaw?: string | null;
      referralDateCorrected?: string | null;
      referralDateConfidence?: Types.AppExtractionResultsSetReferralDateConfidenceChoices | null;
      subcategoryNameRaw?: string | null;
      subcategoryNameCorrected?: string | null;
      subcategoryNameConfidence?: Types.AppExtractionResultsSetSubcategoryNameConfidenceChoices | null;
      subcategoryIdRaw?: string | null;
      subcategoryIdCorrected?: string | null;
      bloodGasStudyRaw?: string | null;
      bloodGasStudyCorrected?: string | null;
      bloodGasStudyConfidence?: Types.AppExtractionResultsSetBloodGasStudyConfidenceChoices | null;
      sleepStudyRaw?: string | null;
      sleepStudyCorrected?: string | null;
      sleepStudyConfidence?: Types.AppExtractionResultsSetSleepStudyConfidenceChoices | null;
      chartNotesRaw?: string | null;
      chartNotesCorrected?: string | null;
      chartNotesConfidence?: Types.AppExtractionResultsSetChartNotesConfidenceChoices | null;
      coverSheetRaw?: string | null;
      coverSheetCorrected?: string | null;
      coverSheetConfidence?: Types.AppExtractionResultsSetCoverSheetConfidenceChoices | null;
      providerSignaturePresentRaw?: boolean | null;
      providerSignaturePresentCorrected?: boolean | null;
      providerSignaturePresentConfidence?: Types.AppExtractionResultsSetProviderSignaturePresentConfidenceChoices | null;
      prescriptionRaw?: string | null;
      prescriptionCorrected?: string | null;
      prescriptionConfidence?: Types.AppExtractionResultsSetPrescriptionConfidenceChoices | null;
      patientAhiRaw?: number | null;
      patientAhiCorrected?: number | null;
      patientAhiConfidence?: Types.AppExtractionResultsSetPatientAhiConfidenceChoices | null;
      patientTotalSleepTimeRaw?: number | null;
      patientTotalSleepTimeCorrected?: number | null;
      patientTotalSleepTimeConfidence?: Types.AppExtractionResultsSetPatientTotalSleepTimeConfidenceChoices | null;
      patientReiRaw?: number | null;
      patientReiCorrected?: number | null;
      patientReiConfidence?: Types.AppExtractionResultsSetPatientReiConfidenceChoices | null;
      patientRdiRaw?: number | null;
      patientRdiCorrected?: number | null;
      patientRdiConfidence?: Types.AppExtractionResultsSetPatientRdiConfidenceChoices | null;
      patientNeckSizeRaw?: string | null;
      patientNeckSizeCorrected?: string | null;
      patientNeckSizeConfidence?: Types.AppExtractionResultsSetPatientNeckSizeConfidenceChoices | null;
      patientNeckCircumferenceInInchesRaw?: number | null;
      patientNeckCircumferenceInInchesCorrected?: number | null;
      patientNeckCircumferenceInInchesConfidence?: Types.AppExtractionResultsSetPatientNeckCircumferenceInInchesConfidenceChoices | null;
      patientLastSeenDateRaw?: string | null;
      patientLastSeenDateCorrected?: string | null;
      patientLastSeenDateConfidence?: Types.AppExtractionResultsSetPatientLastSeenDateConfidenceChoices | null;
      patientHypopneaScoreRaw?: number | null;
      patientHypopneaScoreCorrected?: number | null;
      patientHypopneaScoreConfidence?: Types.AppExtractionResultsSetPatientHypopneaScoreConfidenceChoices | null;
      patientEssRaw?: number | null;
      patientEssCorrected?: number | null;
      patientEssConfidence?: Types.AppExtractionResultsSetPatientEssConfidenceChoices | null;
      patientBpRaw?: string | null;
      patientBpCorrected?: string | null;
      patientBpConfidence?: Types.AppExtractionResultsSetPatientBpConfidenceChoices | null;
      patientBmiRaw?: number | null;
      patientBmiCorrected?: number | null;
      patientBmiConfidence?: Types.AppExtractionResultsSetPatientBmiConfidenceChoices | null;
      normalizedCoordinates?: {
        __typename?: 'CoordinatesSetType';
        patientFirstName?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        patientMiddleName?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        patientLastName?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        patientSex?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        patientCellPhone?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        patientDateOfBirth?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        patientEmailAddress?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        patientHomePhone?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        patientHeightInInches?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        patientWeightInPounds?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        patientHomeAddress?: {
          __typename?: 'CoordinatesAddressResultType';
          address1?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          address2?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          city?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          postalCode?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          state?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null;
        patientShippingAddress?: {
          __typename?: 'CoordinatesAddressResultType';
          address1?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          address2?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          city?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          postalCode?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          state?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null;
        patientPrimaryInsurance?: {
          __typename?: 'CoordinatesInsuranceType';
          groupNumber?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          insurerName?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          planName?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          policyNumber?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null;
        patientSecondaryInsurance?: {
          __typename?: 'CoordinatesInsuranceType';
          groupNumber?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          insurerName?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          planName?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          policyNumber?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null;
        patientTertiaryInsurance?: {
          __typename?: 'CoordinatesInsuranceType';
          groupNumber?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          insurerName?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          planName?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          policyNumber?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null;
        referringProviderFirstName?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        referringProviderLastName?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        referringProviderNpi?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        orderReferralDate?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        orderSubcategoryName?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        lineItems?: Array<{
          __typename?: 'CoordinatesLineItemType';
          hcpcsCode?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          quantity?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null> | null;
        dxCodes?: Array<{
          __typename?: 'CoordinatesDiagnosisType';
          diagnosisCode?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      homeAddress: {
        __typename?: 'ExtractionAddressResultType';
        address1Raw?: string | null;
        address1Corrected?: string | null;
        address1Confidence?: Types.AppExtractionAddressResultAddress1ConfidenceChoices | null;
        address2Raw?: string | null;
        address2Corrected?: string | null;
        address2Confidence?: Types.AppExtractionAddressResultAddress2ConfidenceChoices | null;
        cityRaw?: string | null;
        cityCorrected?: string | null;
        cityConfidence?: Types.AppExtractionAddressResultCityConfidenceChoices | null;
        stateRaw?: string | null;
        stateCorrected?: string | null;
        stateConfidence?: Types.AppExtractionAddressResultStateConfidenceChoices | null;
        postalCodeRaw?: string | null;
        postalCodeCorrected?: string | null;
        postalCodeConfidence?: Types.AppExtractionAddressResultPostalCodeConfidenceChoices | null;
      };
      shippingAddress: {
        __typename?: 'ExtractionAddressResultType';
        address1Raw?: string | null;
        address1Corrected?: string | null;
        address1Confidence?: Types.AppExtractionAddressResultAddress1ConfidenceChoices | null;
        address2Raw?: string | null;
        address2Corrected?: string | null;
        address2Confidence?: Types.AppExtractionAddressResultAddress2ConfidenceChoices | null;
        cityRaw?: string | null;
        cityCorrected?: string | null;
        cityConfidence?: Types.AppExtractionAddressResultCityConfidenceChoices | null;
        stateRaw?: string | null;
        stateCorrected?: string | null;
        stateConfidence?: Types.AppExtractionAddressResultStateConfidenceChoices | null;
        postalCodeRaw?: string | null;
        postalCodeCorrected?: string | null;
        postalCodeConfidence?: Types.AppExtractionAddressResultPostalCodeConfidenceChoices | null;
      };
      primaryInsurance: {
        __typename?: 'ExtractionInsuranceResultType';
        insurerNameRaw?: string | null;
        insurerNameCorrected?: string | null;
        insurerNameConfidence?: Types.AppExtractionInsuranceResultInsurerNameConfidenceChoices | null;
        planNameRaw?: string | null;
        planNameCorrected?: string | null;
        planNameConfidence?: Types.AppExtractionInsuranceResultPlanNameConfidenceChoices | null;
        groupNumberRaw?: string | null;
        groupNumberCorrected?: string | null;
        groupNumberConfidence?: Types.AppExtractionInsuranceResultGroupNumberConfidenceChoices | null;
        policyNumberRaw?: string | null;
        policyNumberCorrected?: string | null;
        policyNumberConfidence?: Types.AppExtractionInsuranceResultPolicyNumberConfidenceChoices | null;
      };
      secondaryInsurance: {
        __typename?: 'ExtractionInsuranceResultType';
        insurerNameRaw?: string | null;
        insurerNameCorrected?: string | null;
        insurerNameConfidence?: Types.AppExtractionInsuranceResultInsurerNameConfidenceChoices | null;
        planNameRaw?: string | null;
        planNameCorrected?: string | null;
        planNameConfidence?: Types.AppExtractionInsuranceResultPlanNameConfidenceChoices | null;
        groupNumberRaw?: string | null;
        groupNumberCorrected?: string | null;
        groupNumberConfidence?: Types.AppExtractionInsuranceResultGroupNumberConfidenceChoices | null;
        policyNumberRaw?: string | null;
        policyNumberCorrected?: string | null;
        policyNumberConfidence?: Types.AppExtractionInsuranceResultPolicyNumberConfidenceChoices | null;
      };
      tertiaryInsurance: {
        __typename?: 'ExtractionInsuranceResultType';
        insurerNameRaw?: string | null;
        insurerNameCorrected?: string | null;
        insurerNameConfidence?: Types.AppExtractionInsuranceResultInsurerNameConfidenceChoices | null;
        planNameRaw?: string | null;
        planNameCorrected?: string | null;
        planNameConfidence?: Types.AppExtractionInsuranceResultPlanNameConfidenceChoices | null;
        groupNumberRaw?: string | null;
        groupNumberCorrected?: string | null;
        groupNumberConfidence?: Types.AppExtractionInsuranceResultGroupNumberConfidenceChoices | null;
        policyNumberRaw?: string | null;
        policyNumberCorrected?: string | null;
        policyNumberConfidence?: Types.AppExtractionInsuranceResultPolicyNumberConfidenceChoices | null;
      };
      dxCodes: Array<{
        __typename?: 'ExtractionDiagnosisResultType';
        id: string;
        diagnosisCodeRaw?: string | null;
        diagnosisCodeCorrected?: string | null;
        diagnosisCodeConfidence?: Types.AppExtractionDiagnosisResultDiagnosisCodeConfidenceChoices | null;
      }>;
      lineItems: Array<{
        __typename?: 'ExtractionLineItemResultType';
        id: string;
        quantityRaw?: number | null;
        quantityCorrected?: number | null;
        quantityConfidence?: Types.AppExtractionLineItemResultQuantityConfidenceChoices | null;
        hcpcsCodeRaw?: string | null;
        hcpcsCodeCorrected?: string | null;
        hcpcsCodeConfidence?: Types.AppExtractionLineItemResultHcpcsCodeConfidenceChoices | null;
        lengthOfNeedRaw?: string | null;
        lengthOfNeedCorrected?: string | null;
        lengthOfNeedConfidence?: Types.AppExtractionLineItemResultLengthOfNeedConfidenceChoices | null;
        productNameRaw?: string | null;
        productNameCorrected?: string | null;
        productNameConfidence?: Types.AppExtractionLineItemResultProductNameConfidenceChoices | null;
        brandNameRaw?: string | null;
        brandNameCorrected?: string | null;
        brandNameConfidence?: Types.AppExtractionLineItemResultBrandNameConfidenceChoices | null;
        frequencyRaw?: string | null;
        frequencyCorrected?: string | null;
        frequencyConfidence?: Types.AppExtractionLineItemResultFrequencyConfidenceChoices | null;
        cptCodeRaw?: string | null;
        cptCodeCorrected?: string | null;
        cptCodeConfidence?: Types.AppExtractionLineItemResultCptCodeConfidenceChoices | null;
      }>;
    } | null;
    documentExtractionJob: {
      __typename?: 'DocumentExtractionJobType';
      id: string;
      senderFaxNumber?: string | null;
      isThvFaxForwardingJob?: boolean | null;
      searchableDocument?: { __typename?: 'DocumentType'; id: string } | null;
      document: { __typename?: 'DocumentType'; id: string };
    };
    order?: { __typename?: 'OrderType'; id: string } | null;
  } | null;
};

export type FaxOrderSeedFragment = {
  __typename?: 'OrderSeedType';
  id: string;
  status: Types.AppOrderSeedStatusChoices;
  primaryExtractor: Types.AppOrderSeedPrimaryExtractorChoices;
  extractionResultsSet?: {
    __typename?: 'ExtractionResultsSetType';
    id: string;
    synthpopClinicalSummary?: string | null;
    orderFieldRequirements: string;
    patientFirstNameRaw?: string | null;
    patientFirstNameCorrected?: string | null;
    patientFirstNameConfidence?: Types.AppExtractionResultsSetPatientFirstNameConfidenceChoices | null;
    patientMiddleNameRaw?: string | null;
    patientMiddleNameCorrected?: string | null;
    patientMiddleNameConfidence?: Types.AppExtractionResultsSetPatientMiddleNameConfidenceChoices | null;
    patientLastNameRaw?: string | null;
    patientLastNameCorrected?: string | null;
    patientLastNameConfidence?: Types.AppExtractionResultsSetPatientLastNameConfidenceChoices | null;
    patientDateOfBirthRaw?: string | null;
    patientDateOfBirthCorrected?: string | null;
    patientDateOfBirthConfidence?: Types.AppExtractionResultsSetPatientDateOfBirthConfidenceChoices | null;
    patientSexRaw?: string | null;
    patientSexCorrected?: string | null;
    patientSexConfidence?: Types.AppExtractionResultsSetPatientSexConfidenceChoices | null;
    patientEmailAddressRaw?: string | null;
    patientEmailAddressCorrected?: string | null;
    patientEmailAddressConfidence?: Types.AppExtractionResultsSetPatientEmailAddressConfidenceChoices | null;
    patientHeightInInchesRaw?: number | null;
    patientHeightInInchesCorrected?: number | null;
    patientHeightInInchesConfidence?: Types.AppExtractionResultsSetPatientHeightInInchesConfidenceChoices | null;
    patientWeightInPoundsRaw?: number | null;
    patientWeightInPoundsCorrected?: number | null;
    patientWeightInPoundsConfidence?: Types.AppExtractionResultsSetPatientWeightInPoundsConfidenceChoices | null;
    patientCellPhoneRaw?: string | null;
    patientCellPhoneCorrected?: string | null;
    patientCellPhoneConfidence?: Types.AppExtractionResultsSetPatientCellPhoneConfidenceChoices | null;
    patientHomePhoneRaw?: string | null;
    patientHomePhoneCorrected?: string | null;
    patientHomePhoneConfidence?: Types.AppExtractionResultsSetPatientHomePhoneConfidenceChoices | null;
    providerFirstNameRaw?: string | null;
    providerFirstNameCorrected?: string | null;
    providerFirstNameConfidence?: Types.AppExtractionResultsSetProviderFirstNameConfidenceChoices | null;
    providerLastNameRaw?: string | null;
    providerLastNameCorrected?: string | null;
    providerLastNameConfidence?: Types.AppExtractionResultsSetProviderLastNameConfidenceChoices | null;
    providerNpiRaw?: string | null;
    providerNpiCorrected?: string | null;
    providerNpiConfidence?: Types.AppExtractionResultsSetProviderNpiConfidenceChoices | null;
    supplierNameRaw?: string | null;
    supplierNameCorrected?: string | null;
    supplierNameConfidence?: Types.AppExtractionResultsSetSupplierNameConfidenceChoices | null;
    referralDateRaw?: string | null;
    referralDateCorrected?: string | null;
    referralDateConfidence?: Types.AppExtractionResultsSetReferralDateConfidenceChoices | null;
    subcategoryNameRaw?: string | null;
    subcategoryNameCorrected?: string | null;
    subcategoryNameConfidence?: Types.AppExtractionResultsSetSubcategoryNameConfidenceChoices | null;
    subcategoryIdRaw?: string | null;
    subcategoryIdCorrected?: string | null;
    bloodGasStudyRaw?: string | null;
    bloodGasStudyCorrected?: string | null;
    bloodGasStudyConfidence?: Types.AppExtractionResultsSetBloodGasStudyConfidenceChoices | null;
    sleepStudyRaw?: string | null;
    sleepStudyCorrected?: string | null;
    sleepStudyConfidence?: Types.AppExtractionResultsSetSleepStudyConfidenceChoices | null;
    chartNotesRaw?: string | null;
    chartNotesCorrected?: string | null;
    chartNotesConfidence?: Types.AppExtractionResultsSetChartNotesConfidenceChoices | null;
    coverSheetRaw?: string | null;
    coverSheetCorrected?: string | null;
    coverSheetConfidence?: Types.AppExtractionResultsSetCoverSheetConfidenceChoices | null;
    providerSignaturePresentRaw?: boolean | null;
    providerSignaturePresentCorrected?: boolean | null;
    providerSignaturePresentConfidence?: Types.AppExtractionResultsSetProviderSignaturePresentConfidenceChoices | null;
    prescriptionRaw?: string | null;
    prescriptionCorrected?: string | null;
    prescriptionConfidence?: Types.AppExtractionResultsSetPrescriptionConfidenceChoices | null;
    patientAhiRaw?: number | null;
    patientAhiCorrected?: number | null;
    patientAhiConfidence?: Types.AppExtractionResultsSetPatientAhiConfidenceChoices | null;
    patientTotalSleepTimeRaw?: number | null;
    patientTotalSleepTimeCorrected?: number | null;
    patientTotalSleepTimeConfidence?: Types.AppExtractionResultsSetPatientTotalSleepTimeConfidenceChoices | null;
    patientReiRaw?: number | null;
    patientReiCorrected?: number | null;
    patientReiConfidence?: Types.AppExtractionResultsSetPatientReiConfidenceChoices | null;
    patientRdiRaw?: number | null;
    patientRdiCorrected?: number | null;
    patientRdiConfidence?: Types.AppExtractionResultsSetPatientRdiConfidenceChoices | null;
    patientNeckSizeRaw?: string | null;
    patientNeckSizeCorrected?: string | null;
    patientNeckSizeConfidence?: Types.AppExtractionResultsSetPatientNeckSizeConfidenceChoices | null;
    patientNeckCircumferenceInInchesRaw?: number | null;
    patientNeckCircumferenceInInchesCorrected?: number | null;
    patientNeckCircumferenceInInchesConfidence?: Types.AppExtractionResultsSetPatientNeckCircumferenceInInchesConfidenceChoices | null;
    patientLastSeenDateRaw?: string | null;
    patientLastSeenDateCorrected?: string | null;
    patientLastSeenDateConfidence?: Types.AppExtractionResultsSetPatientLastSeenDateConfidenceChoices | null;
    patientHypopneaScoreRaw?: number | null;
    patientHypopneaScoreCorrected?: number | null;
    patientHypopneaScoreConfidence?: Types.AppExtractionResultsSetPatientHypopneaScoreConfidenceChoices | null;
    patientEssRaw?: number | null;
    patientEssCorrected?: number | null;
    patientEssConfidence?: Types.AppExtractionResultsSetPatientEssConfidenceChoices | null;
    patientBpRaw?: string | null;
    patientBpCorrected?: string | null;
    patientBpConfidence?: Types.AppExtractionResultsSetPatientBpConfidenceChoices | null;
    patientBmiRaw?: number | null;
    patientBmiCorrected?: number | null;
    patientBmiConfidence?: Types.AppExtractionResultsSetPatientBmiConfidenceChoices | null;
    normalizedCoordinates?: {
      __typename?: 'CoordinatesSetType';
      patientFirstName?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      patientMiddleName?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      patientLastName?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      patientSex?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      patientCellPhone?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      patientDateOfBirth?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      patientEmailAddress?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      patientHomePhone?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      patientHeightInInches?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      patientWeightInPounds?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      patientHomeAddress?: {
        __typename?: 'CoordinatesAddressResultType';
        address1?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        address2?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        city?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        postalCode?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        state?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
      } | null;
      patientShippingAddress?: {
        __typename?: 'CoordinatesAddressResultType';
        address1?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        address2?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        city?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        postalCode?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        state?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
      } | null;
      patientPrimaryInsurance?: {
        __typename?: 'CoordinatesInsuranceType';
        groupNumber?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        insurerName?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        planName?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        policyNumber?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
      } | null;
      patientSecondaryInsurance?: {
        __typename?: 'CoordinatesInsuranceType';
        groupNumber?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        insurerName?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        planName?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        policyNumber?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
      } | null;
      patientTertiaryInsurance?: {
        __typename?: 'CoordinatesInsuranceType';
        groupNumber?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        insurerName?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        planName?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        policyNumber?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
      } | null;
      referringProviderFirstName?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      referringProviderLastName?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      referringProviderNpi?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      orderReferralDate?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      orderSubcategoryName?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      lineItems?: Array<{
        __typename?: 'CoordinatesLineItemType';
        hcpcsCode?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
        quantity?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
      } | null> | null;
      dxCodes?: Array<{
        __typename?: 'CoordinatesDiagnosisType';
        diagnosisCode?: {
          __typename?: 'LocationInfoType';
          page?: number | null;
          boundingBox?: {
            __typename?: 'BoundingBoxType';
            height?: number | null;
            left?: number | null;
            top?: number | null;
            width?: number | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    homeAddress: {
      __typename?: 'ExtractionAddressResultType';
      address1Raw?: string | null;
      address1Corrected?: string | null;
      address1Confidence?: Types.AppExtractionAddressResultAddress1ConfidenceChoices | null;
      address2Raw?: string | null;
      address2Corrected?: string | null;
      address2Confidence?: Types.AppExtractionAddressResultAddress2ConfidenceChoices | null;
      cityRaw?: string | null;
      cityCorrected?: string | null;
      cityConfidence?: Types.AppExtractionAddressResultCityConfidenceChoices | null;
      stateRaw?: string | null;
      stateCorrected?: string | null;
      stateConfidence?: Types.AppExtractionAddressResultStateConfidenceChoices | null;
      postalCodeRaw?: string | null;
      postalCodeCorrected?: string | null;
      postalCodeConfidence?: Types.AppExtractionAddressResultPostalCodeConfidenceChoices | null;
    };
    shippingAddress: {
      __typename?: 'ExtractionAddressResultType';
      address1Raw?: string | null;
      address1Corrected?: string | null;
      address1Confidence?: Types.AppExtractionAddressResultAddress1ConfidenceChoices | null;
      address2Raw?: string | null;
      address2Corrected?: string | null;
      address2Confidence?: Types.AppExtractionAddressResultAddress2ConfidenceChoices | null;
      cityRaw?: string | null;
      cityCorrected?: string | null;
      cityConfidence?: Types.AppExtractionAddressResultCityConfidenceChoices | null;
      stateRaw?: string | null;
      stateCorrected?: string | null;
      stateConfidence?: Types.AppExtractionAddressResultStateConfidenceChoices | null;
      postalCodeRaw?: string | null;
      postalCodeCorrected?: string | null;
      postalCodeConfidence?: Types.AppExtractionAddressResultPostalCodeConfidenceChoices | null;
    };
    primaryInsurance: {
      __typename?: 'ExtractionInsuranceResultType';
      insurerNameRaw?: string | null;
      insurerNameCorrected?: string | null;
      insurerNameConfidence?: Types.AppExtractionInsuranceResultInsurerNameConfidenceChoices | null;
      planNameRaw?: string | null;
      planNameCorrected?: string | null;
      planNameConfidence?: Types.AppExtractionInsuranceResultPlanNameConfidenceChoices | null;
      groupNumberRaw?: string | null;
      groupNumberCorrected?: string | null;
      groupNumberConfidence?: Types.AppExtractionInsuranceResultGroupNumberConfidenceChoices | null;
      policyNumberRaw?: string | null;
      policyNumberCorrected?: string | null;
      policyNumberConfidence?: Types.AppExtractionInsuranceResultPolicyNumberConfidenceChoices | null;
    };
    secondaryInsurance: {
      __typename?: 'ExtractionInsuranceResultType';
      insurerNameRaw?: string | null;
      insurerNameCorrected?: string | null;
      insurerNameConfidence?: Types.AppExtractionInsuranceResultInsurerNameConfidenceChoices | null;
      planNameRaw?: string | null;
      planNameCorrected?: string | null;
      planNameConfidence?: Types.AppExtractionInsuranceResultPlanNameConfidenceChoices | null;
      groupNumberRaw?: string | null;
      groupNumberCorrected?: string | null;
      groupNumberConfidence?: Types.AppExtractionInsuranceResultGroupNumberConfidenceChoices | null;
      policyNumberRaw?: string | null;
      policyNumberCorrected?: string | null;
      policyNumberConfidence?: Types.AppExtractionInsuranceResultPolicyNumberConfidenceChoices | null;
    };
    tertiaryInsurance: {
      __typename?: 'ExtractionInsuranceResultType';
      insurerNameRaw?: string | null;
      insurerNameCorrected?: string | null;
      insurerNameConfidence?: Types.AppExtractionInsuranceResultInsurerNameConfidenceChoices | null;
      planNameRaw?: string | null;
      planNameCorrected?: string | null;
      planNameConfidence?: Types.AppExtractionInsuranceResultPlanNameConfidenceChoices | null;
      groupNumberRaw?: string | null;
      groupNumberCorrected?: string | null;
      groupNumberConfidence?: Types.AppExtractionInsuranceResultGroupNumberConfidenceChoices | null;
      policyNumberRaw?: string | null;
      policyNumberCorrected?: string | null;
      policyNumberConfidence?: Types.AppExtractionInsuranceResultPolicyNumberConfidenceChoices | null;
    };
    dxCodes: Array<{
      __typename?: 'ExtractionDiagnosisResultType';
      id: string;
      diagnosisCodeRaw?: string | null;
      diagnosisCodeCorrected?: string | null;
      diagnosisCodeConfidence?: Types.AppExtractionDiagnosisResultDiagnosisCodeConfidenceChoices | null;
    }>;
    lineItems: Array<{
      __typename?: 'ExtractionLineItemResultType';
      id: string;
      quantityRaw?: number | null;
      quantityCorrected?: number | null;
      quantityConfidence?: Types.AppExtractionLineItemResultQuantityConfidenceChoices | null;
      hcpcsCodeRaw?: string | null;
      hcpcsCodeCorrected?: string | null;
      hcpcsCodeConfidence?: Types.AppExtractionLineItemResultHcpcsCodeConfidenceChoices | null;
      lengthOfNeedRaw?: string | null;
      lengthOfNeedCorrected?: string | null;
      lengthOfNeedConfidence?: Types.AppExtractionLineItemResultLengthOfNeedConfidenceChoices | null;
      productNameRaw?: string | null;
      productNameCorrected?: string | null;
      productNameConfidence?: Types.AppExtractionLineItemResultProductNameConfidenceChoices | null;
      brandNameRaw?: string | null;
      brandNameCorrected?: string | null;
      brandNameConfidence?: Types.AppExtractionLineItemResultBrandNameConfidenceChoices | null;
      frequencyRaw?: string | null;
      frequencyCorrected?: string | null;
      frequencyConfidence?: Types.AppExtractionLineItemResultFrequencyConfidenceChoices | null;
      cptCodeRaw?: string | null;
      cptCodeCorrected?: string | null;
      cptCodeConfidence?: Types.AppExtractionLineItemResultCptCodeConfidenceChoices | null;
    }>;
  } | null;
  documentExtractionJob: {
    __typename?: 'DocumentExtractionJobType';
    id: string;
    senderFaxNumber?: string | null;
    isThvFaxForwardingJob?: boolean | null;
    searchableDocument?: { __typename?: 'DocumentType'; id: string } | null;
    document: { __typename?: 'DocumentType'; id: string };
  };
  order?: { __typename?: 'OrderType'; id: string } | null;
};

export type ExtractionResultsFragment = {
  __typename?: 'ExtractionResultsSetType';
  id: string;
  synthpopClinicalSummary?: string | null;
  orderFieldRequirements: string;
  patientFirstNameRaw?: string | null;
  patientFirstNameCorrected?: string | null;
  patientFirstNameConfidence?: Types.AppExtractionResultsSetPatientFirstNameConfidenceChoices | null;
  patientMiddleNameRaw?: string | null;
  patientMiddleNameCorrected?: string | null;
  patientMiddleNameConfidence?: Types.AppExtractionResultsSetPatientMiddleNameConfidenceChoices | null;
  patientLastNameRaw?: string | null;
  patientLastNameCorrected?: string | null;
  patientLastNameConfidence?: Types.AppExtractionResultsSetPatientLastNameConfidenceChoices | null;
  patientDateOfBirthRaw?: string | null;
  patientDateOfBirthCorrected?: string | null;
  patientDateOfBirthConfidence?: Types.AppExtractionResultsSetPatientDateOfBirthConfidenceChoices | null;
  patientSexRaw?: string | null;
  patientSexCorrected?: string | null;
  patientSexConfidence?: Types.AppExtractionResultsSetPatientSexConfidenceChoices | null;
  patientEmailAddressRaw?: string | null;
  patientEmailAddressCorrected?: string | null;
  patientEmailAddressConfidence?: Types.AppExtractionResultsSetPatientEmailAddressConfidenceChoices | null;
  patientHeightInInchesRaw?: number | null;
  patientHeightInInchesCorrected?: number | null;
  patientHeightInInchesConfidence?: Types.AppExtractionResultsSetPatientHeightInInchesConfidenceChoices | null;
  patientWeightInPoundsRaw?: number | null;
  patientWeightInPoundsCorrected?: number | null;
  patientWeightInPoundsConfidence?: Types.AppExtractionResultsSetPatientWeightInPoundsConfidenceChoices | null;
  patientCellPhoneRaw?: string | null;
  patientCellPhoneCorrected?: string | null;
  patientCellPhoneConfidence?: Types.AppExtractionResultsSetPatientCellPhoneConfidenceChoices | null;
  patientHomePhoneRaw?: string | null;
  patientHomePhoneCorrected?: string | null;
  patientHomePhoneConfidence?: Types.AppExtractionResultsSetPatientHomePhoneConfidenceChoices | null;
  providerFirstNameRaw?: string | null;
  providerFirstNameCorrected?: string | null;
  providerFirstNameConfidence?: Types.AppExtractionResultsSetProviderFirstNameConfidenceChoices | null;
  providerLastNameRaw?: string | null;
  providerLastNameCorrected?: string | null;
  providerLastNameConfidence?: Types.AppExtractionResultsSetProviderLastNameConfidenceChoices | null;
  providerNpiRaw?: string | null;
  providerNpiCorrected?: string | null;
  providerNpiConfidence?: Types.AppExtractionResultsSetProviderNpiConfidenceChoices | null;
  supplierNameRaw?: string | null;
  supplierNameCorrected?: string | null;
  supplierNameConfidence?: Types.AppExtractionResultsSetSupplierNameConfidenceChoices | null;
  referralDateRaw?: string | null;
  referralDateCorrected?: string | null;
  referralDateConfidence?: Types.AppExtractionResultsSetReferralDateConfidenceChoices | null;
  subcategoryNameRaw?: string | null;
  subcategoryNameCorrected?: string | null;
  subcategoryNameConfidence?: Types.AppExtractionResultsSetSubcategoryNameConfidenceChoices | null;
  subcategoryIdRaw?: string | null;
  subcategoryIdCorrected?: string | null;
  bloodGasStudyRaw?: string | null;
  bloodGasStudyCorrected?: string | null;
  bloodGasStudyConfidence?: Types.AppExtractionResultsSetBloodGasStudyConfidenceChoices | null;
  sleepStudyRaw?: string | null;
  sleepStudyCorrected?: string | null;
  sleepStudyConfidence?: Types.AppExtractionResultsSetSleepStudyConfidenceChoices | null;
  chartNotesRaw?: string | null;
  chartNotesCorrected?: string | null;
  chartNotesConfidence?: Types.AppExtractionResultsSetChartNotesConfidenceChoices | null;
  coverSheetRaw?: string | null;
  coverSheetCorrected?: string | null;
  coverSheetConfidence?: Types.AppExtractionResultsSetCoverSheetConfidenceChoices | null;
  providerSignaturePresentRaw?: boolean | null;
  providerSignaturePresentCorrected?: boolean | null;
  providerSignaturePresentConfidence?: Types.AppExtractionResultsSetProviderSignaturePresentConfidenceChoices | null;
  prescriptionRaw?: string | null;
  prescriptionCorrected?: string | null;
  prescriptionConfidence?: Types.AppExtractionResultsSetPrescriptionConfidenceChoices | null;
  patientAhiRaw?: number | null;
  patientAhiCorrected?: number | null;
  patientAhiConfidence?: Types.AppExtractionResultsSetPatientAhiConfidenceChoices | null;
  patientTotalSleepTimeRaw?: number | null;
  patientTotalSleepTimeCorrected?: number | null;
  patientTotalSleepTimeConfidence?: Types.AppExtractionResultsSetPatientTotalSleepTimeConfidenceChoices | null;
  patientReiRaw?: number | null;
  patientReiCorrected?: number | null;
  patientReiConfidence?: Types.AppExtractionResultsSetPatientReiConfidenceChoices | null;
  patientRdiRaw?: number | null;
  patientRdiCorrected?: number | null;
  patientRdiConfidence?: Types.AppExtractionResultsSetPatientRdiConfidenceChoices | null;
  patientNeckSizeRaw?: string | null;
  patientNeckSizeCorrected?: string | null;
  patientNeckSizeConfidence?: Types.AppExtractionResultsSetPatientNeckSizeConfidenceChoices | null;
  patientNeckCircumferenceInInchesRaw?: number | null;
  patientNeckCircumferenceInInchesCorrected?: number | null;
  patientNeckCircumferenceInInchesConfidence?: Types.AppExtractionResultsSetPatientNeckCircumferenceInInchesConfidenceChoices | null;
  patientLastSeenDateRaw?: string | null;
  patientLastSeenDateCorrected?: string | null;
  patientLastSeenDateConfidence?: Types.AppExtractionResultsSetPatientLastSeenDateConfidenceChoices | null;
  patientHypopneaScoreRaw?: number | null;
  patientHypopneaScoreCorrected?: number | null;
  patientHypopneaScoreConfidence?: Types.AppExtractionResultsSetPatientHypopneaScoreConfidenceChoices | null;
  patientEssRaw?: number | null;
  patientEssCorrected?: number | null;
  patientEssConfidence?: Types.AppExtractionResultsSetPatientEssConfidenceChoices | null;
  patientBpRaw?: string | null;
  patientBpCorrected?: string | null;
  patientBpConfidence?: Types.AppExtractionResultsSetPatientBpConfidenceChoices | null;
  patientBmiRaw?: number | null;
  patientBmiCorrected?: number | null;
  patientBmiConfidence?: Types.AppExtractionResultsSetPatientBmiConfidenceChoices | null;
  normalizedCoordinates?: {
    __typename?: 'CoordinatesSetType';
    patientFirstName?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    patientMiddleName?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    patientLastName?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    patientSex?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    patientCellPhone?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    patientDateOfBirth?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    patientEmailAddress?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    patientHomePhone?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    patientHeightInInches?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    patientWeightInPounds?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    patientHomeAddress?: {
      __typename?: 'CoordinatesAddressResultType';
      address1?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      address2?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      city?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      postalCode?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      state?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
    } | null;
    patientShippingAddress?: {
      __typename?: 'CoordinatesAddressResultType';
      address1?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      address2?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      city?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      postalCode?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      state?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
    } | null;
    patientPrimaryInsurance?: {
      __typename?: 'CoordinatesInsuranceType';
      groupNumber?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      insurerName?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      planName?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      policyNumber?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
    } | null;
    patientSecondaryInsurance?: {
      __typename?: 'CoordinatesInsuranceType';
      groupNumber?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      insurerName?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      planName?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      policyNumber?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
    } | null;
    patientTertiaryInsurance?: {
      __typename?: 'CoordinatesInsuranceType';
      groupNumber?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      insurerName?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      planName?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      policyNumber?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
    } | null;
    referringProviderFirstName?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    referringProviderLastName?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    referringProviderNpi?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    orderReferralDate?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    orderSubcategoryName?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    lineItems?: Array<{
      __typename?: 'CoordinatesLineItemType';
      hcpcsCode?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
      quantity?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
    } | null> | null;
    dxCodes?: Array<{
      __typename?: 'CoordinatesDiagnosisType';
      diagnosisCode?: {
        __typename?: 'LocationInfoType';
        page?: number | null;
        boundingBox?: {
          __typename?: 'BoundingBoxType';
          height?: number | null;
          left?: number | null;
          top?: number | null;
          width?: number | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  homeAddress: {
    __typename?: 'ExtractionAddressResultType';
    address1Raw?: string | null;
    address1Corrected?: string | null;
    address1Confidence?: Types.AppExtractionAddressResultAddress1ConfidenceChoices | null;
    address2Raw?: string | null;
    address2Corrected?: string | null;
    address2Confidence?: Types.AppExtractionAddressResultAddress2ConfidenceChoices | null;
    cityRaw?: string | null;
    cityCorrected?: string | null;
    cityConfidence?: Types.AppExtractionAddressResultCityConfidenceChoices | null;
    stateRaw?: string | null;
    stateCorrected?: string | null;
    stateConfidence?: Types.AppExtractionAddressResultStateConfidenceChoices | null;
    postalCodeRaw?: string | null;
    postalCodeCorrected?: string | null;
    postalCodeConfidence?: Types.AppExtractionAddressResultPostalCodeConfidenceChoices | null;
  };
  shippingAddress: {
    __typename?: 'ExtractionAddressResultType';
    address1Raw?: string | null;
    address1Corrected?: string | null;
    address1Confidence?: Types.AppExtractionAddressResultAddress1ConfidenceChoices | null;
    address2Raw?: string | null;
    address2Corrected?: string | null;
    address2Confidence?: Types.AppExtractionAddressResultAddress2ConfidenceChoices | null;
    cityRaw?: string | null;
    cityCorrected?: string | null;
    cityConfidence?: Types.AppExtractionAddressResultCityConfidenceChoices | null;
    stateRaw?: string | null;
    stateCorrected?: string | null;
    stateConfidence?: Types.AppExtractionAddressResultStateConfidenceChoices | null;
    postalCodeRaw?: string | null;
    postalCodeCorrected?: string | null;
    postalCodeConfidence?: Types.AppExtractionAddressResultPostalCodeConfidenceChoices | null;
  };
  primaryInsurance: {
    __typename?: 'ExtractionInsuranceResultType';
    insurerNameRaw?: string | null;
    insurerNameCorrected?: string | null;
    insurerNameConfidence?: Types.AppExtractionInsuranceResultInsurerNameConfidenceChoices | null;
    planNameRaw?: string | null;
    planNameCorrected?: string | null;
    planNameConfidence?: Types.AppExtractionInsuranceResultPlanNameConfidenceChoices | null;
    groupNumberRaw?: string | null;
    groupNumberCorrected?: string | null;
    groupNumberConfidence?: Types.AppExtractionInsuranceResultGroupNumberConfidenceChoices | null;
    policyNumberRaw?: string | null;
    policyNumberCorrected?: string | null;
    policyNumberConfidence?: Types.AppExtractionInsuranceResultPolicyNumberConfidenceChoices | null;
  };
  secondaryInsurance: {
    __typename?: 'ExtractionInsuranceResultType';
    insurerNameRaw?: string | null;
    insurerNameCorrected?: string | null;
    insurerNameConfidence?: Types.AppExtractionInsuranceResultInsurerNameConfidenceChoices | null;
    planNameRaw?: string | null;
    planNameCorrected?: string | null;
    planNameConfidence?: Types.AppExtractionInsuranceResultPlanNameConfidenceChoices | null;
    groupNumberRaw?: string | null;
    groupNumberCorrected?: string | null;
    groupNumberConfidence?: Types.AppExtractionInsuranceResultGroupNumberConfidenceChoices | null;
    policyNumberRaw?: string | null;
    policyNumberCorrected?: string | null;
    policyNumberConfidence?: Types.AppExtractionInsuranceResultPolicyNumberConfidenceChoices | null;
  };
  tertiaryInsurance: {
    __typename?: 'ExtractionInsuranceResultType';
    insurerNameRaw?: string | null;
    insurerNameCorrected?: string | null;
    insurerNameConfidence?: Types.AppExtractionInsuranceResultInsurerNameConfidenceChoices | null;
    planNameRaw?: string | null;
    planNameCorrected?: string | null;
    planNameConfidence?: Types.AppExtractionInsuranceResultPlanNameConfidenceChoices | null;
    groupNumberRaw?: string | null;
    groupNumberCorrected?: string | null;
    groupNumberConfidence?: Types.AppExtractionInsuranceResultGroupNumberConfidenceChoices | null;
    policyNumberRaw?: string | null;
    policyNumberCorrected?: string | null;
    policyNumberConfidence?: Types.AppExtractionInsuranceResultPolicyNumberConfidenceChoices | null;
  };
  dxCodes: Array<{
    __typename?: 'ExtractionDiagnosisResultType';
    id: string;
    diagnosisCodeRaw?: string | null;
    diagnosisCodeCorrected?: string | null;
    diagnosisCodeConfidence?: Types.AppExtractionDiagnosisResultDiagnosisCodeConfidenceChoices | null;
  }>;
  lineItems: Array<{
    __typename?: 'ExtractionLineItemResultType';
    id: string;
    quantityRaw?: number | null;
    quantityCorrected?: number | null;
    quantityConfidence?: Types.AppExtractionLineItemResultQuantityConfidenceChoices | null;
    hcpcsCodeRaw?: string | null;
    hcpcsCodeCorrected?: string | null;
    hcpcsCodeConfidence?: Types.AppExtractionLineItemResultHcpcsCodeConfidenceChoices | null;
    lengthOfNeedRaw?: string | null;
    lengthOfNeedCorrected?: string | null;
    lengthOfNeedConfidence?: Types.AppExtractionLineItemResultLengthOfNeedConfidenceChoices | null;
    productNameRaw?: string | null;
    productNameCorrected?: string | null;
    productNameConfidence?: Types.AppExtractionLineItemResultProductNameConfidenceChoices | null;
    brandNameRaw?: string | null;
    brandNameCorrected?: string | null;
    brandNameConfidence?: Types.AppExtractionLineItemResultBrandNameConfidenceChoices | null;
    frequencyRaw?: string | null;
    frequencyCorrected?: string | null;
    frequencyConfidence?: Types.AppExtractionLineItemResultFrequencyConfidenceChoices | null;
    cptCodeRaw?: string | null;
    cptCodeCorrected?: string | null;
    cptCodeConfidence?: Types.AppExtractionLineItemResultCptCodeConfidenceChoices | null;
  }>;
};

export type ExtractionCoordinatesFragment = {
  __typename?: 'CoordinatesSetType';
  patientFirstName?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  patientMiddleName?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  patientLastName?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  patientSex?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  patientCellPhone?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  patientDateOfBirth?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  patientEmailAddress?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  patientHomePhone?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  patientHeightInInches?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  patientWeightInPounds?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  patientHomeAddress?: {
    __typename?: 'CoordinatesAddressResultType';
    address1?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    address2?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    city?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    postalCode?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    state?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
  } | null;
  patientShippingAddress?: {
    __typename?: 'CoordinatesAddressResultType';
    address1?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    address2?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    city?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    postalCode?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    state?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
  } | null;
  patientPrimaryInsurance?: {
    __typename?: 'CoordinatesInsuranceType';
    groupNumber?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    insurerName?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    planName?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    policyNumber?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
  } | null;
  patientSecondaryInsurance?: {
    __typename?: 'CoordinatesInsuranceType';
    groupNumber?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    insurerName?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    planName?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    policyNumber?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
  } | null;
  patientTertiaryInsurance?: {
    __typename?: 'CoordinatesInsuranceType';
    groupNumber?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    insurerName?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    planName?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    policyNumber?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
  } | null;
  referringProviderFirstName?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  referringProviderLastName?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  referringProviderNpi?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  orderReferralDate?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  orderSubcategoryName?: {
    __typename?: 'LocationInfoType';
    page?: number | null;
    boundingBox?: {
      __typename?: 'BoundingBoxType';
      height?: number | null;
      left?: number | null;
      top?: number | null;
      width?: number | null;
    } | null;
  } | null;
  lineItems?: Array<{
    __typename?: 'CoordinatesLineItemType';
    hcpcsCode?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
    quantity?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
  } | null> | null;
  dxCodes?: Array<{
    __typename?: 'CoordinatesDiagnosisType';
    diagnosisCode?: {
      __typename?: 'LocationInfoType';
      page?: number | null;
      boundingBox?: {
        __typename?: 'BoundingBoxType';
        height?: number | null;
        left?: number | null;
        top?: number | null;
        width?: number | null;
      } | null;
    } | null;
  } | null> | null;
};

export const ExtractionCoordinatesFragmentDoc = `
    fragment ExtractionCoordinatesFragment on CoordinatesSetType {
  patientFirstName {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  patientMiddleName {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  patientLastName {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  patientSex {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  patientCellPhone {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  patientDateOfBirth {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  patientEmailAddress {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  patientHomePhone {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  patientHeightInInches {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  patientWeightInPounds {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  patientHomeAddress {
    address1 {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    address2 {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    city {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    postalCode {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    state {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
  }
  patientShippingAddress {
    address1 {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    address2 {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    city {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    postalCode {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    state {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
  }
  patientPrimaryInsurance {
    groupNumber {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    insurerName {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    planName {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    policyNumber {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
  }
  patientSecondaryInsurance {
    groupNumber {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    insurerName {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    planName {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    policyNumber {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
  }
  patientTertiaryInsurance {
    groupNumber {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    insurerName {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    planName {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    policyNumber {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
  }
  referringProviderFirstName {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  referringProviderLastName {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  referringProviderNpi {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  orderReferralDate {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  orderSubcategoryName {
    boundingBox {
      height
      left
      top
      width
    }
    page
  }
  lineItems {
    hcpcsCode {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
    quantity {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
  }
  dxCodes {
    diagnosisCode {
      boundingBox {
        height
        left
        top
        width
      }
      page
    }
  }
}
    `;
export const ExtractionResultsFragmentDoc = `
    fragment ExtractionResultsFragment on ExtractionResultsSetType {
  id
  synthpopClinicalSummary
  orderFieldRequirements
  normalizedCoordinates {
    ...ExtractionCoordinatesFragment
  }
  patientFirstNameRaw
  patientFirstNameCorrected
  patientFirstNameConfidence
  patientMiddleNameRaw
  patientMiddleNameCorrected
  patientMiddleNameConfidence
  patientLastNameRaw
  patientLastNameCorrected
  patientLastNameConfidence
  patientDateOfBirthRaw
  patientDateOfBirthCorrected
  patientDateOfBirthConfidence
  patientSexRaw
  patientSexCorrected
  patientSexConfidence
  patientEmailAddressRaw
  patientEmailAddressCorrected
  patientEmailAddressConfidence
  patientHeightInInchesRaw
  patientHeightInInchesCorrected
  patientHeightInInchesConfidence
  patientWeightInPoundsRaw
  patientWeightInPoundsCorrected
  patientWeightInPoundsConfidence
  patientCellPhoneRaw
  patientCellPhoneCorrected
  patientCellPhoneConfidence
  patientHomePhoneRaw
  patientHomePhoneCorrected
  patientHomePhoneConfidence
  providerFirstNameRaw
  providerFirstNameCorrected
  providerFirstNameConfidence
  providerLastNameRaw
  providerLastNameCorrected
  providerLastNameConfidence
  providerNpiRaw
  providerNpiCorrected
  providerNpiConfidence
  homeAddress {
    address1Raw
    address1Corrected
    address1Confidence
    address2Raw
    address2Corrected
    address2Confidence
    cityRaw
    cityCorrected
    cityConfidence
    stateRaw
    stateCorrected
    stateConfidence
    postalCodeRaw
    postalCodeCorrected
    postalCodeConfidence
  }
  shippingAddress {
    address1Raw
    address1Corrected
    address1Confidence
    address2Raw
    address2Corrected
    address2Confidence
    cityRaw
    cityCorrected
    cityConfidence
    stateRaw
    stateCorrected
    stateConfidence
    postalCodeRaw
    postalCodeCorrected
    postalCodeConfidence
  }
  primaryInsurance {
    insurerNameRaw
    insurerNameCorrected
    insurerNameConfidence
    planNameRaw
    planNameCorrected
    planNameConfidence
    groupNumberRaw
    groupNumberCorrected
    groupNumberConfidence
    policyNumberRaw
    policyNumberCorrected
    policyNumberConfidence
  }
  secondaryInsurance {
    insurerNameRaw
    insurerNameCorrected
    insurerNameConfidence
    planNameRaw
    planNameCorrected
    planNameConfidence
    groupNumberRaw
    groupNumberCorrected
    groupNumberConfidence
    policyNumberRaw
    policyNumberCorrected
    policyNumberConfidence
  }
  tertiaryInsurance {
    insurerNameRaw
    insurerNameCorrected
    insurerNameConfidence
    planNameRaw
    planNameCorrected
    planNameConfidence
    groupNumberRaw
    groupNumberCorrected
    groupNumberConfidence
    policyNumberRaw
    policyNumberCorrected
    policyNumberConfidence
  }
  supplierNameRaw
  supplierNameCorrected
  supplierNameConfidence
  referralDateRaw
  referralDateCorrected
  referralDateConfidence
  subcategoryNameRaw
  subcategoryNameCorrected
  subcategoryNameConfidence
  subcategoryIdRaw
  subcategoryIdCorrected
  dxCodes {
    id
    diagnosisCodeRaw
    diagnosisCodeCorrected
    diagnosisCodeConfidence
  }
  lineItems {
    id
    quantityRaw
    quantityCorrected
    quantityConfidence
    hcpcsCodeRaw
    hcpcsCodeCorrected
    hcpcsCodeConfidence
    lengthOfNeedRaw
    lengthOfNeedCorrected
    lengthOfNeedConfidence
    productNameRaw
    productNameCorrected
    productNameConfidence
    brandNameRaw
    brandNameCorrected
    brandNameConfidence
    frequencyRaw
    frequencyCorrected
    frequencyConfidence
    cptCodeRaw
    cptCodeCorrected
    cptCodeConfidence
  }
  bloodGasStudyRaw
  bloodGasStudyCorrected
  bloodGasStudyConfidence
  sleepStudyRaw
  sleepStudyCorrected
  sleepStudyConfidence
  chartNotesRaw
  chartNotesCorrected
  chartNotesConfidence
  coverSheetRaw
  coverSheetCorrected
  coverSheetConfidence
  providerSignaturePresentRaw
  providerSignaturePresentCorrected
  providerSignaturePresentConfidence
  prescriptionRaw
  prescriptionCorrected
  prescriptionConfidence
  patientAhiRaw
  patientAhiCorrected
  patientAhiConfidence
  patientTotalSleepTimeRaw
  patientTotalSleepTimeCorrected
  patientTotalSleepTimeConfidence
  patientReiRaw
  patientReiCorrected
  patientReiConfidence
  patientRdiRaw
  patientRdiCorrected
  patientRdiConfidence
  patientNeckSizeRaw
  patientNeckSizeCorrected
  patientNeckSizeConfidence
  patientNeckCircumferenceInInchesRaw
  patientNeckCircumferenceInInchesCorrected
  patientNeckCircumferenceInInchesConfidence
  patientLastSeenDateRaw
  patientLastSeenDateCorrected
  patientLastSeenDateConfidence
  patientHypopneaScoreRaw
  patientHypopneaScoreCorrected
  patientHypopneaScoreConfidence
  patientEssRaw
  patientEssCorrected
  patientEssConfidence
  patientBpRaw
  patientBpCorrected
  patientBpConfidence
  patientBmiRaw
  patientBmiCorrected
  patientBmiConfidence
}
    `;
export const FaxOrderSeedFragmentDoc = `
    fragment FaxOrderSeedFragment on OrderSeedType {
  id
  status
  primaryExtractor
  extractionResultsSet {
    ...ExtractionResultsFragment
  }
  documentExtractionJob {
    id
    senderFaxNumber
    isThvFaxForwardingJob
    searchableDocument {
      id
    }
    document {
      id
    }
  }
  order {
    id
  }
}
    `;
export const GetFaxOrderSeedDocument = `
    query getFaxOrderSeed($orderSeedId: ID!) {
  orderSeed(orderSeedId: $orderSeedId) {
    ...FaxOrderSeedFragment
  }
}
    ${FaxOrderSeedFragmentDoc}
${ExtractionResultsFragmentDoc}
${ExtractionCoordinatesFragmentDoc}`;

export const useGetFaxOrderSeedQuery = <TData = GetFaxOrderSeedQuery, TError = unknown>(
  variables: GetFaxOrderSeedQueryVariables,
  options?: Omit<UseQueryOptions<GetFaxOrderSeedQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetFaxOrderSeedQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetFaxOrderSeedQuery, TError, TData>({
    queryKey: ['getFaxOrderSeed', variables],
    queryFn: fetcher<GetFaxOrderSeedQuery, GetFaxOrderSeedQueryVariables>(GetFaxOrderSeedDocument, variables),
    ...options,
  });
};

useGetFaxOrderSeedQuery.getKey = (variables: GetFaxOrderSeedQueryVariables) => ['getFaxOrderSeed', variables];

export const useInfiniteGetFaxOrderSeedQuery = <TData = InfiniteData<GetFaxOrderSeedQuery>, TError = unknown>(
  variables: GetFaxOrderSeedQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetFaxOrderSeedQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetFaxOrderSeedQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetFaxOrderSeedQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getFaxOrderSeed.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetFaxOrderSeedQuery, GetFaxOrderSeedQueryVariables>(GetFaxOrderSeedDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetFaxOrderSeedQuery.getKey = (variables: GetFaxOrderSeedQueryVariables) => [
  'getFaxOrderSeed.infinite',
  variables,
];

useGetFaxOrderSeedQuery.fetcher = (variables: GetFaxOrderSeedQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GetFaxOrderSeedQuery, GetFaxOrderSeedQueryVariables>(GetFaxOrderSeedDocument, variables, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetFaxOrderSeedQuery(
 *   ({ query, variables }) => {
 *     const { orderSeedId } = variables;
 *     return HttpResponse.json({
 *       data: { orderSeed }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetFaxOrderSeedQuery = (
  resolver: GraphQLResponseResolver<GetFaxOrderSeedQuery, GetFaxOrderSeedQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GetFaxOrderSeedQuery, GetFaxOrderSeedQueryVariables>('getFaxOrderSeed', resolver, options);
