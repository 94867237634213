import { formatISO, parseISO } from 'date-fns';

type DateAPIRepresentation = NonNullable<Parameters<typeof formatISO>[1]>['representation'];

export function formatDate(date: Date, representation?: DateAPIRepresentation): string;
export function formatDate(date: null | undefined, representation?: DateAPIRepresentation): null;
export function formatDate(date: Date | null | undefined, representation?: DateAPIRepresentation): string | null;
export function formatDate(date: Date | null | undefined, representation: DateAPIRepresentation = 'complete') {
  return date ? formatISO(date, { representation }) : null;
}

export function parseDate(date: string): Date;
export function parseDate(date: null | undefined): null;
export function parseDate(date: string | null | undefined): Date | null;
export function parseDate(date: string | null | undefined) {
  return date !== null && date !== undefined ? parseISO(date) : null;
}

export const dateFormats = {
  date: new Intl.DateTimeFormat(undefined, { dateStyle: 'medium' }),
  dateAndTime: new Intl.DateTimeFormat(undefined, { dateStyle: 'medium', timeStyle: 'short' }),
} as const;

export function displayDate(date: Date, format?: Intl.DateTimeFormat): string;
export function displayDate(date: null | undefined, format?: Intl.DateTimeFormat): null;
export function displayDate(date: Date | null | undefined, format?: Intl.DateTimeFormat): string | null;
export function displayDate(date: Date | null | undefined, format: Intl.DateTimeFormat = dateFormats.date) {
  return date ? format.format(date) : null;
}
