import { ColorsV2 } from '@tomorrow/ui/theme';
import { RiCloseCircleFill, RiSearchLine } from '@remixicon/react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';

interface ScreenSearchProps extends SearchTextInputProps {
  children?: React.ReactNode;
  variant?: 'default' | 'toolbar';
}

export function ScreenSearch({ children, onSearch, searchPlaceholderText, variant }: ScreenSearchProps) {
  const content = (
    <Box display="flex" overflow="hidden" paddingRight={2} py={0.5} width="100%">
      <Box display="flex" flexGrow="1" paddingLeft={1}>
        <SearchTextInput onSearch={onSearch} searchPlaceholderText={searchPlaceholderText} />
      </Box>
      {children ? (
        <Box alignItems="center" display="flex">
          {children}
        </Box>
      ) : null}
    </Box>
  );

  return variant === 'toolbar' ? (
    <Box sx={{ height: '49px', paddingLeft: 1, display: 'flex' }}>{content}</Box>
  ) : (
    content
  );
}

interface SearchTextInputProps {
  onSearch: (searchData: string) => void;
  searchPlaceholderText?: string;
}

export function SearchTextInput({ onSearch, searchPlaceholderText }: SearchTextInputProps) {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  useEffect(() => {
    onSearch(debouncedSearchValue);
    // TODO: Fix lint error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  return (
    <InputBase
      endAdornment={
        searchValue ? (
          <InputAdornment position="end">
            <IconButton onClick={() => setSearchValue('')} size="large">
              <RiCloseCircleFill size={18} />
            </IconButton>
          </InputAdornment>
        ) : null
      }
      fullWidth
      onChange={(event) => setSearchValue(event.target.value)}
      placeholder={searchPlaceholderText ?? 'Search...'}
      size="small"
      startAdornment={
        <InputAdornment position="start">
          <RiSearchLine color={ColorsV2.navy} size={18} />
        </InputAdornment>
      }
      sx={{ input: { height: '100%', padding: 0 } }}
      value={searchValue}
    />
  );
}
