import { Link } from 'react-router-dom';
import { RiAddLine } from '@remixicon/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';

type NewOrderButtonProps = {
  onClick?: React.ComponentProps<typeof Button>['onClick'];
  text?: string;
  to: string;
};

const NewOrderButton = ({ onClick, text = 'New Order', to }: NewOrderButtonProps) => {
  return (
    <Link to={to}>
      <Button onClick={onClick} variant="primary">
        <Box display="flex" whiteSpace="nowrap">
          <Box display="flex" width="20px">
            <RiAddLine />
          </Box>
          {text}
        </Box>
      </Button>
    </Link>
  );
};

export default NewOrderButton;
