import { logError } from './logging';

// eslint-disable-next-line no-useless-escape
const jsonRegex = /[\{|\[][a-zA-Z0-9\s\:\(\)',\.\"\-\_\$\n\+\*\\\/\!\?\@]+[\]|\}]/;

export function showError(error: unknown, title: string, options?: { allowJson?: boolean }) {
  const errorMessage = (() => {
    if (error instanceof Error) return error.message;
    if (typeof error === 'string') return error;
    return null;
  })();

  const subtitle =
    errorMessage && (options?.allowJson || !jsonRegex.exec(errorMessage))
      ? errorMessage
      : 'Oops. Something went wrong with last action. Please try again.';

  // TODO: Remove this once we convert logAndShowError to be used as to a hook, or have showSnackbarNotification as param
  window.showSnackbarNotification?.({ type: 'error', title, subtitle });
}

export function logAndShowError(error: unknown, title: string, options?: { allowJson?: boolean }) {
  logError(error);
  showError(error, title, options);
}
