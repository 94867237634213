import { AutocompleteGetTagProps } from '@mui/material';
import { ColorsV2 } from '@tomorrow/ui/theme';
import { DocumentFieldId } from './DocumentStep.utils';
import { LabelStepFormValues, toDefaultFormValues } from './LabelStep.utils';
import { OrderWizardStepComponentProps, StepRef } from '../../IntakeFaxOrderWizard';
import { SupplierIntakeFaxOrderSeed } from '../SupplierFaxOrderWizard.utils';
import { useForm, UseFormReturn } from 'react-hook-form';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export type LabelStepForm = UseFormReturn<LabelStepFormValues>;

// mocked for demo purposes
const LABEL_OPTIONS = [
  { id: '1', label: 'DO NOT TOUCH - RON' },
  { id: '2', label: 'Label 2' },
  { id: '3', label: 'WIP (Jared - health doc)' },
  { id: '4', label: 'Jerry please look at this, ty' },
];

export const LabelStep = forwardRef<
  StepRef,
  OrderWizardStepComponentProps<SupplierIntakeFaxOrderSeed, DocumentFieldId>
>(function LabelStep({ orderSeed, onOrderProduce, onNextStep, onOrderSubmit, pdfViewerRef }, ref) {
  const form = useForm<LabelStepFormValues>({
    defaultValues: toDefaultFormValues(orderSeed),
  });

  const saveOrder = useCallback(
    function saveOrder(_values: LabelStepFormValues, onSuccess: (orderSeed: SupplierIntakeFaxOrderSeed) => void) {
      if (!orderSeed.id) throw new Error('Missing order seed id');

      const updatedOrderSeed = orderSeed;

      onSuccess(updatedOrderSeed);
    },
    [orderSeed],
  );

  const handleFormSubmit = useCallback(async () => {
    await form.handleSubmit((values) => {
      onOrderSubmit();
      saveOrder(values, onNextStep);
    })();
  }, [form, onNextStep, onOrderSubmit, saveOrder]);

  const handlePageJump = (page: number) => {
    pdfViewerRef.current?.scrollToPage(page);
  };

  useImperativeHandle(
    ref,
    () => ({
      triggerFormSubmit: handleFormSubmit,
    }),
    [handleFormSubmit],
  );

  return (
    <Box bgcolor={ColorsV2.white} display="flex" flexGrow="1" minWidth={0}>
      <Box flexGrow="1" minWidth={0}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleFormSubmit();
          }}
        >
          <Stack flexGrow="1" padding="20px 40px" spacing={2}>
            <Stack spacing={2}>
              <Stack paddingX="40px" spacing={2}>
                <Typography fontWeight="600" variant="h5">
                  Label documents to be processed
                </Typography>
                <Divider />
              </Stack>

              <Box paddingLeft="40px">
                <Stack flexGrow={1} paddingTop={1} spacing={1}>
                  <Stack spacing={1}>
                    {orderSeed.pageLabels.map((pageLabel, index) => {
                      return (
                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                        <div key={`${pageLabel.page}`} onMouseEnter={() => handlePageJump(index)}>
                          <Typography gutterBottom>Page {index + 1}</Typography>
                          <LabelAutocomplete
                            multiple={false}
                            selectedOptions={LABEL_OPTIONS.find((option) => option.id === pageLabel.data?.id)}
                            setSelectedOptions={(option) => {
                              if (Array.isArray(option)) return;

                              onOrderProduce((draft) => {
                                draft.pageLabels = orderSeed.pageLabels.map((pageLabel) => {
                                  if (pageLabel.page === index) {
                                    return {
                                      ...pageLabel,
                                      data: {
                                        id: option?.id ?? '',
                                        label: option?.label ?? '',
                                      },
                                    };
                                  }

                                  return pageLabel;
                                });

                                return draft;
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Box>
  );
});

type Option = {
  id: string;
  label: string;
};

interface LabelAutocompleteProps {
  selectedOptions: Option[] | Option | undefined;
  setSelectedOptions: (options: Option[] | Option | null) => void;
  disabled?: boolean;
  disablePortal?: boolean;
  multiple?: boolean;
  placeholder?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  id?: string;
}

const filterOptions = createFilterOptions<Option>({
  stringify: (option) => `${option.id} ${option.label}`,
});

const LabelAutocomplete = React.forwardRef<HTMLElement, LabelAutocompleteProps>(function LabelAutocomplete(
  {
    selectedOptions,
    multiple = true,
    setSelectedOptions,
    disablePortal = false,
    placeholder,
    required,
    size = 'small',
    id,
  },
  ref,
) {
  const [diagnosisCodeOptionsSearchValue, setDiagnosisCodeOptionsSearchValue] = useState('');
  const diagnosisCodeOptions = LABEL_OPTIONS;

  function handleTextboxChange(input: string) {
    setDiagnosisCodeOptionsSearchValue(input);
  }

  return (
    <Autocomplete
      disablePortal={disablePortal}
      filterOptions={filterOptions}
      filterSelectedOptions
      getOptionLabel={getOptionLabel}
      id={id}
      isOptionEqualToValue={getOptionSelected}
      limitTags={3}
      multiple={multiple}
      noOptionsText={
        diagnosisCodeOptionsSearchValue ? 'No options found. Try searching for more.' : 'Try searching for options'
      }
      onChange={(_e, value) => setSelectedOptions(value)}
      options={diagnosisCodeOptions ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
          inputRef={ref}
          onChange={(event) => handleTextboxChange(event.target.value)}
          placeholder={placeholder ?? 'Search code or description'}
          required={required}
          size={size}
        />
      )}
      renderOption={getOptionRender}
      renderTags={renderTags}
      size={size}
      value={selectedOptions}
    />
  );
});

function renderTags(value: Option[], getTagProps: AutocompleteGetTagProps) {
  return value.map((option, index) => (
    // @ts-ignore
    <Chip key={option.id} {...getTagProps({ index })} label={getTagRender(option)} />
  ));
}

function getTagRender({ label, id }: Option) {
  return (
    <Box component={Typography} title={`${id} - ${label}`}>
      {label}
    </Box>
  );
}

function getOptionLabel({ label }: Option) {
  return label;
}

function getOptionRender(props: React.HTMLAttributes<HTMLLIElement>, { label }: Option) {
  return (
    <li {...props}>
      <Typography overflow="hidden" textOverflow="ellipsis">
        {label}
      </Typography>
    </li>
  );
}

function getOptionSelected(option: Option, diagnosisCode: Option) {
  return option.id === diagnosisCode.id;
}
