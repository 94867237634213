import { AppPatientInsuranceStatusChoices, SexEnum } from '../../../../api-clients/falcon-api/graphql/types.generated';
import { ColorsV2 } from '@tomorrow/ui/theme';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import {
  GetOrderSeedPotentialPatientMatchesQuery,
  useGetOrderSeedPotentialPatientMatchesQuery,
} from '../../../../api-clients/falcon-api/graphql/queries/getOrderSeedPotentialPatientMatches.generated';
import { IntakePatient, useCreateOrgPatient } from '../../../../components/PatientModal/PatientForm.helpers';
import { milliseconds } from 'date-fns';
import { OrderWizardStepComponentProps, StepRef } from '../../IntakeFaxOrderWizard';
import { PatientAutocompleteInput } from '../../../../components/FormInputs';
import { PatientCarousel } from '../../../../components/PatientCarousel';
import { PatientDetailCard } from '../../../../components/PatientDetailCard';
import {
  PatientForm,
  PatientFormRef,
  toDefaultPatientFormValues,
} from '../../../../components/PatientModal/PatientForm';
import { PatientStepFormValues, toDefaultFormValues } from './PatientStep.utils';
import { RiArrowUpLine } from '@remixicon/react';
import { SupplierIntakeFaxOrderSeed } from '../SupplierFaxOrderWizard.utils';
import { useController, useForm, UseFormReturn } from 'react-hook-form';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export type PatientStepForm = UseFormReturn<PatientStepFormValues>;

export const PatientStep = forwardRef<StepRef, OrderWizardStepComponentProps<SupplierIntakeFaxOrderSeed>>(
  function PatientStep({ orderSeed, onOrderProduce, onNextStep }, ref) {
    const form = useForm<PatientStepFormValues>({
      defaultValues: toDefaultFormValues(orderSeed),
    });

    const patientFormRef = useRef<PatientFormRef>(null);

    const saveOrder = useCallback(
      function saveOrder(values: PatientStepFormValues, onSuccess: (orderSeed: SupplierIntakeFaxOrderSeed) => void) {
        const updatedOrderSeed = onOrderProduce((draft) => {
          draft.selectedPatient = values.patient;
          draft.selectedPatientFormOption = values.selectedFormOption;
        });

        onSuccess(updatedOrderSeed);
      },
      [onOrderProduce],
    );

    const handleFormSubmit = useCallback(async () => {
      const { selectedFormOption, patient } = form.getValues();
      if (selectedFormOption === 'new' && !patient) {
        // If the user has selected to create a new patient, but hasn't created one yet,
        // trigger nested patient form submission
        patientFormRef.current?.triggerSubmit();

        return;
      }

      await form.handleSubmit((values) => {
        saveOrder(values, onNextStep);
      })();
    }, [form, onNextStep, saveOrder]);

    useImperativeHandle(
      ref,
      () => ({
        triggerFormSubmit: handleFormSubmit,
      }),
      [handleFormSubmit],
    );

    return (
      <Box bgcolor={ColorsV2.white} display="flex" flexGrow="1" minWidth={0}>
        <Box flexGrow="1" minWidth={0}>
          <Stack flexGrow="1" paddingY="20px" spacing={2}>
            <Stack spacing={2}>
              <Stack paddingX="40px" spacing={2}>
                <Typography fontWeight="600" variant="h5">
                  Assign document to a patient
                </Typography>
                <Divider />
              </Stack>

              <Box paddingLeft="40px">
                <PatientOptionInput
                  form={form}
                  onNewPatientCreated={async () => {
                    await form.handleSubmit((values) => {
                      saveOrder(values, onNextStep);
                    })();
                  }}
                  orderSeed={orderSeed}
                  patientFormRef={patientFormRef}
                />
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
  },
);

const PatientOptionInput = ({
  orderSeed,
  form,
  patientFormRef,
  onNewPatientCreated,
}: {
  orderSeed: SupplierIntakeFaxOrderSeed;
  form: PatientStepForm;
  patientFormRef: React.RefObject<PatientFormRef>;
  onNewPatientCreated: () => void;
}) => {
  const createPatientMutation = useCreateOrgPatient();
  const potentialPatientMatchesQuery = useGetOrderSeedPotentialPatientMatchesQuery(
    { orgId: orderSeed.orgId, orderSeedId: orderSeed.id ?? '' },
    {
      enabled: !!orderSeed.orgId && !!orderSeed.id,
      staleTime: milliseconds({ minutes: 5 }),
      meta: {
        errorMessage: 'Failed to load patients.',
      },
      select: useCallback(function transformGetOrderSeedPotentialPatientMatchesQuery(
        query: GetOrderSeedPotentialPatientMatchesQuery,
      ): IntakePatient[] {
        return query.orderSeedPotentialPatientMatches?.flatMap((patient) => (patient ? [patient] : [])) ?? [];
      }, []),
    },
  );

  const { field: optionField, fieldState: optionFieldState } = useController({
    control: form.control,
    name: 'selectedFormOption',
    rules: {
      required: 'Please select an option',
      deps: ['patient'],
    },
  });

  const { field: selectedPatientField, fieldState: selectedPatientFieldState } = useController({
    control: form.control,
    name: 'patient',
    rules: {
      required: optionField.value === 'new' ? 'Please create a patient' : 'Please select a patient',
    },
  });

  const error = optionFieldState.error ?? selectedPatientFieldState.error;

  const { patientFormValues, patientFormFieldDescriptions } = useMemo(
    () => ({
      patientFormValues: toDefaultPatientFormValues({
        firstName: orderSeed.extractionResultsSet?.patientFirstNameCorrected ?? '',
        lastName: orderSeed.extractionResultsSet?.patientLastNameCorrected ?? '',
        middleName: orderSeed.extractionResultsSet?.patientMiddleNameCorrected ?? '',
        dateOfBirth: orderSeed.extractionResultsSet?.patientDateOfBirthCorrected ?? '',
        emailAddress: orderSeed.extractionResultsSet?.patientEmailAddressCorrected ?? '',
        homePhoneNumber: orderSeed.extractionResultsSet?.patientHomePhoneCorrected ?? '',
        weightInPounds: orderSeed.extractionResultsSet?.patientWeightInPoundsCorrected
          ? Number(orderSeed.extractionResultsSet.patientWeightInPoundsCorrected)
          : undefined,
        heightInInches: orderSeed.extractionResultsSet?.patientHeightInInchesCorrected
          ? Number(orderSeed.extractionResultsSet.patientHeightInInchesCorrected)
          : undefined,
        sex: (orderSeed.extractionResultsSet?.patientSexCorrected?.toUpperCase() as SexEnum) || undefined,
        shippingAddress: {
          addressLine1: orderSeed.extractionResultsSet?.shippingAddress.address1Corrected ?? '',
          addressLine2: orderSeed.extractionResultsSet?.shippingAddress.address2Corrected ?? '',
          city: orderSeed.extractionResultsSet?.shippingAddress.cityCorrected ?? '',
          state: orderSeed.extractionResultsSet?.shippingAddress.stateCorrected ?? '',
          postalCode: orderSeed.extractionResultsSet?.shippingAddress.postalCodeCorrected ?? '',
        },
        primaryInsurance: {
          status: AppPatientInsuranceStatusChoices.Unverified,
          insurer: {
            display: '',
            id: '',
            name: '',
            requiresTitleXix: false,
          },
          policyNumber: orderSeed.extractionResultsSet?.primaryInsurance.policyNumberCorrected ?? '',
        },
        secondaryInsurance: {
          status: AppPatientInsuranceStatusChoices.Unverified,
          insurer: {
            display: '',
            id: '',
            name: '',
            requiresTitleXix: false,
          },
          policyNumber: orderSeed.extractionResultsSet?.secondaryInsurance.policyNumberCorrected ?? '',
        },
        languagePreference: undefined,
        guardian: undefined,
        patientWaiver: undefined,
      }),
      patientFormFieldDescriptions: {
        primaryInsurance: {
          carrier: orderSeed.extractionResultsSet?.primaryInsurance.insurerNameCorrected && (
            <ExtractionResultFieldLabel>
              {orderSeed.extractionResultsSet?.primaryInsurance.insurerNameCorrected}
            </ExtractionResultFieldLabel>
          ),
          plan: orderSeed.extractionResultsSet?.primaryInsurance.planNameCorrected && (
            <ExtractionResultFieldLabel>
              {orderSeed.extractionResultsSet?.primaryInsurance.planNameCorrected}
            </ExtractionResultFieldLabel>
          ),
        },
        secondaryInsurance: {
          carrier: orderSeed.extractionResultsSet?.secondaryInsurance.insurerNameCorrected && (
            <ExtractionResultFieldLabel>
              {orderSeed.extractionResultsSet?.secondaryInsurance.insurerNameCorrected}
            </ExtractionResultFieldLabel>
          ),
          plan: orderSeed.extractionResultsSet?.secondaryInsurance.planNameCorrected && (
            <ExtractionResultFieldLabel>
              {orderSeed.extractionResultsSet?.secondaryInsurance.planNameCorrected}
            </ExtractionResultFieldLabel>
          ),
        },
      },
    }),
    [orderSeed.extractionResultsSet],
  );

  return (
    <FormControl
      component="fieldset"
      disabled={createPatientMutation.isLoading}
      error={!!error?.message}
      sx={{
        opacity: createPatientMutation.isLoading ? 0.5 : 1,
      }}
    >
      <RadioGroup
        {...optionField}
        onChange={(_e, value) => {
          selectedPatientField.onChange(null);

          optionField.onChange(value);
        }}
      >
        <FormControlLabel
          control={<Radio color="primary" />}
          disabled={potentialPatientMatchesQuery.isFetching || (potentialPatientMatchesQuery.data?.length ?? 0) === 0}
          disableTypography
          label={
            <Box minWidth={0}>
              <Stack paddingBottom={1} paddingTop={1} spacing={1}>
                <Typography
                  color={
                    potentialPatientMatchesQuery.isFetching || (potentialPatientMatchesQuery.data?.length || 0) === 0
                      ? 'text.secondary'
                      : undefined
                  }
                  fontWeight="600"
                  variant="subtitle2"
                >
                  Select patient from a potential match
                </Typography>

                {potentialPatientMatchesQuery.isFetching && (
                  <Stack spacing={1}>
                    <Skeleton width="50%">
                      <Typography>Loading...</Typography>
                    </Skeleton>
                    <Stack direction="row" overflow="hidden" spacing={1}>
                      <Skeleton
                        sx={{
                          height: '200px',
                          minWidth: '300px',
                        }}
                        variant="rounded"
                      />
                      <Skeleton
                        sx={{
                          height: '200px',
                          minWidth: '300px',
                        }}
                        variant="rounded"
                      />
                    </Stack>
                  </Stack>
                )}

                {!potentialPatientMatchesQuery.isFetching && (potentialPatientMatchesQuery.data?.length ?? 0) === 0 && (
                  <Typography>No matches found.</Typography>
                )}

                {!potentialPatientMatchesQuery.isFetching &&
                  potentialPatientMatchesQuery.data &&
                  potentialPatientMatchesQuery.data.length > 0 && (
                    <Stack spacing={1}>
                      <Typography>
                        We have found {potentialPatientMatchesQuery.data?.length} potential{' '}
                        {potentialPatientMatchesQuery.data?.length === 1 ? 'match' : 'matches'}:
                      </Typography>
                      <PatientCarousel
                        disabled={optionField.value !== 'match'}
                        onPatientSelect={selectedPatientField.onChange}
                        patients={potentialPatientMatchesQuery.data}
                        selectedPatientId={selectedPatientField.value?.id}
                      />
                    </Stack>
                  )}
              </Stack>
            </Box>
          }
          sx={{ alignItems: 'flex-start' }}
          value="match"
        />
        <FormControlLabel
          control={<Radio color="primary" />}
          disableTypography
          label={
            <Stack flexGrow="1" paddingTop={1} spacing={1}>
              <Typography fontWeight="600" variant="subtitle2">
                Select patient from database
              </Typography>
              {optionField.value === 'database' && (
                <Box>
                  <PatientAutocompleteInput control={form.control} name="patient" orgId={orderSeed.orgId} />
                  {selectedPatientField.value && (
                    <PatientDetailCard patient={selectedPatientField.value} showPatientName={false} />
                  )}
                </Box>
              )}
            </Stack>
          }
          sx={{
            alignItems: 'flex-start',
            paddingRight: '40px',
          }}
          value="database"
        />
        <FormControlLabel
          control={<Radio color="primary" />}
          disableTypography
          label={
            <Stack paddingTop={1} spacing={1}>
              <Typography fontWeight="600" variant="subtitle2">
                Create new patient
              </Typography>

              {optionField.value === 'new' && selectedPatientField.value && (
                <>
                  <PatientDetailCard
                    patient={selectedPatientField.value}
                    sx={{
                      bgcolor: '#F8F8F8',
                    }}
                  />
                  <Link
                    component="button"
                    fontWeight="600"
                    onClick={() => {
                      selectedPatientField.onChange(null);
                    }}
                    textAlign="right"
                  >
                    Remove selected patient
                  </Link>
                </>
              )}

              {optionField.value === 'new' && !selectedPatientField.value && (
                <Stack spacing={1}>
                  <Typography color="text.secondary" variant="body2">
                    Some information was extracted from the document.
                  </Typography>
                  <PatientForm
                    ref={patientFormRef}
                    defaultValues={patientFormValues}
                    fieldDescriptions={patientFormFieldDescriptions}
                    formMode="create"
                    onSubmit={async (values) => {
                      const patient = await createPatientMutation.mutateAsync(values, orderSeed.orgId);

                      if (patient) {
                        selectedPatientField.onChange(patient);
                        onNewPatientCreated();
                      }
                    }}
                    submitState={createPatientMutation}
                  />
                </Stack>
              )}
            </Stack>
          }
          onClick={(e) => {
            // Prevents an annoying scroll jump upon focusing this input
            // due to the large patient form container
            e.preventDefault();

            selectedPatientField.onChange(null);
            optionField.onChange('new');
          }}
          sx={{
            alignItems: 'flex-start',
            paddingRight: '40px',
          }}
          value="new"
        />
      </RadioGroup>
      {!!error?.message && <FormHelperText sx={{ marginTop: 1 }}>{error.message}</FormHelperText>}
    </FormControl>
  );
};

const ExtractionResultFieldLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography align="right" color="text.secondary" variant="body2">
      Extracted:{' '}
      <Typography component="span" fontWeight="600">
        {children}
      </Typography>{' '}
      <RiArrowUpLine size="12px" />
    </Typography>
  );
};
