import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type GetIntakeSubcategoriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetIntakeSubcategoriesQuery = {
  __typename?: 'Query';
  intakeSubcategories?: Array<{
    __typename?: 'IntakeSubcategoryType';
    name: string;
    id: string;
    intakeCategory: { __typename?: 'IntakeCategoryType'; id: string };
    activeProductForm?: {
      __typename?: 'IntakeSubcategoryProductFormType';
      id: string;
      formJson?: string | null;
    } | null;
  } | null> | null;
};

export const GetIntakeSubcategoriesDocument = `
    query getIntakeSubcategories {
  intakeSubcategories {
    name
    id
    intakeCategory {
      id
    }
    activeProductForm {
      id
      formJson
    }
  }
}
    `;

export const useGetIntakeSubcategoriesQuery = <TData = GetIntakeSubcategoriesQuery, TError = unknown>(
  variables?: GetIntakeSubcategoriesQueryVariables,
  options?: Omit<UseQueryOptions<GetIntakeSubcategoriesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetIntakeSubcategoriesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetIntakeSubcategoriesQuery, TError, TData>({
    queryKey: variables === undefined ? ['getIntakeSubcategories'] : ['getIntakeSubcategories', variables],
    queryFn: fetcher<GetIntakeSubcategoriesQuery, GetIntakeSubcategoriesQueryVariables>(
      GetIntakeSubcategoriesDocument,
      variables,
    ),
    ...options,
  });
};

useGetIntakeSubcategoriesQuery.getKey = (variables?: GetIntakeSubcategoriesQueryVariables) =>
  variables === undefined ? ['getIntakeSubcategories'] : ['getIntakeSubcategories', variables];

export const useInfiniteGetIntakeSubcategoriesQuery = <
  TData = InfiniteData<GetIntakeSubcategoriesQuery>,
  TError = unknown,
>(
  variables: GetIntakeSubcategoriesQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetIntakeSubcategoriesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetIntakeSubcategoriesQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetIntakeSubcategoriesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['getIntakeSubcategories.infinite']
            : ['getIntakeSubcategories.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetIntakeSubcategoriesQuery, GetIntakeSubcategoriesQueryVariables>(GetIntakeSubcategoriesDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetIntakeSubcategoriesQuery.getKey = (variables?: GetIntakeSubcategoriesQueryVariables) =>
  variables === undefined ? ['getIntakeSubcategories.infinite'] : ['getIntakeSubcategories.infinite', variables];

useGetIntakeSubcategoriesQuery.fetcher = (
  variables?: GetIntakeSubcategoriesQueryVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<GetIntakeSubcategoriesQuery, GetIntakeSubcategoriesQueryVariables>(
    GetIntakeSubcategoriesDocument,
    variables,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetIntakeSubcategoriesQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { intakeSubcategories }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetIntakeSubcategoriesQuery = (
  resolver: GraphQLResponseResolver<GetIntakeSubcategoriesQuery, GetIntakeSubcategoriesQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<GetIntakeSubcategoriesQuery, GetIntakeSubcategoriesQueryVariables>(
    'getIntakeSubcategories',
    resolver,
    options,
  );
