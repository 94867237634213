import './index.css';

import { appRoutes } from './router';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { wrapCreateBrowserRouter } from '@sentry/react';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = createRoot(rootElement);

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(createRoutesFromElements(appRoutes));
root.render(<RouterProvider router={router} />);
