import * as Types from '../types.generated.js';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type PreviewFaxbackCoversheetMutationVariables = Types.Exact<{
  input: Types.FaxbackCoversheetInput;
}>;

export type PreviewFaxbackCoversheetMutation = {
  __typename?: 'Mutation';
  previewFaxbackCoversheet?: {
    __typename?: 'PreviewFaxbackCoversheetMutation';
    pdfData?: string | null;
    error?: { __typename?: 'ErrorType'; id?: string | null; type?: string | null; message: string } | null;
  } | null;
};

export const PreviewFaxbackCoversheetDocument = `
    mutation previewFaxbackCoversheet($input: FaxbackCoversheetInput!) {
  previewFaxbackCoversheet(inputData: $input) {
    pdfData
    error {
      id
      type
      message
    }
  }
}
    `;

export const usePreviewFaxbackCoversheetMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PreviewFaxbackCoversheetMutation,
    TError,
    PreviewFaxbackCoversheetMutationVariables,
    TContext
  >,
) => {
  return useMutation<PreviewFaxbackCoversheetMutation, TError, PreviewFaxbackCoversheetMutationVariables, TContext>({
    mutationKey: ['previewFaxbackCoversheet'],
    mutationFn: (variables?: PreviewFaxbackCoversheetMutationVariables) =>
      fetcher<PreviewFaxbackCoversheetMutation, PreviewFaxbackCoversheetMutationVariables>(
        PreviewFaxbackCoversheetDocument,
        variables,
      )(),
    ...options,
  });
};

usePreviewFaxbackCoversheetMutation.getKey = () => ['previewFaxbackCoversheet'];

usePreviewFaxbackCoversheetMutation.fetcher = (
  variables: PreviewFaxbackCoversheetMutationVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<PreviewFaxbackCoversheetMutation, PreviewFaxbackCoversheetMutationVariables>(
    PreviewFaxbackCoversheetDocument,
    variables,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPreviewFaxbackCoversheetMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { previewFaxbackCoversheet }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPreviewFaxbackCoversheetMutation = (
  resolver: GraphQLResponseResolver<PreviewFaxbackCoversheetMutation, PreviewFaxbackCoversheetMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<PreviewFaxbackCoversheetMutation, PreviewFaxbackCoversheetMutationVariables>(
    'previewFaxbackCoversheet',
    resolver,
    options,
  );
