import { ColorsV2 } from '@tomorrow/ui/theme';
import { documentFieldIdToLabelMap } from '../steps/DocumentStep.utils';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { HighlightFieldCb } from '../../IntakeFaxOrderWizard';
import { RiEditLine } from '@remixicon/react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useRef } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

interface InputSectionProps extends React.PropsWithChildren {
  label: React.ReactNode;
}

export const InputSection = ({ label, children }: InputSectionProps) => {
  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        {typeof label === 'string' ? (
          <Typography fontWeight="600" variant="h5">
            {label}
          </Typography>
        ) : (
          label
        )}
        <Divider />
      </Stack>
      <Stack spacing={1}>{children}</Stack>
    </Stack>
  );
};

interface InputSubSectionProps extends InputSectionProps {
  rightComponent?: React.ReactNode;
}

export const InputSubSection = ({ label, children, rightComponent }: InputSubSectionProps) => {
  return (
    <Stack spacing={2}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography fontWeight="600" variant="subtitle2">
          {label}
        </Typography>
        {rightComponent}
      </Stack>
      <Stack spacing={1}>{children}</Stack>
    </Stack>
  );
};

const confidenceMap = {
  LOW: ColorsV2.red,
  MEDIUM: ColorsV2.mustard,
  HIGH: ColorsV2.neon_green,
  MISSING: ColorsV2.gray_medium,
};

interface InputContainerProps<T extends string> extends React.PropsWithChildren {
  icon: React.ReactNode;
  hidden?: boolean;
  label?: string;
  highlightFieldId: T;
  hoveredFieldId: T | undefined;
  onHighlightField: HighlightFieldCb<T> | undefined;
}

export const InputContainer = <T extends string>({
  icon,
  hidden,
  onHighlightField,
  highlightFieldId,
  hoveredFieldId,
  // ! TODO: Figure out correct type for documentFieldIdToLabelMap
  // @ts-ignore
  label = documentFieldIdToLabelMap[highlightFieldId as string],
  children,
}: InputContainerProps<T>) => {
  const inputContainerRef = useRef<HTMLDivElement>(null);

  if (hidden) {
    return null;
  }

  const handleOnHighlightField = (highlightEnabled: boolean) => () => {
    if (!inputContainerRef.current || !onHighlightField) return;

    onHighlightField(inputContainerRef.current, highlightFieldId, highlightEnabled);
  };

  return (
    <Box
      ref={inputContainerRef}
      component="div"
      onMouseEnter={handleOnHighlightField(true)}
      onMouseLeave={handleOnHighlightField(false)}
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        transition: 'opacity 0.2s ease-in-out',
        opacity: !hoveredFieldId || hoveredFieldId === highlightFieldId ? 1 : 0.2,
      }}
    >
      <Box alignItems="center" display="flex" flexBasis="0" flexGrow="1" gap={1.2} minWidth="0">
        {icon}
        <Tooltip disableInteractive title={label}>
          <Typography noWrap>{label}</Typography>
        </Tooltip>
      </Box>
      <Box flexBasis="0" flexGrow="1">
        {children}
      </Box>
    </Box>
  );
};

interface ConfidenceIconProps<TFieldValues extends FieldValues> extends UseControllerProps<TFieldValues> {
  confidence: keyof typeof confidenceMap | null | undefined;
  corrected: boolean;
}

export function ConfidenceIcon<TFieldValues extends FieldValues>({
  confidence = 'MISSING',
  corrected,
  control,
  name,
}: ConfidenceIconProps<TFieldValues>) {
  const correctedConfidence = confidence && confidence in confidenceMap ? confidence : 'MISSING';
  const confidenceColor = confidenceMap[correctedConfidence];
  const [parentRef] = useAutoAnimate();

  const { fieldState } = useController({
    name,
    control,
  });

  return (
    <div ref={parentRef}>
      {(() => {
        switch (true) {
          case fieldState.isDirty:
            return (
              <Box key="1" height="14px" width="8px">
                <Tooltip disableInteractive title="Modified by you">
                  <Box marginLeft="-2px">
                    <RiEditLine color={ColorsV2.green} size="13.7px" />
                  </Box>
                </Tooltip>
              </Box>
            );
          case corrected:
            return <Box width="8px" />;
          default:
            return (
              <Box key="3" height="9px" width="8px">
                <Tooltip
                  disableInteractive
                  title={<Typography textTransform="capitalize">{correctedConfidence} confidence</Typography>}
                >
                  <Box display="flex">
                    <Eclipse color={confidenceColor} />
                  </Box>
                </Tooltip>
              </Box>
            );
        }
      })()}
    </div>
  );
}

const Eclipse = ({ color }: { color: string }) => {
  return (
    <svg fill="none" viewBox="0 0 8 9" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4.48389" fill={color} r="4" />
    </svg>
  );
};

type LeafCountResult = {
  totalLeafCount: number;
  totalLeafCountWithValues: number;
};

export function countLeafFields(obj: object): LeafCountResult {
  let totalLeafCount = 0;
  let totalLeafCountWithValues = 0;

  function traverse(obj: Record<string, any>) {
    for (const key in obj) {
      // ensure not a date object
      if (obj[key] && typeof obj[key] === 'object' && !obj[key].toISOString) {
        traverse(obj[key]);
      } else {
        totalLeafCount++;
        if (obj[key]) {
          totalLeafCountWithValues++;
        }
      }
    }
  }

  traverse(obj);

  return { totalLeafCount, totalLeafCountWithValues };
}
