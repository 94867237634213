import * as Types from '../types.generated.js';

import { ProviderFragmentDoc } from '../fragments/ProviderFragment.generated.js';
import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type GetUserQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserType';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    emailAddress: string;
    orgUsers?: Array<{
      __typename?: 'OrgUserType';
      id: string;
      acceptedInviteAt?: string | null;
      phoneNumber?: string | null;
      faxNumber?: string | null;
      smsNumber?: string | null;
      delegatedBy?: Array<{
        __typename?: 'OrgUserType';
        id: string;
        user?: { __typename?: 'UserType'; provider?: { __typename?: 'ProviderType'; id: string } | null } | null;
      } | null> | null;
      org?: {
        __typename?: 'OrgType';
        agreementStatus: Types.AgreementStatusEnum;
        id: string;
        name?: string | null;
        orgTypeEnum: Types.OrgTypes;
        insurerCategoryEnum?: Types.AppInsurerInsurerCategoryDirectChoices | null;
        orgFaxNumbers?: Array<{
          __typename?: 'OrgFaxNumberType';
          id: string;
          faxNumber: string;
          confirmedAt?: string | null;
          createdAt: string;
        } | null> | null;
        supplier?: {
          __typename?: 'OptimizedNetworkSupplierType';
          automatedFaxEnabled: boolean;
          id: string;
          intakePhoneNumber?: string | null;
          isProofOfDeliveryRequired: boolean;
          isThdSupplier: boolean;
        } | null;
        practice?: { __typename?: 'PracticeType'; id: string } | null;
      } | null;
    }> | null;
    provider?: {
      __typename?: 'ProviderType';
      firstName: string;
      lastName: string;
      npi: string;
      emailAddress?: string | null;
      faxNumber?: string | null;
      id: string;
      phoneNumber?: string | null;
      smsNumber?: string | null;
    } | null;
  } | null;
};

export const GetUserDocument = `
    query getUser {
  user {
    id
    firstName
    lastName
    emailAddress
    orgUsers {
      id
      acceptedInviteAt
      phoneNumber
      faxNumber
      smsNumber
      delegatedBy {
        id
        user {
          provider {
            id
          }
        }
      }
      org {
        agreementStatus
        id
        name
        orgTypeEnum
        insurerCategoryEnum
        orgFaxNumbers {
          id
          faxNumber
          confirmedAt
          createdAt
        }
        supplier {
          automatedFaxEnabled
          id
          intakePhoneNumber
          isProofOfDeliveryRequired
          isThdSupplier
        }
        practice {
          id
        }
      }
    }
    provider {
      ...ProviderFragment
    }
  }
}
    ${ProviderFragmentDoc}`;

export const useGetUserQuery = <TData = GetUserQuery, TError = unknown>(
  variables?: GetUserQueryVariables,
  options?: Omit<UseQueryOptions<GetUserQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetUserQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetUserQuery, TError, TData>({
    queryKey: variables === undefined ? ['getUser'] : ['getUser', variables],
    queryFn: fetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables),
    ...options,
  });
};

useGetUserQuery.getKey = (variables?: GetUserQueryVariables) =>
  variables === undefined ? ['getUser'] : ['getUser', variables];

export const useInfiniteGetUserQuery = <TData = InfiniteData<GetUserQuery>, TError = unknown>(
  variables: GetUserQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetUserQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetUserQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetUserQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: (optionsQueryKey ?? variables === undefined) ? ['getUser.infinite'] : ['getUser.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetUserQuery.getKey = (variables?: GetUserQueryVariables) =>
  variables === undefined ? ['getUser.infinite'] : ['getUser.infinite', variables];

useGetUserQuery.fetcher = (variables?: GetUserQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUserQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { user }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetUserQuery = (
  resolver: GraphQLResponseResolver<GetUserQuery, GetUserQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GetUserQuery, GetUserQueryVariables>('getUser', resolver, options);
