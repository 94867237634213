import {
  AppOrderSeedStatusChoices,
  ExtractionResultsSetCorrectionsInput,
  LocationInfoType,
  SexEnum,
} from '../../../../api-clients/falcon-api/graphql/types.generated';
import { DiagnosisCode } from '../../../../components/DiagnosisCodeAutocomplete';
import { FeatureFlags } from '../../../../hooks/useFeatureFlags';
import { FieldError, FieldErrors } from 'react-hook-form';
import { formatDate, parseDate } from '@tomorrow/ui/utils';
import { HcpcsCode } from '../../../../components/HcpcsCodeAutocomplete';
import { normalizePhoneNumber } from '../../../../utils/phoneUtils';
import { SupplierIntakeFaxOrderSeed } from '../SupplierFaxOrderWizard.utils';

export interface OrderDocumentExtractionFormValues {
  resultId: string;
  order: {
    diagnoses: {
      resultId: string | null;
      dxCode: DiagnosisCode | null;
    }[];
    lineItems: {
      resultId: string | null;
      hcpcs: HcpcsCode | null;
      quantity: string | null;
      lengthOfNeed?: string | null;
      frequency?: string | null;
      brandName?: string | null;
      productName?: string | null;
      cptCode?: string | null;
    }[];
    removedDiagnosisIds: string[];
    removedLineItemIds: string[];
    referralDate: Date | null;
    subcategoryId: string;
    subcategoryName: string;
  };
  patient: {
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: Date | null;
    emailAddress: string;
    sex: SexEnum | null;
    homePhoneNumber: string;
    cellPhoneNumber: string;
    height: string;
    weight: string;
  };
  insurance: {
    primary: {
      insurerName: string;
      plan: string;
      groupNumber: string;
      policyNumber: string;
    };
    secondary: {
      insurerName: string;
      plan: string;
      groupNumber: string;
      policyNumber: string;
    };
    tertiary: {
      insurerName: string;
      plan: string;
      groupNumber: string;
      policyNumber: string;
    };
  };
  referringProvider: {
    firstName: string;
    lastName: string;
    npi: string;
  };
  homeAddress: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postalCode: string;
  };
  shippingAddress: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postalCode: string;
  };
  clinicalInfo?: {
    chartNotes: string;
    bloodGasStudy: string;
    coverSheet: string;
    patientAhi: string;
    patientBmi: string;
    patientBp: string;
    patientEss: string;
    patientHypopneaScore: string;
    patientLastSeenDate: string;
    patientNeckCircumferenceInInches: string;
    patientNeckSize: string;
    patientRdi: string;
    patientRei: string;
    patientTotalSleepTime: string;
    prescription: string;
    providerSignaturePresent: boolean | null;
    sleepStudy: string;
  };
}

export const isOrderSeedCorrected = (orderSeed: SupplierIntakeFaxOrderSeed) => {
  // We're assuming a status of "Created" means the order has not been reviewed yet
  return !!orderSeed.status && orderSeed.status !== AppOrderSeedStatusChoices.Created;
};

export function toDefaultFormValues(orderSeed: SupplierIntakeFaxOrderSeed): OrderDocumentExtractionFormValues {
  return isOrderSeedCorrected(orderSeed)
    ? getUserCorrectedDefaultFormValues(orderSeed)
    : getRawDefaultFormValues(orderSeed);
}

function getRawDefaultFormValues(orderSeed: SupplierIntakeFaxOrderSeed): OrderDocumentExtractionFormValues {
  return {
    resultId: orderSeed.extractionResultsSet?.id ?? '',
    order: {
      diagnoses:
        orderSeed?.extractionResultsSet?.dxCodes?.map((item) => ({
          resultId: item.id,
          dxCode: {
            code: item.diagnosisCodeRaw ?? '',
            description: item.diagnosisCodeRaw ?? '',
            id: item.diagnosisCodeRaw ?? '',
          },
        })) ?? [],
      lineItems:
        orderSeed.extractionResultsSet?.lineItems.map((item) => ({
          resultId: item.id,
          hcpcs: { code: item.hcpcsCodeRaw ?? '', id: item.hcpcsCodeRaw ?? '' },
          quantity: item.quantityRaw ? String(item.quantityRaw) : '',
          lengthOfNeed: item.lengthOfNeedRaw ?? '',
          frequency: item.frequencyRaw ?? '',
          brandName: item.brandNameRaw ?? '',
          productName: item.productNameRaw ?? '',
          cptCode: item.cptCodeRaw ?? '',
        })) ?? [],
      removedDiagnosisIds: [],
      removedLineItemIds: [],
      referralDate: orderSeed.extractionResultsSet?.referralDateRaw
        ? parseDate(orderSeed.extractionResultsSet.referralDateRaw)
        : null,
      subcategoryId: orderSeed.extractionResultsSet?.subcategoryIdRaw ?? '',
      subcategoryName: orderSeed.extractionResultsSet?.subcategoryNameRaw ?? '',
    },
    patient: {
      firstName: orderSeed.extractionResultsSet?.patientFirstNameRaw ?? '',
      middleName: orderSeed.extractionResultsSet?.patientMiddleNameRaw ?? '',
      lastName: orderSeed.extractionResultsSet?.patientLastNameRaw ?? '',
      sex: (orderSeed.extractionResultsSet?.patientSexRaw?.toUpperCase() as SexEnum) || null,
      dateOfBirth: orderSeed.extractionResultsSet?.patientDateOfBirthRaw
        ? parseDate(orderSeed.extractionResultsSet.patientDateOfBirthRaw)
        : null,
      emailAddress: orderSeed.extractionResultsSet?.patientEmailAddressRaw ?? '',
      homePhoneNumber: orderSeed.extractionResultsSet?.patientHomePhoneRaw
        ? normalizePhoneNumber(orderSeed.extractionResultsSet.patientHomePhoneRaw)
        : '',
      cellPhoneNumber: orderSeed.extractionResultsSet?.patientCellPhoneRaw
        ? normalizePhoneNumber(orderSeed.extractionResultsSet.patientCellPhoneRaw)
        : '',
      weight: orderSeed.extractionResultsSet?.patientWeightInPoundsRaw
        ? String(orderSeed.extractionResultsSet.patientWeightInPoundsRaw)
        : '',
      height: orderSeed.extractionResultsSet?.patientHeightInInchesRaw
        ? String(orderSeed.extractionResultsSet.patientHeightInInchesRaw)
        : '',
    },
    referringProvider: {
      firstName: orderSeed.extractionResultsSet?.providerFirstNameRaw ?? '',
      lastName: orderSeed.extractionResultsSet?.providerLastNameRaw ?? '',
      npi: orderSeed.extractionResultsSet?.providerNpiRaw ?? '',
    },
    homeAddress: {
      addressLine1: orderSeed.extractionResultsSet?.homeAddress.address1Raw ?? '',
      addressLine2: orderSeed.extractionResultsSet?.homeAddress.address2Raw ?? '',
      city: orderSeed.extractionResultsSet?.homeAddress.cityRaw ?? '',
      state: orderSeed.extractionResultsSet?.homeAddress.stateRaw ?? '',
      postalCode: orderSeed.extractionResultsSet?.homeAddress.postalCodeRaw ?? '',
    },
    shippingAddress: {
      addressLine1: orderSeed.extractionResultsSet?.shippingAddress.address1Raw ?? '',
      addressLine2: orderSeed.extractionResultsSet?.shippingAddress.address2Raw ?? '',
      city: orderSeed.extractionResultsSet?.shippingAddress.cityRaw ?? '',
      state: orderSeed.extractionResultsSet?.shippingAddress.stateRaw ?? '',
      postalCode: orderSeed.extractionResultsSet?.shippingAddress.postalCodeRaw ?? '',
    },
    insurance: {
      primary: {
        insurerName: orderSeed.extractionResultsSet?.primaryInsurance.insurerNameRaw ?? '',
        plan: orderSeed.extractionResultsSet?.primaryInsurance.planNameRaw ?? '',
        groupNumber: orderSeed.extractionResultsSet?.primaryInsurance.groupNumberRaw ?? '',
        policyNumber: orderSeed.extractionResultsSet?.primaryInsurance.policyNumberRaw ?? '',
      },
      secondary: {
        insurerName: orderSeed.extractionResultsSet?.secondaryInsurance.insurerNameRaw ?? '',
        plan: orderSeed.extractionResultsSet?.secondaryInsurance.planNameRaw ?? '',
        groupNumber: orderSeed.extractionResultsSet?.secondaryInsurance.groupNumberRaw ?? '',
        policyNumber: orderSeed.extractionResultsSet?.secondaryInsurance.policyNumberRaw ?? '',
      },
      tertiary: {
        insurerName: orderSeed.extractionResultsSet?.tertiaryInsurance.insurerNameRaw ?? '',
        plan: orderSeed.extractionResultsSet?.tertiaryInsurance.planNameRaw ?? '',
        groupNumber: orderSeed.extractionResultsSet?.tertiaryInsurance.groupNumberRaw ?? '',
        policyNumber: orderSeed.extractionResultsSet?.tertiaryInsurance.policyNumberRaw ?? '',
      },
    },
    clinicalInfo: {
      chartNotes: orderSeed.extractionResultsSet?.chartNotesRaw ?? '',
      bloodGasStudy: orderSeed.extractionResultsSet?.bloodGasStudyRaw ?? '',
      coverSheet: orderSeed.extractionResultsSet?.coverSheetRaw ?? '',
      patientAhi: orderSeed.extractionResultsSet?.patientAhiRaw
        ? String(orderSeed.extractionResultsSet.patientAhiRaw)
        : '',
      patientBmi: orderSeed.extractionResultsSet?.patientBmiRaw
        ? String(orderSeed.extractionResultsSet.patientBmiRaw)
        : '',
      patientBp: orderSeed.extractionResultsSet?.patientBpRaw ?? '',
      patientEss: orderSeed.extractionResultsSet?.patientEssRaw
        ? String(orderSeed.extractionResultsSet.patientEssRaw)
        : '',
      patientHypopneaScore: orderSeed.extractionResultsSet?.patientHypopneaScoreRaw
        ? String(orderSeed.extractionResultsSet.patientHypopneaScoreRaw)
        : '',
      patientLastSeenDate: orderSeed.extractionResultsSet?.patientLastSeenDateRaw ?? '',
      patientNeckCircumferenceInInches: orderSeed.extractionResultsSet?.patientNeckCircumferenceInInchesRaw
        ? String(orderSeed.extractionResultsSet.patientNeckCircumferenceInInchesRaw)
        : '',
      patientNeckSize: orderSeed.extractionResultsSet?.patientNeckSizeRaw ?? '',
      patientRdi: orderSeed.extractionResultsSet?.patientRdiRaw
        ? String(orderSeed.extractionResultsSet.patientRdiRaw)
        : '',
      patientRei: orderSeed.extractionResultsSet?.patientReiRaw
        ? String(orderSeed.extractionResultsSet.patientReiRaw)
        : '',
      patientTotalSleepTime: orderSeed.extractionResultsSet?.patientTotalSleepTimeRaw
        ? String(orderSeed.extractionResultsSet.patientTotalSleepTimeRaw)
        : '',
      prescription: orderSeed.extractionResultsSet?.prescriptionRaw ?? '',
      providerSignaturePresent:
        typeof orderSeed.extractionResultsSet?.providerSignaturePresentRaw === 'boolean'
          ? orderSeed.extractionResultsSet.providerSignaturePresentRaw
          : null,
      sleepStudy: orderSeed.extractionResultsSet?.sleepStudyRaw ?? '',
    },
  };
}

function getUserCorrectedDefaultFormValues(orderSeed: SupplierIntakeFaxOrderSeed): OrderDocumentExtractionFormValues {
  return {
    resultId: orderSeed.extractionResultsSet?.id ?? '',
    order: {
      diagnoses:
        orderSeed?.extractionResultsSet?.dxCodes?.flatMap((item) =>
          item.diagnosisCodeCorrected !== null
            ? [
                {
                  resultId: item.id,
                  dxCode: {
                    code: item.diagnosisCodeCorrected ?? '',
                    description: item.diagnosisCodeCorrected ?? '',
                    id: item.diagnosisCodeCorrected ?? '',
                  },
                },
              ]
            : [],
        ) ?? [],
      lineItems:
        orderSeed.extractionResultsSet?.lineItems.flatMap((item) =>
          item.hcpcsCodeCorrected !== null
            ? [
                {
                  resultId: item.id,
                  hcpcs: { code: item.hcpcsCodeCorrected ?? '', id: item.hcpcsCodeCorrected ?? '' },
                  quantity: item.quantityCorrected ? String(item.quantityCorrected) : '',
                  lengthOfNeed: item.lengthOfNeedCorrected ?? '',
                  frequency: item.frequencyCorrected ?? '',
                  brandName: item.brandNameCorrected ?? '',
                  productName: item.productNameCorrected ?? '',
                  cptCode: item.cptCodeCorrected ?? '',
                },
              ]
            : [],
        ) ?? [],
      removedDiagnosisIds: [],
      removedLineItemIds: [],
      referralDate: orderSeed.extractionResultsSet?.referralDateCorrected
        ? parseDate(orderSeed.extractionResultsSet.referralDateCorrected)
        : null,
      subcategoryId: orderSeed.extractionResultsSet?.subcategoryIdCorrected ?? '',
      subcategoryName: orderSeed.extractionResultsSet?.subcategoryNameCorrected ?? '',
    },
    patient: {
      firstName: orderSeed.extractionResultsSet?.patientFirstNameCorrected ?? '',
      middleName: orderSeed.extractionResultsSet?.patientMiddleNameCorrected ?? '',
      lastName: orderSeed.extractionResultsSet?.patientLastNameCorrected ?? '',
      sex: (orderSeed.extractionResultsSet?.patientSexCorrected?.toUpperCase() as SexEnum) || null,
      dateOfBirth: orderSeed.extractionResultsSet?.patientDateOfBirthCorrected
        ? parseDate(orderSeed.extractionResultsSet.patientDateOfBirthCorrected)
        : null,
      emailAddress: orderSeed.extractionResultsSet?.patientEmailAddressCorrected ?? '',
      homePhoneNumber: orderSeed.extractionResultsSet?.patientHomePhoneCorrected
        ? normalizePhoneNumber(orderSeed.extractionResultsSet.patientHomePhoneCorrected)
        : '',
      cellPhoneNumber: orderSeed.extractionResultsSet?.patientCellPhoneCorrected
        ? normalizePhoneNumber(orderSeed.extractionResultsSet.patientCellPhoneCorrected)
        : '',
      weight: orderSeed.extractionResultsSet?.patientWeightInPoundsCorrected
        ? String(orderSeed.extractionResultsSet.patientWeightInPoundsCorrected)
        : '',
      height: orderSeed.extractionResultsSet?.patientHeightInInchesCorrected
        ? String(orderSeed.extractionResultsSet.patientHeightInInchesCorrected)
        : '',
    },
    referringProvider: {
      firstName: orderSeed.extractionResultsSet?.providerFirstNameCorrected ?? '',
      lastName: orderSeed.extractionResultsSet?.providerLastNameCorrected ?? '',
      npi: orderSeed.extractionResultsSet?.providerNpiCorrected ?? '',
    },
    homeAddress: {
      addressLine1: orderSeed.extractionResultsSet?.homeAddress.address1Corrected ?? '',
      addressLine2: orderSeed.extractionResultsSet?.homeAddress.address2Corrected ?? '',
      city: orderSeed.extractionResultsSet?.homeAddress.cityCorrected ?? '',
      state: orderSeed.extractionResultsSet?.homeAddress.stateCorrected ?? '',
      postalCode: orderSeed.extractionResultsSet?.homeAddress.postalCodeCorrected ?? '',
    },
    shippingAddress: {
      addressLine1: orderSeed.extractionResultsSet?.shippingAddress.address1Corrected ?? '',
      addressLine2: orderSeed.extractionResultsSet?.shippingAddress.address2Corrected ?? '',
      city: orderSeed.extractionResultsSet?.shippingAddress.cityCorrected ?? '',
      state: orderSeed.extractionResultsSet?.shippingAddress.stateCorrected ?? '',
      postalCode: orderSeed.extractionResultsSet?.shippingAddress.postalCodeCorrected ?? '',
    },
    insurance: {
      primary: {
        insurerName: orderSeed.extractionResultsSet?.primaryInsurance.insurerNameCorrected ?? '',
        plan: orderSeed.extractionResultsSet?.primaryInsurance.planNameCorrected ?? '',
        groupNumber: orderSeed.extractionResultsSet?.primaryInsurance.groupNumberCorrected ?? '',
        policyNumber: orderSeed.extractionResultsSet?.primaryInsurance.policyNumberCorrected ?? '',
      },
      secondary: {
        insurerName: orderSeed.extractionResultsSet?.secondaryInsurance.insurerNameCorrected ?? '',
        plan: orderSeed.extractionResultsSet?.secondaryInsurance.planNameCorrected ?? '',
        groupNumber: orderSeed.extractionResultsSet?.secondaryInsurance.groupNumberCorrected ?? '',
        policyNumber: orderSeed.extractionResultsSet?.secondaryInsurance.policyNumberCorrected ?? '',
      },
      tertiary: {
        insurerName: orderSeed.extractionResultsSet?.tertiaryInsurance.insurerNameCorrected ?? '',
        plan: orderSeed.extractionResultsSet?.tertiaryInsurance.planNameCorrected ?? '',
        groupNumber: orderSeed.extractionResultsSet?.tertiaryInsurance.groupNumberCorrected ?? '',
        policyNumber: orderSeed.extractionResultsSet?.tertiaryInsurance.policyNumberCorrected ?? '',
      },
    },
    clinicalInfo: {
      chartNotes: orderSeed.extractionResultsSet?.chartNotesCorrected ?? '',
      bloodGasStudy: orderSeed.extractionResultsSet?.bloodGasStudyCorrected ?? '',
      coverSheet: orderSeed.extractionResultsSet?.coverSheetCorrected ?? '',
      patientAhi: orderSeed.extractionResultsSet?.patientAhiCorrected
        ? String(orderSeed.extractionResultsSet.patientAhiCorrected)
        : '',
      patientBmi: orderSeed.extractionResultsSet?.patientBmiCorrected
        ? String(orderSeed.extractionResultsSet.patientBmiCorrected)
        : '',
      patientBp: orderSeed.extractionResultsSet?.patientBpCorrected ?? '',
      patientEss: orderSeed.extractionResultsSet?.patientEssCorrected
        ? String(orderSeed.extractionResultsSet.patientEssCorrected)
        : '',
      patientHypopneaScore: orderSeed.extractionResultsSet?.patientHypopneaScoreCorrected
        ? String(orderSeed.extractionResultsSet.patientHypopneaScoreCorrected)
        : '',
      patientLastSeenDate: orderSeed.extractionResultsSet?.patientLastSeenDateCorrected ?? '',
      patientNeckCircumferenceInInches: orderSeed.extractionResultsSet?.patientNeckCircumferenceInInchesCorrected
        ? String(orderSeed.extractionResultsSet.patientNeckCircumferenceInInchesCorrected)
        : '',
      patientNeckSize: orderSeed.extractionResultsSet?.patientNeckSizeCorrected ?? '',
      patientRdi: orderSeed.extractionResultsSet?.patientRdiCorrected
        ? String(orderSeed.extractionResultsSet.patientRdiCorrected)
        : '',
      patientRei: orderSeed.extractionResultsSet?.patientReiCorrected
        ? String(orderSeed.extractionResultsSet.patientReiCorrected)
        : '',
      patientTotalSleepTime: orderSeed.extractionResultsSet?.patientTotalSleepTimeCorrected
        ? String(orderSeed.extractionResultsSet.patientTotalSleepTimeCorrected)
        : '',
      prescription: orderSeed.extractionResultsSet?.prescriptionCorrected ?? '',
      providerSignaturePresent:
        typeof orderSeed.extractionResultsSet?.providerSignaturePresentCorrected === 'boolean'
          ? orderSeed.extractionResultsSet.providerSignaturePresentCorrected
          : null,
      sleepStudy: orderSeed.extractionResultsSet?.sleepStudyCorrected ?? '',
    },
  };
}

export function toCorrectedExtractionResultsMutationInput(
  values: OrderDocumentExtractionFormValues,
): ExtractionResultsSetCorrectionsInput {
  // For addition
  // if the user is trying to add a line item the raw extraction completely missed
  // you would pass in the quantity and hcpcs_code but without the lineItemResultsId

  // For deletion
  // If a user wants to say a line item was extracted in error
  // you would pass in the lineItemResultId filled in but with the remaining fields null
  const removedLineItems: NonNullable<ExtractionResultsSetCorrectionsInput['order']>['lineItems'] =
    values.order.removedLineItemIds.map((lineItemResultId) => ({
      lineItemResultId,
      hcpcsCode: null,
      quantity: null,
      brandName: null,
      cptCode: null,
      frequency: null,
      lengthOfNeed: null,
      productName: null,
    }));

  const removedDiagnosisItems: NonNullable<ExtractionResultsSetCorrectionsInput['order']>['dxCodes'] =
    values.order.removedDiagnosisIds.map((diagnosisResultId) => ({
      diagnosisResultId,
      diagnosisCode: null,
    }));

  return {
    resultsSetId: values.resultId,
    order: {
      dxCodes: [
        ...removedDiagnosisItems,
        ...values.order.diagnoses.map((item) => ({
          diagnosisResultId: item.resultId,
          diagnosisCode: item.dxCode?.code ?? null,
        })),
      ],
      lineItems: [
        ...removedLineItems,
        ...values.order.lineItems.map((item) => ({
          lineItemResultId: item.resultId,
          hcpcsCode: item.hcpcs?.code ?? null,
          quantity: item.quantity ? Number(item.quantity) : null,
          lengthOfNeed: item.lengthOfNeed ?? null,
          frequency: item.frequency ?? null,
          brandName: item.brandName ?? null,
          productName: item.productName ?? null,
          cptCode: item.cptCode ?? null,
        })),
      ],
      referralDate: formatDate(values.order.referralDate, 'date'),
      subcategoryId: values.order.subcategoryId || null,
      subcategoryName: values.order.subcategoryName || null,
    },
    patient: {
      firstName: values.patient.firstName,
      middleName: values.patient.middleName,
      lastName: values.patient.lastName,
      sex: values.patient.sex,
      dateOfBirth: formatDate(values.patient.dateOfBirth, 'date'),
      emailAddress: values.patient.emailAddress,
      weightInPounds: values.patient.weight ? parseFloat(values.patient.weight) : undefined,
      heightInInches: values.patient.height ? parseFloat(values.patient.height) : undefined,
      ahi: values.clinicalInfo?.patientAhi ? parseFloat(values.clinicalInfo.patientAhi) : undefined,
      bmi: values.clinicalInfo?.patientBmi ? parseFloat(values.clinicalInfo.patientBmi) : undefined,
      bp: values.clinicalInfo?.patientBp ?? undefined,
      ess: values.clinicalInfo?.patientEss ? parseFloat(values.clinicalInfo.patientEss) : undefined,
      hypopneaScore: values.clinicalInfo?.patientHypopneaScore
        ? parseFloat(values.clinicalInfo.patientHypopneaScore)
        : undefined,
      neckCircumferenceInInches: values.clinicalInfo?.patientNeckCircumferenceInInches
        ? parseFloat(values.clinicalInfo.patientNeckCircumferenceInInches)
        : undefined,
      neckSize: values.clinicalInfo?.patientNeckSize ?? undefined,
      rdi: values.clinicalInfo?.patientRdi ? parseFloat(values.clinicalInfo.patientRdi) : undefined,
      rei: values.clinicalInfo?.patientRei ? parseFloat(values.clinicalInfo.patientRei) : undefined,
      totalSleepTime: values.clinicalInfo?.patientTotalSleepTime
        ? parseFloat(values.clinicalInfo.patientTotalSleepTime)
        : undefined,
      homePhone: values.patient.homePhoneNumber,
      cellPhone: values.patient.cellPhoneNumber,
      homeAddress: {
        address1: values.homeAddress.addressLine1,
        address2: values.homeAddress.addressLine2,
        city: values.homeAddress.city,
        postalCode: values.homeAddress.postalCode,
        state: values.homeAddress.state,
      },
      shippingAddress: {
        address1: values.shippingAddress.addressLine1,
        address2: values.shippingAddress.addressLine2,
        city: values.shippingAddress.city,
        postalCode: values.shippingAddress.postalCode,
        state: values.shippingAddress.state,
      },
      primaryInsurance: {
        groupNumber: values.insurance.primary.groupNumber,
        insurerName: values.insurance.primary.insurerName,
        planName: values.insurance.primary.plan,
        policyNumber: values.insurance.primary.policyNumber,
      },
      secondaryInsurance: {
        groupNumber: values.insurance.secondary.groupNumber,
        insurerName: values.insurance.secondary.insurerName,
        planName: values.insurance.secondary.plan,
        policyNumber: values.insurance.secondary.policyNumber,
      },
      tertiaryInsurance: {
        groupNumber: values.insurance.tertiary.groupNumber,
        insurerName: values.insurance.tertiary.insurerName,
        planName: values.insurance.tertiary.plan,
        policyNumber: values.insurance.tertiary.policyNumber,
      },
    },
    provider: {
      firstName: values.referringProvider.firstName,
      lastName: values.referringProvider.lastName,
      npi: values.referringProvider.npi,
    },
    documents: {
      bloodGasStudy: values.clinicalInfo?.bloodGasStudy ?? null,
      chartNotes: values.clinicalInfo?.chartNotes ?? null,
      coverSheet: values.clinicalInfo?.coverSheet ?? null,
      prescription: values.clinicalInfo?.prescription ?? null,
      sleepStudy: values.clinicalInfo?.sleepStudy ?? null,
    },
  };
}

type OrderFieldRequirements = Record<DocumentFieldId | 'icd_10_code', string> &
  Record<'line_items', Record<LineItemField, string>[] | undefined>;

const REQUIRED_FIELD_ERROR_TEMPLATE: FieldError = {
  type: 'manual',
  message: 'Coverage may be limited without this information',
};

export function getOrderFieldRequirements(orderFieldRequirementsJSONString: string | undefined, flags?: FeatureFlags) {
  const defaultResults = {
    requirementFieldError: false,
    requirementFieldDictionary: {} as OrderFieldRequirements,
    requirementErrors: undefined,
  };

  if (!flags?.fax_intake_qualification_fields || !orderFieldRequirementsJSONString) {
    return defaultResults;
  }

  try {
    const orderFieldRequirements = JSON.parse(orderFieldRequirementsJSONString) as OrderFieldRequirements;
    const requirementFieldError = Object.values(orderFieldRequirements ?? {}).some((message) => {
      return message !== '';
    });

    return {
      requirementFieldError,
      requirementFieldDictionary: orderFieldRequirements,
      requirementErrors: requirementFieldError
        ? ({
            order: {
              ...(orderFieldRequirements.icd_10_code && {
                diagnoses: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.referral_date && {
                referralDate: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.subcategory_name && {
                subcategoryName: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
            },
            patient: {
              ...(orderFieldRequirements.patient_first_name && {
                firstName: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_middle_name && {
                middleName: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_last_name && {
                lastName: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_date_of_birth && {
                dateOfBirth: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.email && {
                emailAddress: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.height_in_inches && {
                height: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.weight_in_pounds && {
                weight: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.home_phone && {
                homePhoneNumber: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.cell_phone && {
                cellPhoneNumber: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.sex && {
                sex: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
            },
            shippingAddress: {
              ...(orderFieldRequirements.shipping_address_1 && {
                addressLine1: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.shipping_address_2 && {
                addressLine2: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.shipping_city && {
                city: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.shipping_state && {
                state: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.shipping_postal_code && {
                postalCode: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
            },
            homeAddress: {
              ...(orderFieldRequirements.home_address_1 && {
                addressLine1: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.home_address_2 && {
                addressLine2: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.home_city && {
                city: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.home_state && {
                state: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.home_postal_code && {
                postalCode: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
            },
            insurance: {
              primary: {
                ...(orderFieldRequirements.primary_insurer_name && {
                  insurerName: REQUIRED_FIELD_ERROR_TEMPLATE,
                }),
                ...(orderFieldRequirements.primary_plan_name && {
                  plan: REQUIRED_FIELD_ERROR_TEMPLATE,
                }),
                ...(orderFieldRequirements.primary_group_number && {
                  groupNumber: REQUIRED_FIELD_ERROR_TEMPLATE,
                }),
                ...(orderFieldRequirements.primary_policy_number && {
                  policyNumber: REQUIRED_FIELD_ERROR_TEMPLATE,
                }),
              },
              secondary: {
                ...(orderFieldRequirements.secondary_insurer_name && {
                  insurerName: REQUIRED_FIELD_ERROR_TEMPLATE,
                }),
                ...(orderFieldRequirements.secondary_plan_name && {
                  plan: REQUIRED_FIELD_ERROR_TEMPLATE,
                }),
                ...(orderFieldRequirements.secondary_group_number && {
                  groupNumber: REQUIRED_FIELD_ERROR_TEMPLATE,
                }),
                ...(orderFieldRequirements.secondary_policy_number && {
                  policyNumber: REQUIRED_FIELD_ERROR_TEMPLATE,
                }),
              },
            },
            referringProvider: {
              ...(orderFieldRequirements.provider_npi && {
                npi: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.provider_first_name && {
                firstName: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.provider_last_name && {
                lastName: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
            },
            clinicalInfo: {
              ...(orderFieldRequirements.blood_gas_study && {
                bloodGasStudy: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.chart_notes && {
                chartNotes: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.cover_sheet && {
                coverSheet: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_ahi && {
                patientAhi: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_bmi && {
                patientBmi: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_bp && {
                patientBp: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_ess && {
                patientEss: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_hypopnea_score && {
                patientHypopneaScore: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_last_seen_date && {
                patientLastSeenDate: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_neck_circumference_in_inches && {
                patientNeckCircumferenceInInches: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_neck_size && {
                patientNeckSize: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_rdi && {
                patientRdi: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_rei && {
                patientRei: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.patient_total_sleep_time && {
                patientTotalSleepTime: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.provider_signature_present && {
                providerSignaturePresent: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.sleep_study && {
                sleepStudy: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
              ...(orderFieldRequirements.prescription && {
                prescription: REQUIRED_FIELD_ERROR_TEMPLATE,
              }),
            } satisfies Partial<Record<keyof OrderDocumentExtractionFormValues['clinicalInfo'], any>>,
          } satisfies FieldErrors<OrderDocumentExtractionFormValues>)
        : undefined,
    };
  } catch (_err) {
    return defaultResults;
  }
}

export function mapOrderSeedCoordinateFieldsToHighlightLabel(
  orderSeed: SupplierIntakeFaxOrderSeed,
): Record<DocumentFieldId, LocationInfoType | undefined> {
  const coordinateSet = orderSeed.extractionResultsSet?.normalizedCoordinates ?? {};

  const mapping: Record<DocumentFieldId, LocationInfoType | undefined> = {
    // patient info
    patient_first_name: coordinateSet.patientFirstName ?? undefined,
    patient_middle_name: coordinateSet.patientMiddleName ?? undefined,
    patient_last_name: coordinateSet.patientLastName ?? undefined,
    patient_date_of_birth: coordinateSet.patientDateOfBirth ?? undefined,
    email: coordinateSet.patientEmailAddress ?? undefined,
    height_in_inches: coordinateSet.patientHeightInInches ?? undefined,
    weight_in_pounds: coordinateSet.patientWeightInPounds ?? undefined,
    sex: coordinateSet.patientSex ?? undefined,
    cell_phone: coordinateSet.patientCellPhone ?? undefined,
    home_phone: coordinateSet.patientHomePhone ?? undefined,
    home_address_1: coordinateSet.patientHomeAddress?.address1 ?? undefined,
    home_address_2: coordinateSet.patientHomeAddress?.address2 ?? undefined,
    home_city: coordinateSet.patientHomeAddress?.city ?? undefined,
    home_state: coordinateSet.patientHomeAddress?.state ?? undefined,
    home_postal_code: coordinateSet.patientHomeAddress?.postalCode ?? undefined,
    shipping_address_1: coordinateSet.patientShippingAddress?.address1 ?? undefined,
    shipping_address_2: coordinateSet.patientShippingAddress?.address2 ?? undefined,
    shipping_city: coordinateSet.patientShippingAddress?.city ?? undefined,
    shipping_state: coordinateSet.patientShippingAddress?.state ?? undefined,
    shipping_postal_code: coordinateSet.patientShippingAddress?.postalCode ?? undefined,
    primary_insurer_name: coordinateSet.patientPrimaryInsurance?.insurerName ?? undefined,
    primary_plan_name: coordinateSet.patientPrimaryInsurance?.planName ?? undefined,
    primary_group_number: coordinateSet.patientPrimaryInsurance?.groupNumber ?? undefined,
    primary_policy_number: coordinateSet.patientPrimaryInsurance?.policyNumber ?? undefined,
    secondary_insurer_name: coordinateSet.patientSecondaryInsurance?.insurerName ?? undefined,
    secondary_plan_name: coordinateSet.patientSecondaryInsurance?.planName ?? undefined,
    secondary_group_number: coordinateSet.patientSecondaryInsurance?.groupNumber ?? undefined,
    secondary_policy_number: coordinateSet.patientSecondaryInsurance?.policyNumber ?? undefined,

    // provider info
    provider_first_name: coordinateSet.referringProviderFirstName ?? undefined,
    provider_last_name: coordinateSet.referringProviderLastName ?? undefined,
    provider_npi: coordinateSet.referringProviderNpi ?? undefined,

    // order info
    referral_date: coordinateSet.orderReferralDate ?? undefined,
    subcategory_name: coordinateSet.orderSubcategoryName ?? undefined,

    // TODO: These fields are undefined because they do not have gql schema coordinate fields in the extraction results as of now
    blood_gas_study: undefined,
    chart_notes: undefined,
    cover_sheet: undefined,
    patient_ahi: undefined,
    patient_bmi: undefined,
    patient_bp: undefined,
    patient_ess: undefined,
    patient_hypopnea_score: undefined,
    patient_last_seen_date: undefined,
    patient_neck_circumference_in_inches: undefined,
    patient_neck_size: undefined,
    patient_rdi: undefined,
    patient_rei: undefined,
    patient_total_sleep_time: undefined,
    prescription: undefined,
    provider_signature_present: undefined,
    sleep_study: undefined,
  };

  coordinateSet.lineItems?.forEach((lineItem, index) => {
    mapping[`line_item_${index + 1}_hcpcs`] = lineItem?.hcpcsCode ?? undefined;
    mapping[`line_item_${index + 1}_quantity`] = lineItem?.quantity ?? undefined;
    mapping[`line_item_${index + 1}_cpt_code`] = lineItem?.quantity ?? undefined;
    mapping[`line_item_${index + 1}_length_of_need`] = lineItem?.quantity ?? undefined;
    mapping[`line_item_${index + 1}_frequency`] = lineItem?.quantity ?? undefined;
    mapping[`line_item_${index + 1}_brand_name`] = lineItem?.quantity ?? undefined;
    mapping[`line_item_${index + 1}_product_name`] = lineItem?.quantity ?? undefined;
  });

  coordinateSet.dxCodes?.forEach((dxCode, index) => {
    mapping[`dx_code_${index + 1}`] = dxCode?.diagnosisCode ?? undefined;
  });

  return mapping;
}

export const documentFieldIdToLabelMap = {
  patient_first_name: 'First Name',
  patient_middle_name: 'Middle Name',
  patient_last_name: 'Last Name',
  patient_date_of_birth: 'Date of Birth',
  height_in_inches: 'Height (inches)',
  weight_in_pounds: 'Weight (pounds)',
  email: 'Email',
  sex: 'Gender',
  home_phone: 'Home Phone Number',
  cell_phone: 'Cell Phone Number',

  primary_insurer_name: 'Insurer Name',
  primary_plan_name: 'Insurer Plan',
  primary_group_number: 'Group Number',
  primary_policy_number: 'Policy Number',

  secondary_insurer_name: 'Insurer Name',
  secondary_plan_name: 'Insurer Plan',
  secondary_group_number: 'Group Number',
  secondary_policy_number: 'Policy Number',

  shipping_address_1: 'Address Line 1',
  shipping_address_2: 'Address Line 2',
  shipping_city: 'City',
  shipping_state: 'State',
  shipping_postal_code: 'Zip Code',

  home_address_1: 'Address Line 1',
  home_address_2: 'Address Line 2',
  home_city: 'City',
  home_state: 'State',
  home_postal_code: 'Zip Code',

  provider_npi: 'NPI',
  provider_first_name: 'First Name',
  provider_last_name: 'Last Name',

  referral_date: 'Referral Date',
  subcategory_name: 'Subcategory Name',

  // clinicial info
  chart_notes: 'Chart Notes',
  blood_gas_study: 'Blood Gas Study',
  cover_sheet: 'Cover Sheet',
  patient_ahi: 'Patient AHI',
  patient_bmi: 'Patient BMI',
  patient_bp: 'Patient Blood Pressure',
  patient_ess: 'Patient ESS',
  patient_hypopnea_score: 'Patient Hypopnea Score',
  patient_last_seen_date: 'Patient Last Seen Date',
  patient_neck_circumference_in_inches: 'Patient Neck Circumference',
  patient_neck_size: 'Patient Neck Size',
  patient_rdi: 'Patient RDI',
  patient_rei: 'Patient REI',
  patient_total_sleep_time: 'Patient Total Sleep Time',
  prescription: 'Prescription',
  provider_signature_present: 'Provider Signature Present',
  sleep_study: 'Sleep Study',
};

export type LineItemField =
  | 'hcpcs'
  | 'quantity'
  | 'length_of_need'
  | 'frequency'
  | 'brand_name'
  | 'cpt_code'
  | 'product_name';

export type DocumentFieldId =
  | keyof typeof documentFieldIdToLabelMap
  | `dx_code_${number}`
  | `line_item_${number}_${LineItemField}`;
