import { PropsWithChildren } from 'react';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface DetailCardProps extends PropsWithChildren {
  sx?: SxProps;
}

export const DetailCard = ({ sx, children }: DetailCardProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        bgcolor: 'rgb(247, 248, 249)',
        borderRadius: '2px',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export const DetailItem = ({
  children,
  label,
  compact,
  disableTypography,
}: {
  children: React.ReactNode;
  label: React.ReactNode;
  compact?: boolean;
  disableTypography?: boolean;
}) => {
  const content = disableTypography ? (
    children
  ) : (
    <Typography
      sx={{
        wordBreak: 'break-word',
        wordWrap: 'break-word',
      }}
    >
      {children}
    </Typography>
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={compact ? undefined : 5}>
        <Typography color="#5D7179" fontWeight="600" variant="caption">
          {label}:
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {content}
      </Grid>
    </Grid>
  );
};
