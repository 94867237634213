import { OrderDisplayStatus } from '../../api-clients/falcon-api/hooks/useOrderStatuses';
import {
  RiCalendarLine,
  RiCheckDoubleLine,
  RiCheckLine,
  RiCloseLine,
  RiErrorWarningLine,
  RiFileList2Line,
  RiGitBranchLine,
  RiPenNibLine,
  RiQuestionLine,
  RiStore2Line,
  RiSurveyLine,
  RiTimeLine,
  RiTruckLine,
} from '@remixicon/react';
import { theme } from '@tomorrow/ui/theme';
import React from 'react';

export interface OrderDisplayStatusProps {
  badge: {
    color: string;
    icon: React.ReactElement;
  };
  subLabelColor?: string;
  patientViewGroup: 'active' | 'past';
  practiceOrderDescription?: React.ReactNode;
  careCoordinatorOrderDescription?: React.ReactNode;
  timelineDescription?: string;
}

const orderDisplayStatusProps: Record<OrderDisplayStatus, OrderDisplayStatusProps> = {
  Accepted: {
    badge: { color: theme.colors.muiGreen, icon: <RiCheckLine /> },
    patientViewGroup: 'active',
  },
  Cancelled: {
    badge: { color: theme.colors.dullGray, icon: <RiCloseLine /> },
    subLabelColor: theme.colors.secondaryHover,
    patientViewGroup: 'past',
    practiceOrderDescription: (
      <>
        The order has been canceled. If you have more questions about the reason, please message Tomorrow Health below.
      </>
    ),
  },
  'Appointment Required': {
    badge: { color: theme.colors.muiError, icon: <RiCalendarLine /> },
    patientViewGroup: 'active',
    practiceOrderDescription: (
      <>
        You are responsible for ensuring the patient sets up a new appointment. Tomorrow Health will not action on this
        referral until a new appointment date is updated online.
      </>
    ),
  },
  'Awaiting Appointment': {
    badge: { color: theme.colors.muiError, icon: <RiCalendarLine /> },
    patientViewGroup: 'active',
    practiceOrderDescription: (
      <>
        We are waiting for the patient to see their referring provider. Tomorrow Health will not action on the referral
        until then.
      </>
    ),
  },
  Delivered: {
    badge: { color: theme.colors.muiGreen, icon: <RiCheckDoubleLine /> },
    patientViewGroup: 'past',
    practiceOrderDescription: <>The order has been delivered to the patient.</>,
  },
  'Draft Order': {
    badge: { color: theme.colors.lightViolet, icon: <RiFileList2Line /> },
    patientViewGroup: 'active',
    practiceOrderDescription: (
      <>
        You&apos;ve started the order, but it&apos;s not finished. To move the order along so it gets to Tomorrow Health
        and a supplier, you need to complete intake by clicking “continue draft order” below.
      </>
    ),
  },
  'Draft Referral': {
    badge: { color: theme.colors.lightViolet, icon: <RiFileList2Line /> },
    patientViewGroup: 'active',
  },
  'Matching To Supplier': {
    badge: { color: theme.colors.lightDuskBlue, icon: <RiGitBranchLine /> },
    patientViewGroup: 'active',
    practiceOrderDescription: <>Tomorrow Health is finding the best supplier for this order.</>,
  },
  'More Info Needed': {
    badge: { color: theme.colors.muiError, icon: <RiQuestionLine /> },
    patientViewGroup: 'active',
    practiceOrderDescription: (
      <>
        More information is needed for this order to move forward. This may be a piece of information or documentation.
        Please see “Messages” for more.
      </>
    ),
  },
  'Needs Signature': {
    badge: { color: theme.colors.muiError, icon: <RiPenNibLine /> },
    patientViewGroup: 'active',
    practiceOrderDescription: (
      <>The referring provider has received a link to sign the order, but they have not signed the order yet.</>
    ),
  },
  'Order Under Review': {
    badge: { color: theme.colors.gold, icon: <RiTimeLine /> },
    patientViewGroup: 'active',
    practiceOrderDescription: (
      <>
        Tomorrow Health is reviewing the order to make sure all required information is there. Next, the order will be
        sent to a DME supplier.
      </>
    ),
  },
  'Partially Delivered': {
    badge: { color: theme.colors.muiGreen, icon: <RiCheckLine /> },
    patientViewGroup: 'active',
  },
  'Partially delivered': {
    badge: { color: theme.colors.muiGreen, icon: <RiCheckLine /> },
    patientViewGroup: 'active',
  },
  'Pending Acceptance': {
    badge: { color: theme.colors.warningRed, icon: <RiErrorWarningLine /> },
    patientViewGroup: 'active',
  },
  'Pending acceptance': {
    badge: { color: theme.colors.warningRed, icon: <RiErrorWarningLine /> },
    patientViewGroup: 'active',
  },
  'Referral Under Review': {
    badge: { color: theme.colors.gold, icon: <RiTimeLine /> },
    patientViewGroup: 'active',
  },
  Rejected: {
    badge: { color: theme.colors.warningRed, icon: <RiCloseLine /> },
    patientViewGroup: 'past',
  },
  'Sent To Supplier': {
    badge: { color: theme.colors.lightDuskBlue, icon: <RiStore2Line /> },
    patientViewGroup: 'active',
    practiceOrderDescription: (
      <>
        The order has been sent to a supplier, and we are waiting for them to review and then accept it or reject it. If
        they accept it, they will start working on the order. If they reject it, we will send the order to a different
        supplier.
      </>
    ),
  },
  Shipped: {
    badge: { color: theme.colors.gold, icon: <RiTruckLine /> },
    patientViewGroup: 'active',
    practiceOrderDescription: <>The supplier has shipped the order.</>,
    timelineDescription: 'Tracking Number Added',
  },
  'Supplier Working On Order': {
    badge: { color: theme.colors.lightDuskBlue, icon: <RiSurveyLine /> },
    patientViewGroup: 'active',
    practiceOrderDescription: (
      <>
        The supplier is working on the order. Check “Messages” for any updates, documentation requests or to chat with
        the supplier to receive an update.
      </>
    ),
  },
  'Supplier Working On Referral': {
    badge: { color: theme.colors.lightDuskBlue, icon: <RiSurveyLine /> },
    patientViewGroup: 'active',
    careCoordinatorOrderDescription: (
      <>
        The supplier is working on the referral. Check “Messages” for any updates, documentation requests or to chat
        with the supplier to receive an update.
      </>
    ),
  },
};

export function getOrderDisplayStatusProps(status: OrderDisplayStatus): OrderDisplayStatusProps {
  return orderDisplayStatusProps[status];
}

export function getCancellationReasonText(
  falconStatus:
    | { reasonCode?: { code?: string; externalDescription?: string } | null; reasonText?: string | null }
    | undefined,
) {
  return ['C10', 'R0'].includes(falconStatus?.reasonCode?.code ?? '') && falconStatus?.reasonText
    ? falconStatus?.reasonText
    : falconStatus?.reasonCode?.externalDescription;
}
