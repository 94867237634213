import { forwardRef } from 'react';
import { normalizePhoneNumber } from '../utils/phoneUtils';
import InputMask from 'react-input-mask';
import TextField, { TextFieldProps } from '@mui/material/TextField';

// TODO: Consider migrating away from this library as it is no longer maintained
// ? https://github.com/mona-health/react-input-mask/ is a maintained fork
// ? Reference: https://github.com/sanniassin/react-input-mask/issues/318#issuecomment-1847707208
interface PhoneInputProps extends Omit<TextFieldProps, 'value'> {
  value?: string;
}

export const PhoneInput = forwardRef<HTMLElement, PhoneInputProps>(function PhoneInput(props, ref) {
  const { onBlur, onChange, onFocus, value, ...rest } = props;
  return (
    <InputMask
      disabled={props.disabled}
      mask="(999) 999-9999"
      onBlur={onBlur}
      onChange={(e) => onChange?.({ ...e, target: { ...e.target, value: normalizePhoneNumber(e.target.value) } })}
      onFocus={onFocus}
      value={value}
    >
      {/* @ts-ignore */}
      {(inputProps) => <TextField {...rest} {...inputProps} inputRef={ref} />}
    </InputMask>
  );
});
