import { ClinicalSummaryFeedbackButton } from '../components/ClinicalSummaryFeedbackButton';
import { ColorsV2 } from '@tomorrow/ui/theme';
import { DocumentFieldId } from './DocumentStep.utils';
import { forwardRef, memo, useImperativeHandle } from 'react';
import { InputSection } from '../components/InputSection';
import { OrderWizardStepComponentProps, StepRef } from '../../IntakeFaxOrderWizard';
import { SupplierIntakeFaxOrderSeed } from '../SupplierFaxOrderWizard.utils';
import { useCurrentOrgUser } from '../../../../router/useCurrentOrgUser';
import Box from '@mui/material/Box';
import Markdown from 'react-markdown';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// This component is expected to be rendered when use with Synthpop integration specifically (as of now)
export const AutoQualificationsStep = memo(
  forwardRef<StepRef, OrderWizardStepComponentProps<SupplierIntakeFaxOrderSeed, DocumentFieldId>>(
    function AutoQualificationsStep({ orderSeed, onNextStep, onOrderProduce, logIntakeAction }, ref) {
      useImperativeHandle(
        ref,
        () => ({
          triggerFormSubmit: () => {
            onOrderProduce((draft) => {
              draft.completedAutoQualificationsReviewStep = true;
            });

            onNextStep(orderSeed);
          },
        }),
        [onNextStep, onOrderProduce, orderSeed],
      );

      const currentOrgUser = useCurrentOrgUser();

      return (
        <Box display="flex" flexGrow="1">
          <Stack bgcolor={ColorsV2.white} flexGrow="1" padding="20px 40px" spacing={2}>
            <Typography fontWeight="600" variant="h5">
              Auto Qualification Results
            </Typography>
            <InputSection
              label={
                <Box alignItems="center" display="flex" gap={1} justifyContent="space-between">
                  <Typography fontWeight="600" variant="h6">
                    Clinical Summary
                  </Typography>
                  {orderSeed.id && orderSeed.extractionResultsSet?.id && (
                    <ClinicalSummaryFeedbackButton
                      extractionResultsSetId={orderSeed.extractionResultsSet.id}
                      onLogIntakeAction={(actionType) => {
                        const orderSeedId = orderSeed.id;
                        const extractionResultsSetId = orderSeed.extractionResultsSet?.id;

                        switch (actionType) {
                          case 'opened':
                            logIntakeAction('clinical_summary_feedback_opened', {
                              orderSeedId,
                              extractionResultsSetId,
                              orgId: currentOrgUser.org.id,
                              orgUserId: currentOrgUser.id,
                            });
                            break;
                          case 'closed':
                            logIntakeAction('clinical_summary_feedback_closed', {
                              orderSeedId,
                              extractionResultsSetId,
                              orgId: currentOrgUser.org.id,
                              orgUserId: currentOrgUser.id,
                            });
                            break;
                          case 'submitted':
                            logIntakeAction('clinical_summary_feedback_submit', {
                              orderSeedId,
                              extractionResultsSetId,
                              orgId: currentOrgUser.org.id,
                              orgUserId: currentOrgUser.id,
                            });
                            break;
                          default:
                            break;
                        }
                      }}
                    />
                  )}
                </Box>
              }
            >
              {orderSeed.extractionResultsSet?.synthpopClinicalSummary ? (
                <Typography component="div">
                  <Markdown>{orderSeed.extractionResultsSet?.synthpopClinicalSummary}</Markdown>
                </Typography>
              ) : (
                <Typography>No clinical summary available</Typography>
              )}
            </InputSection>
          </Stack>
        </Box>
      );
    },
  ),
);
