export enum PATHS {
  CARE_COORDINATORS = '/care-coordinators',
  CARE_COORDINATOR = '/care-coordinators/:orgId',
  CARE_COORDINATOR_MEMBERS = '/care-coordinators/:orgId/members',
  CARE_COORDINATOR_REFERRALS = '/care-coordinators/:orgId/referrals',
  CARE_COORDINATOR_REFERRALS_INTAKE_NEW = '/care-coordinators/:orgId/referrals/new/intake/:step?',
  CARE_COORDINATOR_REFERRALS_INTAKE_EDIT = '/care-coordinators/:orgId/referrals/:orderId/intake/:step?',
  CARE_COORDINATOR_SETTINGS = '/care-coordinators/:orgId/settings',
  CARE_COORDINATOR_SETTINGS_MY_TEAM = '/care-coordinators/:orgId/settings/my-team',
  CARE_COORDINATOR_SETTINGS_NOTIFICATIONS = '/care-coordinators/:orgId/settings/notifications',

  // practice routes
  PRACTICES = '/practices',
  PRACTICE = '/practices/:orgId',
  PRACTICE_BAA = '/practices/:orgId/baa',
  PRACTICE_MANAGEMENT = '/practices/:orgId/practice-management',
  PRACTICE_ORDERS = '/practices/:orgId/orders',
  PRACTICE_ORDERS_DOCUMENT = '/practices/:orgId/orders/:orderId/document/:documentId',
  PRACTICE_ORDERS_INTAKE_NEW = '/practices/:orgId/orders/new/intake/:step?',
  PRACTICE_ORDERS_INTAKE_EDIT = '/practices/:orgId/orders/:orderId/intake/:step?',
  PRACTICE_ORDERS_SUCCESS = '/practices/:orgId/orders/:orderId/success',
  PRACTICE_ORDER_FAX_INTAKE_QUALIFICATIONS_VIEW_ONLY = '/practices/:orgId/orders/faxes/:orderSeedId/intake/qualifications_view_only/:documentId/:step?',
  PRACTICE_PATIENTS = '/practices/:orgId/patients',
  PRACTICE_PATIENT_EHR = '/practices/:orgId/patients/ehr',
  PRACTICE_PATIENT = '/practices/:orgId/patients/:patientId',
  PRACTICE_SETTINGS = '/practices/:orgId/settings',
  PRACTICE_SETTINGS_NOTIFICATIONS = '/practices/:orgId/settings/notifications',
  PRACTICE_SETTINGS_PRACTICE_MANAGEMENT = '/practices/:orgId/settings/practice-management',

  // Supplier routes
  SUPPLIERS = '/suppliers',
  SUPPLIER = '/suppliers/:orgId',
  SUPPLIER_ORDERS = '/suppliers/:orgId/orders',
  SUPPLIER_ORDERS_INTAKE_NEW = '/suppliers/:orgId/orders/new/intake/:step?',
  SUPPLIER_ORDERS_INTAKE_EDIT = '/suppliers/:orgId/orders/:orderId/intake/:step?',
  SUPPLIER_ORDER_FAX_INTAKE_QUALIFICATIONS = '/suppliers/:orgId/orders/faxes/:orderSeedId/intake/qualifications/:step?',
  SUPPLIER_ORDER_FAX_INTAKE_QUALIFICATIONS_VIEW_ONLY = '/suppliers/:orgId/orders/faxes/:orderSeedId/intake/qualifications_view_only/:documentId/:step?',

  SUPPLIER_FAX_ORDERS = '/suppliers/:orgId/faxes',
  SUPPLIER_FAX_ORDERS_INTAKE_EDIT = '/suppliers/:orgId/faxes/:orderSeedId/intake/:step?',
  SUPPLIER_PATIENT = '/suppliers/:orgId/patients/:patientId',
  SUPPLIER_PERFORMANCE = '/suppliers/:orgId/performance',
  SUPPLIER_PRIOR_AUTH = '/suppliers/:orgId/prior-auth',
  SUPPLIER_PRIOR_AUTH_NEW = '/suppliers/:orgId/prior-auth/new/intake',
  SUPPLIER_SETTINGS = '/suppliers/:orgId/settings',
  SUPPLIER_SETTINGS_NOTIFICATIONS = '/suppliers/:orgId/settings/notifications',
  SUPPLIER_SETTINGS_CAPABILITIES = '/suppliers/:orgId/settings/capabilities',
  SUPPLIER_SETTINGS_MY_TEAM = '/suppliers/:orgId/settings/my-team',

  // General Routes
  HOME = '/',
  LOGIN = '/login',
  POST_LOGOUT = '/post-logout',
  NOT_FOUND = '/404',
  UNAUTHORIZED = '/unauthorized',
  EHR_UNAUTHORIZED = '/ehr-unauthorized',
}
