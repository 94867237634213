import { BirdLogoLoaderScreen } from '../components/BirdLogoLoaderScreen';
import { Buffer } from 'buffer';
import { createContext, useContext, useEffect, useState } from 'react';
import { env } from '../env';
import { GenericError } from '@auth0/auth0-spa-js';
import { logError, logWarning } from '../utils/logging';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { wrapStorage } from '../utils/wrapStorage';
import qs from 'qs';

export type HttpHeaders = Record<string, string>;

// this is necessary because fetcher() is a static method with no proper way to
// get access to the authState in React context. A better solution would be to
// change the fetcher config in codegen.ts to `isReactHook: true` option
// https://www.graphql-code-generator.com/plugins/typescript-react-query#usage-example-isreacthook-true
export const fetcherConfig = {
  // eslint-disable-next-line @typescript-eslint/require-await
  async getHeaders(): Promise<HttpHeaders> {
    return {};
  },
};

// When authenticating via EHR in a patient context, this will be set, and
// used to lock the current window to only show this patient.
export type EhrContext = { type: 'user'; patientId: undefined } | { type: 'patient'; patientId: string };

export interface AuthMeta {
  ehr?: EhrContext;
  headers: HttpHeaders;
}

export type AuthState =
  | { status: 'loading' }
  | { status: 'error'; error: Error }
  | { status: 'anonymous'; login(redirectUrl: string): void }
  | { status: 'authenticated'; logout?(returnTo: string): void; getMeta(): Promise<AuthMeta>; authType: string };

export const AuthContext = createContext<AuthState>({ status: 'loading' });

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuthController();
  const location = useLocation();

  // Private route protection is done as high in the stack as possible to
  // expedite rerouting to Auth0. This avoids loading a lot of extraneous
  // JS and React components just to immediately redirect to the login page.
  const isPrivateRoute = ['/login', '/practices/*', '/suppliers/*', '/care-coordinators/*'].some((path) =>
    matchPath(path, location.pathname),
  );

  useEffect(() => {
    if (isPrivateRoute && auth.status === 'anonymous') {
      auth.login(window.location.pathname + window.location.search);
    }
  }, [auth, isPrivateRoute]);

  // Wait for Auth callback (if there is one from Auth0 and/or EHR) to finish
  // before loading the App and potentially redirecting to the 404 page.
  const isAuthCallback = ['/callback', '/ehr-callback'].some((path) => matchPath(path, location.pathname));

  // Error state is allowed to unblock <App /> rendering so that it can log
  // errors to Sentry. App is then responsible for blocking child rendering.
  // Alternatively we could lazy load and render a dedicated auth error view
  if (isAuthCallback || (isPrivateRoute && auth.status !== 'authenticated' && auth.status !== 'error')) {
    return <BirdLogoLoaderScreen />;
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

function useAuthController() {
  const [authState, setAuthState] = useState<AuthState>({ status: 'loading' });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function executeAuthFlow(signal: AbortSignal) {
      try {
        const result =
          authViaDevFhir(navigate, location) ??
          (await authViaFhir(navigate, location)) ??
          authViaSudo(navigate, location) ??
          (await authViaAuth0(navigate, location));

        // getHeaders should be set before setAuthState so that calls such as
        // getUser immediately have access to new headers. Ideally, the fetcher
        // function would be a React hook and not need this global variable.
        fetcherConfig.getHeaders = async () => {
          try {
            return result.status === 'authenticated' ? (await result.getMeta()).headers : {};
          } catch (err) {
            logError(err);
            throw new Error('Unauthorized');
          }
        };

        if (!signal.aborted) setAuthState(result);
      } catch (error) {
        if (!signal.aborted) setAuthState({ status: 'error', error: error as Error });
      }
    }

    const abort = new AbortController();
    executeAuthFlow(abort.signal);
    return () => abort.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return authState;
}

async function authViaAuth0(
  navigate: ReturnType<typeof useNavigate>,
  location: ReturnType<typeof useLocation>,
): Promise<AuthState> {
  const [{ default: Cookie }, { createAuth0Client }] = await Promise.all([
    import('js-cookie'),
    import('@auth0/auth0-spa-js'),
  ]);
  const client = await createAuth0Client({
    authorizationParams: {
      audience: env.REACT_APP_AUTH0_AUDIENCE,
      redirect_uri: `${window.location.origin}/callback`,
    },
    clientId: env.REACT_APP_AUTH0_CLIENT_ID,
    domain: env.REACT_APP_AUTH0_DOMAIN,
    useRefreshTokens: true,
    /*
    When e2e tests are running, in order to avoid roundtrip to Auth0 on every
    test, auth credentials are cached in localstorage. See auth.setup.ts

    | Storing tokens in browser local storage provides persistence across page
    | refreshes and browser tabs. However, if an attacker can achieve running
    | JavaScript in the SPA using a cross-site scripting (XSS) attack, they can
    | retrieve the tokens stored in local storage. A vulnerability leading to a
    | successful XSS attack can be either in the SPA source code or in any
    | third-party JavaScript code (such as bootstrap, jQuery, or Google
    | Analytics) included in the SPA.
    - https://auth0.com/docs/libraries/auth0-single-page-app-sdk#change-storage-options
    */
    cacheLocation: Cookie.get('client-is-playwright') ? 'localstorage' : 'memory',
  });

  const nonceStorage = wrapStorage<string>(window.sessionStorage, 'auth.auth0-nonce');
  const nonce = nonceStorage.getJSON();

  if (nonce && location.pathname && location.search.includes('code=')) {
    nonceStorage.setJSON(null);
    const { appState } = await client.handleRedirectCallback();

    if (appState[nonce]?.redirectUrl) {
      navigate(appState[nonce].redirectUrl, { replace: true });
    } else {
      client.logout();
    }
  }

  function logout(returnTo: string) {
    client.logout({ logoutParams: { returnTo } });
  }

  function login(redirectUrl: string) {
    // @ts-ignore msCrypto = IE11
    const crypto = window.crypto || window.msCrypto;
    const nonce = Buffer.from(crypto.getRandomValues(new Uint8Array(16))).toString('base64');

    nonceStorage.setJSON(nonce);
    client.loginWithRedirect({ appState: { [nonce]: { redirectUrl } } });
  }

  async function getMeta(): Promise<AuthMeta> {
    /*
    ? Exception handler introduced for the Auth0 SDK v2.0.0 upgrade.
    ? Needed for when useRefreshTokensFallback option is set to false (which is now the default)
    https://github.com/auth0/auth0-spa-js/blob/main/FAQ.md#why-am-i-getting-a-missing_refresh_token-error-after-upgrading-to-v2
    */
    try {
      const token = await client.getTokenSilently();
      return { headers: { Authorization: `Bearer ${token}` } };
    } catch (e) {
      if (e instanceof GenericError) {
        // If the refresh token is missing or invalid, the user needs to log in again
        if (e.error === 'missing_refresh_token' || e.error === 'invalid_grant') {
          logWarning('Missing Refresh Token Error', { errorType: e.name });
          client.loginWithRedirect();
        }
      }

      throw e;
    }
  }

  return (await client.isAuthenticated())
    ? { status: 'authenticated', logout, getMeta, authType: 'auth0' }
    : { status: 'anonymous', login };
}

let loggedFhirUrl = '';
async function authViaFhir(
  navigate: ReturnType<typeof useNavigate>,
  location: ReturnType<typeof useLocation>,
): Promise<AuthState | undefined> {
  interface PostAuthRedirect {
    pathname: string;
    search: string;
  }

  // used to redirect user back to their intended page after auth flow
  const redirectStorage = wrapStorage<PostAuthRedirect>(window.sessionStorage, 'auth.fhir.redirect');

  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  // The first page request from the EHR system will have iss+launch params,
  // which are used to start an oauth launch flow
  const { iss, launch, ...rest } = params;
  if (typeof iss === 'string' && typeof launch === 'string') {
    const redirectUri = `${window.location.origin}/ehr-callback`;
    try {
      const { useGetEhrClientQuery } = await import('../api-clients/falcon-api/graphql/queries/getEhrClient.generated');
      const { fhirClient } = await useGetEhrClientQuery.fetcher({ fhirServerUrl: iss, launch })();
      if (!fhirClient) throw new Error('EHR not configured with Tomorrow Health');
      const { clientId, scopes } = fhirClient;
      if (!clientId) throw new Error('Missing client ID for EHR integration');
      if (!scopes) throw new Error('Missing scope for EHR integration');
      // Used to send the user back to their original page after going through the
      // auth redirect, which always returns to a fixed url (/ehr-callback).
      redirectStorage.setJSON({ pathname: location.pathname, search: qs.stringify(rest) });
      const { oauth2 } = await import('fhirclient');
      await oauth2.authorize({ clientId, scope: scopes, redirectUri, iss, launch, completeInTarget: true });

      // authorize() should trigger an immediate browser navigate, aborting any
      // running JS, so this next line should never run, but if that behavior
      // changed, this should block other auth providers from starting.
      return { status: 'loading' };
    } catch (err) {
      const { logAndShowError } = await import('../utils/errorHandlerHelper');
      logAndShowError(err, 'Problem with Tomorrow Health EHR integration');
      return { status: 'error', error: err as Error };
    }
  }

  // The second page request will have some of these params. This is how
  // AuthContext knows to complete the FHIR auth flow.
  // https://github.com/smart-on-fhir/client-js/blob/6daa7a5a8db32f2598266130d9c9cce5fcd4a34c/src/smart.ts#L470
  const { code, error, error_description } = params;
  const hasStoredClientState = window.sessionStorage.getItem('SMART_KEY');
  const redirect = redirectStorage.getJSON();
  if (hasStoredClientState || (redirect && (code || error || error_description))) {
    const { oauth2 } = await import('fhirclient');

    const fhirClient = await oauth2.ready().catch((err: Error) => err);

    if (redirect) {
      redirectStorage.setJSON(null);
      navigate(redirect, { replace: true });
    }

    if (fhirClient instanceof Error) {
      throw fhirClient;
    } else if (!fhirClient?.state?.tokenResponse?.access_token) {
      throw new Error('Unauthorized');
    }

    const getMeta = async function getMeta(): Promise<AuthMeta> {
      // refreshIfNeeded currently does nothing but return the current state
      // because refresh tokens are disabled in the EHR integration (at least with Epic's IDP)
      const { tokenResponse, serverUrl } = await fhirClient.refreshIfNeeded();

      if (!tokenResponse) throw new Error('Missing FHIR token response');
      if (!tokenResponse.access_token) throw new Error('Missing FHIR access token');
      if (!tokenResponse.id_token) throw new Error('Missing FHIR id token');
      if (!serverUrl) throw new Error('Missing FHIR server URL');

      const headers: HttpHeaders = {
        Authorization: `Bearer ${tokenResponse.access_token}`,
        'TH-EHR-ID-Token': tokenResponse.id_token,
        'TH-EHR-FHIR-Server': serverUrl,
      };

      const ehr: EhrContext = tokenResponse.patient
        ? { type: 'patient', patientId: tokenResponse.patient }
        : { type: 'user', patientId: undefined };

      if (env.REACT_APP_ENV !== 'production') {
        // Fabricate a URL to launch a local instance of the app that can
        // utilize EHR credentials from staging. This is useful for running
        // the frontend locally that points to staging backend, while using a
        // staging launchURL from the EHR sandboxes.
        const params: FhirQueryStringParams = {
          access: tokenResponse.access_token,
          id: tokenResponse.id_token,
          server: serverUrl,
          patientId: tokenResponse.patient,
        };

        const localFhirUrl = `http://localhost:3000/practices${qs.stringify(params, { addQueryPrefix: true })}`;

        if (localFhirUrl !== loggedFhirUrl) {
          // eslint-disable-next-line no-console
          console.log(localFhirUrl);
          loggedFhirUrl = localFhirUrl;
        }
      }

      return { headers, ehr };
    };

    return { status: 'authenticated', getMeta, authType: 'fhir' };
  }

  return undefined;
}

interface FhirQueryStringParams {
  access: unknown;
  id: unknown;
  server: unknown;
  patientId: unknown;
}

function authViaDevFhir(
  _navigate: ReturnType<typeof useNavigate>,
  location: ReturnType<typeof useLocation>,
): AuthState | undefined {
  if (env.REACT_APP_ENV !== 'production') {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Partial<FhirQueryStringParams>;
    if (params.access && params.id && params.server) {
      const meta: AuthMeta = {
        headers: {
          Authorization: `Bearer ${String(params.access)}`,
          'TH-EHR-ID-Token': String(params.id),
          'TH-EHR-FHIR-Server': String(params.server),
        },
        ehr: params.patientId
          ? { type: 'patient', patientId: String(params.patientId) }
          : { type: 'user', patientId: undefined },
      };

      return {
        status: 'authenticated',
        getMeta: () => Promise.resolve(meta),
        authType: 'dev-fhir',
      };
    }
  }

  return undefined;
}

function authViaSudo(
  navigate: ReturnType<typeof useNavigate>,
  location: ReturnType<typeof useLocation>,
): AuthState | undefined {
  // Retool users can use Care Advocate Mode (Sign in as a user — "sudo mode")
  // to pass auth info from the adminSignInAs mutation to the frontend app via
  // these querystring parameters.
  interface SudoParams {
    headers: HttpHeaders;
    targetEmail: string; // Not used for anything... delete?
    expirationTimestamp: number;
  }

  const unixTimestampNow = Date.now() / 1000;
  const paramsStorage = wrapStorage<SudoParams>(window.localStorage, 'auth.sudo-token');

  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { sudoBearerToken, sudoTargetEmail, sudoExpiresAtTimestamp, ...rest } = params;
  if (sudoBearerToken || sudoTargetEmail || sudoExpiresAtTimestamp) {
    if (
      typeof sudoBearerToken !== 'string' ||
      typeof sudoTargetEmail !== 'string' ||
      typeof sudoExpiresAtTimestamp !== 'string'
    ) {
      throw new Error('Did not find all expected fields when parsing sudo query string');
    }

    const expirationTimestamp = parseInt(sudoExpiresAtTimestamp, 10);

    paramsStorage.setJSON({
      headers: { Authorization: `Bearer ${sudoBearerToken}` },
      targetEmail: sudoTargetEmail,
      expirationTimestamp,
    });

    if (unixTimestampNow > expirationTimestamp) {
      throw new Error('Sudo token is already expired');
    }

    navigate({ search: qs.stringify(rest) }, { replace: true });
  }

  const sudoParams = paramsStorage.getJSON();

  if (sudoParams && unixTimestampNow > sudoParams.expirationTimestamp) {
    paramsStorage.setJSON(null);
    return undefined;
  }

  function logout(returnTo: string) {
    paramsStorage.setJSON(null);
    window.location.assign(returnTo); // Full page reload to re-init React states
  }

  function getMeta() {
    // todo: expiration?
    return Promise.resolve({ headers: sudoParams?.headers ?? {} });
  }

  return sudoParams ? { status: 'authenticated', logout, getMeta, authType: 'sudo' } : undefined;
}
