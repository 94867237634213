import { findOrgUser, useCurrentUser } from '../api-clients/falcon-api/hooks/useCurrentUser';
import { Navigate } from 'react-router-dom';
import { OrgTypes } from '../api-clients/falcon-api/graphql/types.generated';
import { PATHS } from './paths';
import { useUserLocalStorage } from '../hooks/useUserStorage';
import SpinnerScreen from '../components/Spinner/SpinnerScreen';

export function NavigateToFirstOrgByType({ orgType }: { orgType: OrgTypes }) {
  const { data: user } = useCurrentUser();
  const { data: userStorageData, isLoading: isUserStorageDataLoading } = useUserLocalStorage();
  const orgId = userStorageData?.last_used_org_id;
  const firstOrgId = findOrgUser(user, { orgId })?.org?.id ?? findOrgUser(user, { orgTypeEnum: orgType })?.org?.id;

  return !user || isUserStorageDataLoading ? (
    <SpinnerScreen fullHeight />
  ) : (
    <Navigate replace to={firstOrgId ?? PATHS.HOME} />
  );
}
