import { theme } from '@tomorrow/ui/theme';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface Props {
  errorText: string;
  margin?: string;
}

const ErrorInfo = ({ errorText, margin }: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: theme.colors.errorBannerBackground,
        borderRadius: '5px',
        minHeight: '65px',
        padding: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: margin ?? '0px',
      }}
    >
      <Typography color={theme.colors.negative} variant="h6">
        {errorText}
      </Typography>
    </Box>
  );
};

export default ErrorInfo;
