import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type GetDiagnosisCodesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetDiagnosisCodesQuery = {
  __typename?: 'Query';
  diagnosisCodes?: {
    __typename?: 'DiagnosisTypeConnection';
    totalCount?: number | null;
    edges: Array<{
      __typename?: 'DiagnosisTypeEdge';
      node?: { __typename?: 'DiagnosisType'; code: string; description?: string | null } | null;
    } | null>;
  } | null;
};

export type DiagnosisCodeFragment = { __typename?: 'DiagnosisType'; code: string; description?: string | null };

export const DiagnosisCodeFragmentDoc = `
    fragment DiagnosisCodeFragment on DiagnosisType {
  code
  description
}
    `;
export const GetDiagnosisCodesDocument = `
    query getDiagnosisCodes($first: Int, $searchText: String) {
  diagnosisCodes(first: $first, searchText: $searchText) {
    totalCount
    edges {
      node {
        ...DiagnosisCodeFragment
      }
    }
  }
}
    ${DiagnosisCodeFragmentDoc}`;

export const useGetDiagnosisCodesQuery = <TData = GetDiagnosisCodesQuery, TError = unknown>(
  variables?: GetDiagnosisCodesQueryVariables,
  options?: Omit<UseQueryOptions<GetDiagnosisCodesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetDiagnosisCodesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetDiagnosisCodesQuery, TError, TData>({
    queryKey: variables === undefined ? ['getDiagnosisCodes'] : ['getDiagnosisCodes', variables],
    queryFn: fetcher<GetDiagnosisCodesQuery, GetDiagnosisCodesQueryVariables>(GetDiagnosisCodesDocument, variables),
    ...options,
  });
};

useGetDiagnosisCodesQuery.getKey = (variables?: GetDiagnosisCodesQueryVariables) =>
  variables === undefined ? ['getDiagnosisCodes'] : ['getDiagnosisCodes', variables];

export const useInfiniteGetDiagnosisCodesQuery = <TData = InfiniteData<GetDiagnosisCodesQuery>, TError = unknown>(
  variables: GetDiagnosisCodesQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetDiagnosisCodesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetDiagnosisCodesQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetDiagnosisCodesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['getDiagnosisCodes.infinite']
            : ['getDiagnosisCodes.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetDiagnosisCodesQuery, GetDiagnosisCodesQueryVariables>(GetDiagnosisCodesDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetDiagnosisCodesQuery.getKey = (variables?: GetDiagnosisCodesQueryVariables) =>
  variables === undefined ? ['getDiagnosisCodes.infinite'] : ['getDiagnosisCodes.infinite', variables];

useGetDiagnosisCodesQuery.fetcher = (variables?: GetDiagnosisCodesQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GetDiagnosisCodesQuery, GetDiagnosisCodesQueryVariables>(GetDiagnosisCodesDocument, variables, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDiagnosisCodesQuery(
 *   ({ query, variables }) => {
 *     const { first, searchText } = variables;
 *     return HttpResponse.json({
 *       data: { diagnosisCodes }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetDiagnosisCodesQuery = (
  resolver: GraphQLResponseResolver<GetDiagnosisCodesQuery, GetDiagnosisCodesQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GetDiagnosisCodesQuery, GetDiagnosisCodesQueryVariables>('getDiagnosisCodes', resolver, options);
