import * as Types from '../types.generated.js';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type SendFaxbackCoversheetMutationVariables = Types.Exact<{
  input: Types.FaxbackCoversheetInput;
}>;

export type SendFaxbackCoversheetMutation = {
  __typename?: 'Mutation';
  sendFaxbackCoversheet?: {
    __typename?: 'SendFaxbackCoversheetMutation';
    pdfUrl?: string | null;
    transmission?: { __typename?: 'FaxTransmissionType'; createdAt: string; updatedAt: string; id: string } | null;
    error?: { __typename?: 'ErrorType'; id?: string | null; message: string } | null;
  } | null;
};

export const SendFaxbackCoversheetDocument = `
    mutation sendFaxbackCoversheet($input: FaxbackCoversheetInput!) {
  sendFaxbackCoversheet(inputData: $input) {
    transmission {
      createdAt
      updatedAt
      id
    }
    pdfUrl
    error {
      id
      message
    }
  }
}
    `;

export const useSendFaxbackCoversheetMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<SendFaxbackCoversheetMutation, TError, SendFaxbackCoversheetMutationVariables, TContext>,
) => {
  return useMutation<SendFaxbackCoversheetMutation, TError, SendFaxbackCoversheetMutationVariables, TContext>({
    mutationKey: ['sendFaxbackCoversheet'],
    mutationFn: (variables?: SendFaxbackCoversheetMutationVariables) =>
      fetcher<SendFaxbackCoversheetMutation, SendFaxbackCoversheetMutationVariables>(
        SendFaxbackCoversheetDocument,
        variables,
      )(),
    ...options,
  });
};

useSendFaxbackCoversheetMutation.getKey = () => ['sendFaxbackCoversheet'];

useSendFaxbackCoversheetMutation.fetcher = (
  variables: SendFaxbackCoversheetMutationVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<SendFaxbackCoversheetMutation, SendFaxbackCoversheetMutationVariables>(
    SendFaxbackCoversheetDocument,
    variables,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSendFaxbackCoversheetMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { sendFaxbackCoversheet }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSendFaxbackCoversheetMutation = (
  resolver: GraphQLResponseResolver<SendFaxbackCoversheetMutation, SendFaxbackCoversheetMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<SendFaxbackCoversheetMutation, SendFaxbackCoversheetMutationVariables>(
    'sendFaxbackCoversheet',
    resolver,
    options,
  );
