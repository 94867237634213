import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type GetLanguagesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetLanguagesQuery = {
  __typename?: 'Query';
  languages?: Array<{ __typename?: 'LanguageType'; name: string; code: string } | null> | null;
};

export const GetLanguagesDocument = `
    query getLanguages {
  languages {
    name
    code
  }
}
    `;

export const useGetLanguagesQuery = <TData = GetLanguagesQuery, TError = unknown>(
  variables?: GetLanguagesQueryVariables,
  options?: Omit<UseQueryOptions<GetLanguagesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetLanguagesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetLanguagesQuery, TError, TData>({
    queryKey: variables === undefined ? ['getLanguages'] : ['getLanguages', variables],
    queryFn: fetcher<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, variables),
    ...options,
  });
};

useGetLanguagesQuery.getKey = (variables?: GetLanguagesQueryVariables) =>
  variables === undefined ? ['getLanguages'] : ['getLanguages', variables];

export const useInfiniteGetLanguagesQuery = <TData = InfiniteData<GetLanguagesQuery>, TError = unknown>(
  variables: GetLanguagesQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetLanguagesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetLanguagesQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetLanguagesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['getLanguages.infinite']
            : ['getLanguages.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetLanguagesQuery.getKey = (variables?: GetLanguagesQueryVariables) =>
  variables === undefined ? ['getLanguages.infinite'] : ['getLanguages.infinite', variables];

useGetLanguagesQuery.fetcher = (variables?: GetLanguagesQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, variables, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLanguagesQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { languages }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetLanguagesQuery = (
  resolver: GraphQLResponseResolver<GetLanguagesQuery, GetLanguagesQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GetLanguagesQuery, GetLanguagesQueryVariables>('getLanguages', resolver, options);
