import { BannerRouteTabs } from '../BannerRouteTabs';
import { generatePath } from 'react-router-dom';
import { OrgSelect } from '../OrgSelect/OrgSelect';
import { PATHS } from '../../router/paths';
import { RiHeartLine } from '@remixicon/react';
import { theme } from '@tomorrow/ui/theme';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useOrgIdParam } from '../../hooks/useOrgIdParam';
import BannerIconContainer from '../BannerIconContainer';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import NewOrderButton from '../NewOrderButton/NewOrderButton';
import qs from 'qs';

export function CareCoordinatorBanner() {
  const orgId = useOrgIdParam() ?? '';
  const flags = useFeatureFlags();

  const tabs = [
    { label: 'Referrals', path: generatePath(PATHS.CARE_COORDINATOR_REFERRALS, { orgId }) },
    ...(flags.rp_patients_screen
      ? [{ label: 'Members', path: generatePath(PATHS.CARE_COORDINATOR_MEMBERS, { orgId }) }]
      : []),
  ];

  return (
    <Box bgcolor={theme.colors.white} pt="20px" width="100%">
      <Container>
        <Grid container spacing={1}>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <BannerIconContainer>
                  <RiHeartLine color="rgb(93, 113, 121)" size="32px" />
                </BannerIconContainer>
              </Grid>
              <Grid item>
                <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                  <OrgSelect />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs>
            <Box alignItems="center" display="flex" height="100%" justifyContent="flex-end">
              <NewReferralButton />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              {tabs.length > 1 ? <BannerRouteTabs tabs={tabs} /> : null}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export function NewReferralButton({ patientId }: { patientId?: string }) {
  const orgId = useOrgIdParam() ?? '';

  return (
    <NewOrderButton
      text="New Referral"
      to={
        generatePath(PATHS.CARE_COORDINATOR_REFERRALS_INTAKE_NEW, { orgId, step: null }) +
        qs.stringify({ patientId }, { addQueryPrefix: true })
      }
    />
  );
}
