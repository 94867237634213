import * as Types from '../types.generated.js';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type CreateExtractionFeedbackMutationVariables = Types.Exact<{
  input: Types.ExtractionFeedbackInput;
}>;

export type CreateExtractionFeedbackMutation = {
  __typename?: 'Mutation';
  createExtractionFeedbackMutation?: {
    __typename?: 'CreateExtractionFeedbackMutation';
    error?: { __typename?: 'ErrorType'; id?: string | null; type?: string | null; message: string } | null;
  } | null;
};

export const CreateExtractionFeedbackMutationDocument = `
    mutation createExtractionFeedbackMutation($input: ExtractionFeedbackInput!) {
  createExtractionFeedbackMutation(inputData: $input) {
    error {
      id
      type
      message
    }
  }
}
    `;

export const useCreateExtractionFeedbackMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateExtractionFeedbackMutation,
    TError,
    CreateExtractionFeedbackMutationVariables,
    TContext
  >,
) => {
  return useMutation<CreateExtractionFeedbackMutation, TError, CreateExtractionFeedbackMutationVariables, TContext>({
    mutationKey: ['createExtractionFeedbackMutation'],
    mutationFn: (variables?: CreateExtractionFeedbackMutationVariables) =>
      fetcher<CreateExtractionFeedbackMutation, CreateExtractionFeedbackMutationVariables>(
        CreateExtractionFeedbackMutationDocument,
        variables,
      )(),
    ...options,
  });
};

useCreateExtractionFeedbackMutation.getKey = () => ['createExtractionFeedbackMutation'];

useCreateExtractionFeedbackMutation.fetcher = (
  variables: CreateExtractionFeedbackMutationVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<CreateExtractionFeedbackMutation, CreateExtractionFeedbackMutationVariables>(
    CreateExtractionFeedbackMutationDocument,
    variables,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateExtractionFeedbackMutationMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createExtractionFeedbackMutation }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateExtractionFeedbackMutationMutation = (
  resolver: GraphQLResponseResolver<CreateExtractionFeedbackMutation, CreateExtractionFeedbackMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<CreateExtractionFeedbackMutation, CreateExtractionFeedbackMutationVariables>(
    'createExtractionFeedbackMutation',
    resolver,
    options,
  );
