import * as Types from '../types.generated.js';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type OrgRevokeDocumentExtractionJobMutationVariables = Types.Exact<{
  input: Types.OrgRevokeDocumentExtractionJobInput;
}>;

export type OrgRevokeDocumentExtractionJobMutation = {
  __typename?: 'Mutation';
  orgRevokeDocumentExtractionJob?: {
    __typename?: 'OrgRevokeDocumentExtractionJobMutation';
    error?: { __typename?: 'ErrorType'; id?: string | null; type?: string | null; message: string } | null;
  } | null;
};

export const OrgRevokeDocumentExtractionJobDocument = `
    mutation orgRevokeDocumentExtractionJob($input: OrgRevokeDocumentExtractionJobInput!) {
  orgRevokeDocumentExtractionJob(inputData: $input) {
    error {
      id
      type
      message
    }
  }
}
    `;

export const useOrgRevokeDocumentExtractionJobMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    OrgRevokeDocumentExtractionJobMutation,
    TError,
    OrgRevokeDocumentExtractionJobMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    OrgRevokeDocumentExtractionJobMutation,
    TError,
    OrgRevokeDocumentExtractionJobMutationVariables,
    TContext
  >({
    mutationKey: ['orgRevokeDocumentExtractionJob'],
    mutationFn: (variables?: OrgRevokeDocumentExtractionJobMutationVariables) =>
      fetcher<OrgRevokeDocumentExtractionJobMutation, OrgRevokeDocumentExtractionJobMutationVariables>(
        OrgRevokeDocumentExtractionJobDocument,
        variables,
      )(),
    ...options,
  });
};

useOrgRevokeDocumentExtractionJobMutation.getKey = () => ['orgRevokeDocumentExtractionJob'];

useOrgRevokeDocumentExtractionJobMutation.fetcher = (
  variables: OrgRevokeDocumentExtractionJobMutationVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<OrgRevokeDocumentExtractionJobMutation, OrgRevokeDocumentExtractionJobMutationVariables>(
    OrgRevokeDocumentExtractionJobDocument,
    variables,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockOrgRevokeDocumentExtractionJobMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { orgRevokeDocumentExtractionJob }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockOrgRevokeDocumentExtractionJobMutation = (
  resolver: GraphQLResponseResolver<
    OrgRevokeDocumentExtractionJobMutation,
    OrgRevokeDocumentExtractionJobMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<OrgRevokeDocumentExtractionJobMutation, OrgRevokeDocumentExtractionJobMutationVariables>(
    'orgRevokeDocumentExtractionJob',
    resolver,
    options,
  );
