import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type GetOrderSeedPotentialOrderMatchesQueryVariables = Types.Exact<{
  orderSeedId: Types.Scalars['UUID']['input'];
  orgId: Types.Scalars['UUID']['input'];
  patientId: Types.Scalars['UUID']['input'];
}>;

export type GetOrderSeedPotentialOrderMatchesQuery = {
  __typename?: 'Query';
  orderSeedPotentialOrderMatches?: Array<{
    __typename?: 'OrderType';
    id: string;
    createdAt: string;
    friendlyId?: string | null;
    intakeSubcategory?: { __typename?: 'IntakeSubcategoryType'; name: string } | null;
    referringProvider?: { __typename?: 'ProviderType'; firstName: string; lastName: string } | null;
    falconStatus?: {
      __typename?: 'OrderStatusHistoryType';
      reasonText?: string | null;
      rpStatus?: string | null;
      status: Types.AppOrderStatusHistoryStatusChoices;
      networkSupplier?: {
        __typename?: 'OptimizedNetworkSupplierType';
        intakePhoneNumber?: string | null;
        name: string;
      } | null;
      reasonCode?: { __typename?: 'OrderStatusReasonType'; code: string; externalDescription: string } | null;
    } | null;
    currentAssignment?: {
      __typename?: 'OrderAssignmentType';
      trackingNumberSummary: {
        __typename?: 'OrderAssignmentTrackingNumberSummaryType';
        hasFailedTrackingNumber: boolean;
        hasTrackingNumber: boolean;
      };
    } | null;
  } | null> | null;
};

export type PotentialOrderMatchFragment = {
  __typename?: 'OrderType';
  id: string;
  createdAt: string;
  friendlyId?: string | null;
  intakeSubcategory?: { __typename?: 'IntakeSubcategoryType'; name: string } | null;
  referringProvider?: { __typename?: 'ProviderType'; firstName: string; lastName: string } | null;
  falconStatus?: {
    __typename?: 'OrderStatusHistoryType';
    reasonText?: string | null;
    rpStatus?: string | null;
    status: Types.AppOrderStatusHistoryStatusChoices;
    networkSupplier?: {
      __typename?: 'OptimizedNetworkSupplierType';
      intakePhoneNumber?: string | null;
      name: string;
    } | null;
    reasonCode?: { __typename?: 'OrderStatusReasonType'; code: string; externalDescription: string } | null;
  } | null;
  currentAssignment?: {
    __typename?: 'OrderAssignmentType';
    trackingNumberSummary: {
      __typename?: 'OrderAssignmentTrackingNumberSummaryType';
      hasFailedTrackingNumber: boolean;
      hasTrackingNumber: boolean;
    };
  } | null;
};

export const PotentialOrderMatchFragmentDoc = `
    fragment PotentialOrderMatchFragment on OrderType {
  id
  createdAt
  friendlyId
  intakeSubcategory {
    name
  }
  referringProvider {
    firstName
    lastName
  }
  falconStatus {
    networkSupplier {
      intakePhoneNumber
      name
    }
    reasonCode {
      code
      externalDescription
    }
    reasonText
    rpStatus
    status
  }
  currentAssignment {
    trackingNumberSummary {
      hasFailedTrackingNumber
      hasTrackingNumber
    }
  }
}
    `;
export const GetOrderSeedPotentialOrderMatchesDocument = `
    query getOrderSeedPotentialOrderMatches($orderSeedId: UUID!, $orgId: UUID!, $patientId: UUID!) {
  orderSeedPotentialOrderMatches(
    orderSeedId: $orderSeedId
    orgId: $orgId
    patientId: $patientId
  ) {
    ...PotentialOrderMatchFragment
  }
}
    ${PotentialOrderMatchFragmentDoc}`;

export const useGetOrderSeedPotentialOrderMatchesQuery = <
  TData = GetOrderSeedPotentialOrderMatchesQuery,
  TError = unknown,
>(
  variables: GetOrderSeedPotentialOrderMatchesQueryVariables,
  options?: Omit<UseQueryOptions<GetOrderSeedPotentialOrderMatchesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetOrderSeedPotentialOrderMatchesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetOrderSeedPotentialOrderMatchesQuery, TError, TData>({
    queryKey: ['getOrderSeedPotentialOrderMatches', variables],
    queryFn: fetcher<GetOrderSeedPotentialOrderMatchesQuery, GetOrderSeedPotentialOrderMatchesQueryVariables>(
      GetOrderSeedPotentialOrderMatchesDocument,
      variables,
    ),
    ...options,
  });
};

useGetOrderSeedPotentialOrderMatchesQuery.getKey = (variables: GetOrderSeedPotentialOrderMatchesQueryVariables) => [
  'getOrderSeedPotentialOrderMatches',
  variables,
];

export const useInfiniteGetOrderSeedPotentialOrderMatchesQuery = <
  TData = InfiniteData<GetOrderSeedPotentialOrderMatchesQuery>,
  TError = unknown,
>(
  variables: GetOrderSeedPotentialOrderMatchesQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetOrderSeedPotentialOrderMatchesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetOrderSeedPotentialOrderMatchesQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetOrderSeedPotentialOrderMatchesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getOrderSeedPotentialOrderMatches.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetOrderSeedPotentialOrderMatchesQuery, GetOrderSeedPotentialOrderMatchesQueryVariables>(
            GetOrderSeedPotentialOrderMatchesDocument,
            { ...variables, ...(metaData.pageParam ?? {}) },
          )(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetOrderSeedPotentialOrderMatchesQuery.getKey = (
  variables: GetOrderSeedPotentialOrderMatchesQueryVariables,
) => ['getOrderSeedPotentialOrderMatches.infinite', variables];

useGetOrderSeedPotentialOrderMatchesQuery.fetcher = (
  variables: GetOrderSeedPotentialOrderMatchesQueryVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<GetOrderSeedPotentialOrderMatchesQuery, GetOrderSeedPotentialOrderMatchesQueryVariables>(
    GetOrderSeedPotentialOrderMatchesDocument,
    variables,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetOrderSeedPotentialOrderMatchesQuery(
 *   ({ query, variables }) => {
 *     const { orderSeedId, orgId, patientId } = variables;
 *     return HttpResponse.json({
 *       data: { orderSeedPotentialOrderMatches }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetOrderSeedPotentialOrderMatchesQuery = (
  resolver: GraphQLResponseResolver<
    GetOrderSeedPotentialOrderMatchesQuery,
    GetOrderSeedPotentialOrderMatchesQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<GetOrderSeedPotentialOrderMatchesQuery, GetOrderSeedPotentialOrderMatchesQueryVariables>(
    'getOrderSeedPotentialOrderMatches',
    resolver,
    options,
  );
