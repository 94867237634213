import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type GetProductsQueryVariables = Types.Exact<{
  searchText: Types.Scalars['String']['input'];
  categoryIds?: Types.InputMaybe<Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input']>;
  subcategoryIds?: Types.InputMaybe<Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input']>;
  productIds?: Types.InputMaybe<Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input']>;
}>;

export type GetProductsQuery = {
  __typename?: 'Query';
  products?: {
    __typename?: 'ProductTypeConnection';
    totalCount?: number | null;
    edges: Array<{
      __typename?: 'ProductTypeEdge';
      node?: {
        __typename?: 'ProductType';
        description: string;
        id: string;
        imageUrl?: string | null;
        name: string;
        subproducts?: Array<{
          __typename?: 'SubproductType';
          hcpcs: { __typename?: 'HcpcsType'; code: string };
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type ProductFragment = {
  __typename?: 'ProductType';
  description: string;
  id: string;
  imageUrl?: string | null;
  name: string;
  subproducts?: Array<{
    __typename?: 'SubproductType';
    hcpcs: { __typename?: 'HcpcsType'; code: string };
  } | null> | null;
};

export const ProductFragmentDoc = `
    fragment ProductFragment on ProductType {
  description
  id
  imageUrl
  name
  subproducts {
    hcpcs {
      code
    }
  }
}
    `;
export const GetProductsDocument = `
    query getProducts($searchText: String!, $categoryIds: [UUID!], $subcategoryIds: [UUID!], $productIds: [UUID!]) {
  products(
    searchText: $searchText
    categoryIds: $categoryIds
    subcategoryIds: $subcategoryIds
    productIds: $productIds
  ) {
    totalCount
    edges {
      node {
        ...ProductFragment
      }
    }
  }
}
    ${ProductFragmentDoc}`;

export const useGetProductsQuery = <TData = GetProductsQuery, TError = unknown>(
  variables: GetProductsQueryVariables,
  options?: Omit<UseQueryOptions<GetProductsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetProductsQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetProductsQuery, TError, TData>({
    queryKey: ['getProducts', variables],
    queryFn: fetcher<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, variables),
    ...options,
  });
};

useGetProductsQuery.getKey = (variables: GetProductsQueryVariables) => ['getProducts', variables];

export const useInfiniteGetProductsQuery = <TData = InfiniteData<GetProductsQuery>, TError = unknown>(
  variables: GetProductsQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetProductsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetProductsQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetProductsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getProducts.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetProductsQuery.getKey = (variables: GetProductsQueryVariables) => ['getProducts.infinite', variables];

useGetProductsQuery.fetcher = (variables: GetProductsQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, variables, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetProductsQuery(
 *   ({ query, variables }) => {
 *     const { searchText, categoryIds, subcategoryIds, productIds } = variables;
 *     return HttpResponse.json({
 *       data: { products }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetProductsQuery = (
  resolver: GraphQLResponseResolver<GetProductsQuery, GetProductsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GetProductsQuery, GetProductsQueryVariables>('getProducts', resolver, options);
