import { addMonths, isValid, milliseconds, startOfToday, subYears } from 'date-fns';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { FieldValues, useController, UseControllerProps, useWatch } from 'react-hook-form';
import { FormInputProps, Input } from '../FormInputs';
import {
  GetWaiversQuery,
  GetWaiversQueryVariables,
  useGetWaiversQuery,
} from '../../api-clients/falcon-api/graphql/queries/getWaivers.generated';
import { Select } from '../Select/Select';
import { useEffect, useMemo } from 'react';
import { useInsurers } from '../../api-clients/falcon-api/hooks/useInsurers';
import { useLanguages } from '../../api-clients/falcon-api/hooks/useLanguages';
import { UseQueryOptions } from '@tanstack/react-query';
import DatePicker from '../DatePicker/DatePicker';
import TextField from '@mui/material/TextField';

const MAX_PATIENT_AGE_YEARS = 120;
const MAX_FUTURE_DATE_OF_BIRTH_MONTHS = 18; // 1.5 years allows for future birth dates e.g. breast pump orders
const minDateOfBirth = subYears(startOfToday(), MAX_PATIENT_AGE_YEARS);
const maxDateOfBirth = addMonths(startOfToday(), MAX_FUTURE_DATE_OF_BIRTH_MONTHS);

export function PatientDateOfBirthInput<TFieldValues extends FieldValues>({
  label,
  onBlur,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  ...props
}: Omit<FormInputProps<TFieldValues>, 'type'>) {
  const { field, fieldState } = useController({
    ...props,
    rules: {
      validate: (value: Date) => {
        if (!value) return undefined;
        if (!isValid(value)) return 'Date of birth must be a valid date';
        if (value < minDateOfBirth) return 'Date of birth is too long ago';
        if (value > maxDateOfBirth) return 'Date of birth is too far in the future';

        return undefined;
      },
      ...props.rules,
    },
  });

  return (
    <DatePicker
      {...field}
      error={!!fieldState.error?.message}
      helperText={fieldState.error?.message}
      label={label}
      maxDate={maxDateOfBirth}
      minDate={minDateOfBirth}
      onBlur={() => {
        field.onBlur();
        onBlur?.();
      }}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      required={!!props.rules?.required}
      size="small"
    />
  );
}

export function PatientHeightInput<TFieldValues extends FieldValues>(props: FormInputProps<TFieldValues>) {
  return (
    <Input
      {...props}
      rules={{
        validate(value) {
          if (!value) return undefined;
          const num = Number(value);
          return Number.isNaN(num) || num < 12.0 || num > 100.0 || num !== Number(num.toFixed(2))
            ? 'Height should be a number between 12 and 100 with up to 2 decimal places'
            : undefined;
        },
        ...props.rules,
      }}
    />
  );
}

export function PatientWeightInput<TFieldValues extends FieldValues>(props: FormInputProps<TFieldValues>) {
  return (
    <Input
      {...props}
      rules={{
        validate(value) {
          if (!value) return undefined;
          const num = Number(value);
          return Number.isNaN(num) || num < 1.0 || num > 999.0 || num !== Number(num.toFixed(2))
            ? 'Weight should be a number between 1 and 999 with up to 2 decimal places'
            : undefined;
        },
        ...props.rules,
      }}
    />
  );
}

export function PatientLanguagePreferenceInput<TFieldValues extends FieldValues>({
  label,
  ...props
}: Omit<FormInputProps<TFieldValues>, 'type'>) {
  const languages = useLanguages();
  const { field, fieldState } = useController(props);

  return (
    <Select
      {...field}
      error={!!fieldState.error?.message}
      errorText={fieldState.error?.message}
      id={field.name}
      label={label}
      options={languages.data}
      required={!!props.rules?.required}
      size="small"
    />
  );
}

export function InsurancePrimaryCarrierInput<TFieldValues extends FieldValues>({
  error,
  ...rest
}: UseControllerProps<TFieldValues> & { error: string | undefined }) {
  const options = useInsuranceCarrierOptions();
  const { field } = useController({
    ...rest,
    rules: {
      ...rest.rules,
      deps: ['insurance', 'insurance.primary.planId', 'insurance.primary.policyNumber'] as any[],
    },
  });

  return (
    <Autocomplete
      {...field}
      errorText={error}
      id={field.name}
      inputLabel="Insurance (e.g. Geisinger Health Plan, Aetna)"
      onFocus={() => undefined}
      onSelect={field.onChange}
      options={options}
    />
  );
}

export function InsurancePrimaryPlanInput<TFieldValues extends FieldValues>({
  watchFieldName,
  ...rest
}: UseControllerProps<TFieldValues> & { watchFieldName: UseControllerProps<TFieldValues>['name'] }) {
  const carrierId = useWatch({ control: rest.control, name: watchFieldName });
  const options = useInsurancePlanOptions(carrierId);
  const { field, fieldState } = useController({
    ...rest,
    rules: {
      ...rest.rules,
      required: {
        value: false, // todo: make required -- being discussed
        message: 'Insurance plan is required',
      },
    },
  });

  const optionExists = options.some((plan) => plan.value === field.value);

  if (!optionExists && field.value) {
    field.onChange('');
  }

  return (
    <Autocomplete
      {...field}
      disabled={(field.disabled ?? false) || !options.length}
      errorText={fieldState.error?.message}
      id={field.name}
      inputLabel="Plan (e.g. Geisinger Gold, Aetna PPO)"
      onFocus={() => undefined}
      onSelect={field.onChange}
      options={options}
    />
  );
}

export function InsurancePrimaryMemberIdInput<TFieldValues extends FieldValues>({
  watchFieldName,
  ...rest
}: UseControllerProps<TFieldValues> & { watchFieldName: UseControllerProps<TFieldValues>['name'] }) {
  const carrierId = useWatch({ control: rest.control, name: watchFieldName });
  const {
    field: { ref, ...field },
    fieldState,
  } = useController({
    ...rest,
    rules: {
      ...rest.rules,
      required: {
        value: !!carrierId,
        message: 'Member ID is required',
      },
    },
  });

  return (
    <TextField
      {...field}
      disabled={(field.disabled ?? false) || !carrierId}
      error={!!fieldState.error?.message}
      helperText={fieldState.error?.message}
      id={field.name}
      inputRef={ref}
      label="Member ID"
      size="small"
      type="text"
    />
  );
}

export function InsuranceSecondaryCarrierInput<TFieldValues extends FieldValues>({
  error,
  ...rest
}: UseControllerProps<TFieldValues> & { error: string | undefined }) {
  const options = useInsuranceCarrierOptions();
  const { field } = useController({
    ...rest,
    rules: {
      ...rest.rules,
      deps: ['insurance', 'insurance.secondary.planId', 'insurance.secondary.policyNumber'] as any[],
    },
  });

  return (
    <Autocomplete
      {...field}
      errorText={error}
      id={field.name}
      inputLabel="Insurance (e.g. Geisinger Health Plan, Aetna)"
      onFocus={() => undefined}
      onSelect={field.onChange}
      options={options}
    />
  );
}

export function InsuranceSecondaryPlanInput<TFieldValues extends FieldValues>({
  watchFieldName,
  ...rest
}: UseControllerProps<TFieldValues> & { watchFieldName: UseControllerProps<TFieldValues>['name'] }) {
  const carrierId = useWatch({ control: rest.control, name: watchFieldName });
  const options = useInsurancePlanOptions(carrierId);
  const { field, fieldState } = useController({
    ...rest,
    rules: {
      ...rest.rules,
      required: {
        value: false, // !!carrierId, -- TODO make required -- being discussed
        message: 'Insurance plan is required',
      },
    },
  });

  const optionExists = options.some((plan) => plan.value === field.value);

  useEffect(() => {
    if (!optionExists) field.onChange('');
  }, [optionExists, field]);

  return (
    <Autocomplete
      {...field}
      disabled={(field.disabled ?? false) || !options.length}
      errorText={fieldState.error?.message}
      id={field.name}
      inputLabel="Plan (e.g. Geisinger Gold, Aetna PPO)"
      onFocus={() => undefined}
      onSelect={field.onChange}
      options={options}
    />
  );
}

export function InsuranceSecondaryMemberIdInput<TFieldValues extends FieldValues>({
  watchFieldName,
  ...rest
}: UseControllerProps<TFieldValues> & { watchFieldName: UseControllerProps<TFieldValues>['name'] }) {
  const carrierId = useWatch({ control: rest.control, name: watchFieldName });
  const {
    field: { ref, ...field },
    fieldState,
  } = useController({
    ...rest,
    rules: {
      ...rest.rules,
      required: {
        value: !!carrierId,
        message: 'Member ID is required',
      },
    },
  });

  return (
    <TextField
      {...field}
      disabled={(field.disabled ?? false) || !carrierId}
      error={!!fieldState.error?.message}
      helperText={fieldState.error?.message}
      id={field.name}
      inputRef={ref}
      label="Member ID"
      size="small"
      type="text"
    />
  );
}

export function InsuranceWaiverInput<TFieldValues extends FieldValues>({
  error,
  watchFieldName,
  ...rest
}: UseControllerProps<TFieldValues> & {
  watchFieldName: UseControllerProps<TFieldValues>['name'];
  error: string | undefined;
}) {
  const state = useWatch({ control: rest.control, name: watchFieldName });
  const options = useWaivers({ state }, { select: toWaiverOptions });

  const { field } = useController({
    ...rest,
    rules: {
      ...rest.rules,
      deps: ['insurance'] as any[],
    },
  });

  return (
    <Autocomplete
      {...field}
      disabled={(field.disabled ?? false) || !options.data?.length}
      errorText={error}
      id={field.name}
      inputLabel="Waiver"
      onFocus={() => undefined}
      onSelect={field.onChange}
      options={options.data ?? []}
    />
  );
}

function useInsuranceCarrierOptions() {
  const { data: insurers } = useInsurers();
  return useMemo((): React.ComponentProps<typeof Autocomplete>['options'] => {
    return insurers?.map((carrier) => ({ id: carrier.id, label: carrier.name, value: carrier.id })) ?? [];
  }, [insurers]);
}

function useInsurancePlanOptions(carrierId: string) {
  const { data: insurers } = useInsurers();
  return useMemo((): React.ComponentProps<typeof Autocomplete>['options'] => {
    const carrier = carrierId ? insurers?.find((ins) => ins.id === carrierId) : undefined;
    return carrier?.plans?.map((plan) => ({ id: plan.id, label: plan.name, value: plan.id })) ?? [];
  }, [carrierId, insurers]);
}

export function useWaivers<TData = GetWaiversQuery>(
  vars: GetWaiversQueryVariables,
  options?: Partial<UseQueryOptions<GetWaiversQuery, Error, TData>>,
) {
  return useGetWaiversQuery(
    {
      onlyActive: true,
      orderBy: 'name',
      ...vars,
    },
    {
      enabled: !!vars.state,
      staleTime: milliseconds({ minutes: 5 }),
      meta: {
        errorMessage: 'Failed to load waivers.',
      },
      ...options,
    },
  );
}

function toWaiverOptions(query: GetWaiversQuery): React.ComponentProps<typeof Autocomplete>['options'] {
  return (
    query.waivers?.edges?.flatMap((edge) => (edge?.node ? [{ label: edge.node.name, value: edge.node.id }] : [])) ?? []
  );
}
