import { logAction } from '../utils/logging';
import { useLocation, useNavigate } from 'react-router-dom';
import Tab, { tabClasses } from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export function BannerRouteTabs({ tabs }: { tabs: { label: React.ReactNode; path: string }[] }) {
  const navigate = useNavigate();
  const location = useLocation();
  const matchedTab = tabs.find((tab) => {
    return new RegExp(`^${tab.path}`).test(location.pathname);
  });

  return (
    <Tabs
      sx={{
        '& .MuiTabs-indicator': {
          height: '3px',
          display: 'inline-block',
        },
        '& .MuiTab-root': {
          [`&.${tabClasses.selected}`]: {
            '&:not(:hover)': {
              backgroundColor: 'transparent',
            },
          },
        },
      }}
      value={matchedTab?.path ?? false}
    >
      {tabs.map((tab) => {
        return (
          <Tab
            key={tab.path}
            label={tab.label}
            onClick={() => {
              logAction(`Click '${tab.label?.toString()}' tab`);
              navigate(tab.path);
            }}
            value={tab.path}
          />
        );
      })}
    </Tabs>
  );
}
