import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type GetInsurerHierarchyQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetInsurerHierarchyQuery = {
  __typename?: 'Query';
  insurerHierarchy?: Array<{
    __typename?: 'InsurerType';
    name: string;
    id: string;
    eligibilityShowErrors: boolean;
    plans?: Array<{
      __typename?: 'InsurerType';
      name: string;
      id: string;
      eligibilityShowErrors: boolean;
    } | null> | null;
  } | null> | null;
};

export const GetInsurerHierarchyDocument = `
    query getInsurerHierarchy {
  insurerHierarchy {
    name
    id
    eligibilityShowErrors
    plans {
      name
      id
      eligibilityShowErrors
    }
  }
}
    `;

export const useGetInsurerHierarchyQuery = <TData = GetInsurerHierarchyQuery, TError = unknown>(
  variables?: GetInsurerHierarchyQueryVariables,
  options?: Omit<UseQueryOptions<GetInsurerHierarchyQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetInsurerHierarchyQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetInsurerHierarchyQuery, TError, TData>({
    queryKey: variables === undefined ? ['getInsurerHierarchy'] : ['getInsurerHierarchy', variables],
    queryFn: fetcher<GetInsurerHierarchyQuery, GetInsurerHierarchyQueryVariables>(
      GetInsurerHierarchyDocument,
      variables,
    ),
    ...options,
  });
};

useGetInsurerHierarchyQuery.getKey = (variables?: GetInsurerHierarchyQueryVariables) =>
  variables === undefined ? ['getInsurerHierarchy'] : ['getInsurerHierarchy', variables];

export const useInfiniteGetInsurerHierarchyQuery = <TData = InfiniteData<GetInsurerHierarchyQuery>, TError = unknown>(
  variables: GetInsurerHierarchyQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetInsurerHierarchyQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetInsurerHierarchyQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetInsurerHierarchyQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['getInsurerHierarchy.infinite']
            : ['getInsurerHierarchy.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetInsurerHierarchyQuery, GetInsurerHierarchyQueryVariables>(GetInsurerHierarchyDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetInsurerHierarchyQuery.getKey = (variables?: GetInsurerHierarchyQueryVariables) =>
  variables === undefined ? ['getInsurerHierarchy.infinite'] : ['getInsurerHierarchy.infinite', variables];

useGetInsurerHierarchyQuery.fetcher = (
  variables?: GetInsurerHierarchyQueryVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<GetInsurerHierarchyQuery, GetInsurerHierarchyQueryVariables>(GetInsurerHierarchyDocument, variables, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetInsurerHierarchyQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { insurerHierarchy }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetInsurerHierarchyQuery = (
  resolver: GraphQLResponseResolver<GetInsurerHierarchyQuery, GetInsurerHierarchyQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<GetInsurerHierarchyQuery, GetInsurerHierarchyQueryVariables>('getInsurerHierarchy', resolver, options);
