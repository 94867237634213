import { AuthProvider, useAuth } from '../contexts/AuthContext';
import { BirdLogoLoaderScreen } from '../components/BirdLogoLoaderScreen';
import { careCoordinatorRoutes } from '../pages/care-coordinators/index';
import { FeatureFlagged } from './FeatureFlagged';
import { findOrgUser, useCurrentUser, User, UserOrg } from '../api-clients/falcon-api/hooks/useCurrentUser';
import { GeneralHeader } from '../components/Header/GeneralHeader';
import { generatePath, Navigate, Route, useLocation } from 'react-router-dom';
import { Layout } from '../layouts/Layout';
import { lazily } from 'react-lazily';
import { OrgLayout } from '../layouts/OrgLayout';
import { OrgTypes } from '../api-clients/falcon-api/graphql/types.generated';
import { PATHS } from './paths';
import { practiceRoutes } from '../screens/Practice';
import { retry } from './retry';
import { supplierRoutes } from '../screens/Supplier';
import { Suspense, useEffect } from 'react';
import { useOrderIdParam } from '../hooks/useOrderIdParam';
import { useOrgIdParam } from '../hooks/useOrgIdParam';
import { useUserLocalStorage } from '../hooks/useUserStorage';
import EhrUnauthorized from '../screens/EhrUnauthorized/EhrUnauthorized';
import qs from 'qs';

const { default: App } = lazily(() => retry(() => import('../App')));
const { SignByTokenWizard } = lazily(() =>
  retry(() => import('../screens/IntakeOrderWizard/RPIntake/SignByTokenWizard')),
);
const { default: NotFound } = lazily(() => retry(() => import('../screens/NotFound/NotFoundScreen')));
const { UnauthorizedScreen } = lazily(() => retry(() => import('../screens/Unauthorized/UnauthorizedScreen')));
const { default: SurveyFormSandbox } = lazily(() => retry(() => import('../components/survey/Sandbox')));
const { IntakeWizardStep } = lazily(() => retry(() => import('../components/intake-wizard/IntakeWizard')));

export const appRoutes = (
  <Route element={<AppAuth />} path="/">
    <Route element={<Home />} index />
    <Route element={<Login />} path="login" />
    <Route element={<PostLogout />} path="post-logout" />
    <Route element={<EhrUnauthorized />} path="ehr-unauthorized" />

    <Route element={<OrgLayout />}>
      {careCoordinatorRoutes}
      {practiceRoutes}
      {supplierRoutes}
    </Route>

    <Route element={<Layout />}>
      <Route element={<SignByTokenWizard />} path="review/orders/:orderId/*">
        <Route element={<IntakeWizardStep />} path=":step" />
        <Route element={<Navigate replace to="review" />} index />
      </Route>
    </Route>
    <Route element={<Layout header={<GeneralHeader homeLink={PATHS.HOME} />} />}>
      <Route element={<UnauthorizedScreen />} path="unauthorized" />
      <Route element={<NotFound />} path="404" />
      <Route
        element={<FeatureFlagged enabled={<SurveyFormSandbox />} flag="sandbox_forms" />}
        path="sandbox/forms/:subcategoryId?/:insurerId?"
      />
    </Route>
    {/* Dead routes */}
    <Route element={<RedirectToSupplierOrderCreate />} path=":orgId/orders/create/*" />
    <Route element={<RedirectToSupplierOrderEdit />} path=":orgId/orders/edit/:orderId/*" />
    <Route element={<ShutOffGW10 />} path="referrals/*" />

    {/* End dead routes */}
    <Route element={<Navigate replace to={PATHS.NOT_FOUND} />} path="*" />
  </Route>
);

export function getDefaultPageForOrg(org: UserOrg) {
  const orgId = org.id;
  switch (org.orgTypeEnum) {
    case OrgTypes.CareCoordination:
      return generatePath(PATHS.CARE_COORDINATOR, { orgId });
    case OrgTypes.Practice:
      return generatePath(PATHS.PRACTICE, { orgId });
    case OrgTypes.Supplier:
      return generatePath(PATHS.SUPPLIER, { orgId });
    default:
      throw new Error('Unexpected orgType');
  }
}

export function getDefaultPageForUser({ user, orgId }: { user: User | null | undefined; orgId?: string }) {
  const org = findOrgUser(user, { orgId })?.org ?? user?.orgUsers?.[0]?.org;
  return org ? getDefaultPageForOrg(org) : undefined;
}

function AppAuth() {
  // By first loading routing+auth and lazy loading the rest of the SPA like
  // this, the app can more quickly check for routes which require auth and
  // redirect to auth0 when necessary, because less JS needs to be immediately
  // loaded.
  return (
    <AuthProvider>
      <Suspense>
        <App />
      </Suspense>
    </AuthProvider>
  );
}

function Home() {
  const { data: user } = useCurrentUser();
  const { data: userStorageData, isLoading: isUserStorageDataLoading } = useUserLocalStorage();

  const auth = useAuth();

  if (auth.status !== 'authenticated') {
    return <Navigate replace to={PATHS.LOGIN} />;
  }

  if (!user || isUserStorageDataLoading) return <BirdLogoLoaderScreen />;

  const lastUsedOrgId = userStorageData?.last_used_org_id;

  return <Navigate replace to={getDefaultPageForUser({ user, orgId: lastUsedOrgId }) ?? PATHS.UNAUTHORIZED} />;
}

function Login() {
  // Auth check for this route is done in AuthContext.tsx
  return <Navigate replace to={PATHS.HOME} />;
}

function ShutOffGW10() {
  useEffect(() => window.location.assign('https://home.tomorrowhealth.com/patient-referrals-were-moving-on'), []);
  return <></>;
}

function PostLogout() {
  const location = useLocation();
  const { redirect } = qs.parse(location.search, { ignoreQueryPrefix: true });
  return <Navigate replace to={typeof redirect === 'string' ? redirect : PATHS.LOGIN} />;
}

function RedirectToSupplierOrderCreate() {
  const orgId = useOrderIdParam() ?? '';
  return <Navigate replace to={generatePath(PATHS.SUPPLIER_ORDERS_INTAKE_NEW, { orgId, step: null })} />;
}

function RedirectToSupplierOrderEdit() {
  const orgId = useOrgIdParam() ?? '';
  const orderId = useOrderIdParam() ?? '';
  return <Navigate replace to={generatePath(PATHS.SUPPLIER_ORDERS_INTAKE_EDIT, { orgId, orderId, step: null })} />;
}
