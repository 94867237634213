import { Colors } from '../theme';
import { RiErrorWarningLine, RiHashtag } from '@remixicon/react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

export interface OrderTrackingStatusBadgeProps {
  failedDelivery?: boolean;
}

export const OrderTrackingStatusBadge = ({ failedDelivery }: OrderTrackingStatusBadgeProps) => {
  return (
    <Tooltip
      arrow
      enterTouchDelay={0}
      placement="top"
      title={
        failedDelivery
          ? 'This order has a tracking number that cannot be confirmed.'
          : 'This order has tracking number(s) attached.'
      }
    >
      <Box color={failedDelivery ? Colors.muiError : Colors.mirage} display="inline-flex">
        <RiHashtag size="15px" />
        {failedDelivery && <RiErrorWarningLine size="15px" />}
      </Box>
    </Tooltip>
  );
};

OrderTrackingStatusBadge.displayName = 'OrderTrackingStatusBadge';

export default OrderTrackingStatusBadge;
