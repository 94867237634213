import { CSSProperties, memo } from 'react';
import BirdLogo from '../assets/bird-logo-dark.svg?react';

export const BirdLogoLoaderScreen = memo(({ content = 'Loading...' }: { content?: string }) => {
  return (
    <div style={containerStyles}>
      <BirdLogo height="100px" style={logoStyles} width="100px" />
      {content && <p style={textStyles}>{content}</p>}
    </div>
  );
});

const containerStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
};

const logoStyles: CSSProperties = {
  animation: 'pulsate 4s ease-in-out infinite alternate',
};

const textStyles: CSSProperties = {
  fontFamily: 'Inter, sans-serif',
  fontSize: '1.5rem',
};
