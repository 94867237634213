type DjangoClientError = {
  request: {
    query: string;
    variables: Record<string, unknown>;
  };
  response: {
    data: Record<string, unknown>;
    errors: Error[];
  };
};

export function parseDjangoClientError(error: unknown) {
  if (
    !!error &&
    typeof error === 'object' &&
    'response' in error &&
    (error as DjangoClientError).response.errors?.length
  ) {
    return (error as DjangoClientError).response.errors[0].message;
  }
  return null;
}
