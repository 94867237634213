import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type NpiSearchQueryVariables = Types.Exact<{
  firstName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  lastName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  npiNumber: Types.Scalars['String']['input'];
}>;

export type NpiSearchQuery = {
  __typename?: 'Query';
  npiSearch?: {
    __typename?: 'NPISearchResultsType';
    results?: Array<{
      __typename?: 'NPIRecordType';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      fax?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      npiNumber?: string | null;
      postal?: string | null;
      phone?: string | null;
      providerId?: string | null;
      sms?: string | null;
      state?: string | null;
    } | null> | null;
    error?: { __typename?: 'ErrorType'; message: string } | null;
  } | null;
};

export const NpiSearchDocument = `
    query npiSearch($firstName: String, $lastName: String, $npiNumber: String!) {
  npiSearch(firstName: $firstName, lastName: $lastName, npiNumber: $npiNumber) {
    results {
      address1
      address2
      city
      fax
      firstName
      lastName
      npiNumber
      postal
      phone
      providerId
      sms
      state
    }
    error {
      message
    }
  }
}
    `;

export const useNpiSearchQuery = <TData = NpiSearchQuery, TError = unknown>(
  variables: NpiSearchQueryVariables,
  options?: Omit<UseQueryOptions<NpiSearchQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<NpiSearchQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<NpiSearchQuery, TError, TData>({
    queryKey: ['npiSearch', variables],
    queryFn: fetcher<NpiSearchQuery, NpiSearchQueryVariables>(NpiSearchDocument, variables),
    ...options,
  });
};

useNpiSearchQuery.getKey = (variables: NpiSearchQueryVariables) => ['npiSearch', variables];

export const useInfiniteNpiSearchQuery = <TData = InfiniteData<NpiSearchQuery>, TError = unknown>(
  variables: NpiSearchQueryVariables,
  options: Omit<UseInfiniteQueryOptions<NpiSearchQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<NpiSearchQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<NpiSearchQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['npiSearch.infinite', variables],
        queryFn: (metaData) =>
          fetcher<NpiSearchQuery, NpiSearchQueryVariables>(NpiSearchDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteNpiSearchQuery.getKey = (variables: NpiSearchQueryVariables) => ['npiSearch.infinite', variables];

useNpiSearchQuery.fetcher = (variables: NpiSearchQueryVariables, options?: RequestInit['headers']) =>
  fetcher<NpiSearchQuery, NpiSearchQueryVariables>(NpiSearchDocument, variables, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockNpiSearchQuery(
 *   ({ query, variables }) => {
 *     const { firstName, lastName, npiNumber } = variables;
 *     return HttpResponse.json({
 *       data: { npiSearch }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockNpiSearchQuery = (
  resolver: GraphQLResponseResolver<NpiSearchQuery, NpiSearchQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<NpiSearchQuery, NpiSearchQueryVariables>('npiSearch', resolver, options);
