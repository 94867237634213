import { AppExtractionFeedbackFeedbackCategoryChoices } from '../../../../api-clients/falcon-api/graphql/types.generated';
import { Controller, useForm } from 'react-hook-form';
import { RiFeedbackFill } from '@remixicon/react';
import { useCreateExtractionFeedbackMutation } from '../../../../api-clients/falcon-api/graphql/mutations/createExtractionFeedbackMutation.generated';
import { useCurrentOrgUser } from '../../../../router/useCurrentOrgUser';
import { useSnackbar } from '../../../../components/Snackbar/SnackbarContext';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import OrderActionDialog from '../../../Supplier/components/SupplierOrderDrawer/components/OrderActionDialog';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export type ClinicalSummaryFeedbackFormValues = {
  feedbackText: string;
};

export type ClinicalSummaryFeedbackProps = {
  extractionResultsSetId: string;
  onLogIntakeAction?: (type: 'closed' | 'opened' | 'submitted') => void;
};

export function ClinicalSummaryFeedbackButton({
  extractionResultsSetId,
  onLogIntakeAction,
}: ClinicalSummaryFeedbackProps) {
  const { showSnackbarNotification } = useSnackbar();
  const currentOrgUser = useCurrentOrgUser();
  const createExtractionFeedbackMutation = useCreateExtractionFeedbackMutation();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    onLogIntakeAction?.('closed');

    setDialogOpen(false);
  };
  const handleOpenDialog = () => {
    onLogIntakeAction?.('opened');

    setDialogOpen(true);
  };

  const form = useForm<ClinicalSummaryFeedbackFormValues>({
    defaultValues: {
      feedbackText: '',
    },
  });

  return (
    <>
      <Tooltip enterTouchDelay={0} title="Leave some feedback">
        <Button onClick={handleOpenDialog} size="small" variant="text">
          <Typography>Something wrong?</Typography>
          <Box display="flex" marginLeft="4px">
            <RiFeedbackFill size={18} />
          </Box>
        </Button>
      </Tooltip>

      <OrderActionDialog onClose={handleCloseDialog} open={isDialogOpen}>
        <Stack spacing={2}>
          <div>
            <Typography fontWeight="600" paragraph variant="subtitle1">
              Clinical Summary Feedback
            </Typography>
            <Typography color="textSecondary">
              Provide some feedback on the clinical summary you received. This will help us improve our service.
            </Typography>
          </div>

          <Controller
            control={form.control}
            name="feedbackText"
            render={(feedbackText) => (
              <TextField
                disabled={createExtractionFeedbackMutation.isPending}
                error={!!feedbackText.fieldState.error}
                helperText={feedbackText.fieldState.error ? 'Feedback is required' : undefined}
                id="reasonText"
                label="Feedback..."
                multiline
                onChange={feedbackText.field.onChange}
                rows={3}
                size="small"
                type="text"
                value={feedbackText.field.value}
              />
            )}
            rules={{ required: true }}
          />

          <form
            onSubmit={form.handleSubmit(async (values) => {
              onLogIntakeAction?.('submitted');

              const response = await createExtractionFeedbackMutation.mutateAsync({
                input: {
                  extractionResultsSetId,
                  feedbackCategory: AppExtractionFeedbackFeedbackCategoryChoices.ClinicalSummary,
                  orgUserId: currentOrgUser.id,
                  text: values.feedbackText,
                },
              });

              if (!response.createExtractionFeedbackMutation?.error) {
                showSnackbarNotification({ title: 'Feedback submitted', type: 'success' });

                setDialogOpen(false);
              }
            })}
          >
            <Stack direction="row" flexWrap="wrap" justifyContent="flex-end" spacing={1}>
              <Button aria-label="cancel" onClick={handleCloseDialog} variant="text">
                Cancel
              </Button>

              <Button aria-label="confirm" type="submit">
                Submit
              </Button>
            </Stack>
          </form>
        </Stack>
      </OrderActionDialog>
    </>
  );
}
