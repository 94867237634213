import * as Types from '../types.generated.js';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type OrgUserAcceptInviteMutationVariables = Types.Exact<{
  orgId: Types.Scalars['ID']['input'];
}>;

export type OrgUserAcceptInviteMutation = {
  __typename?: 'Mutation';
  orgUserAcceptInvite?: {
    __typename?: 'OrgUserAcceptInvite';
    ok?: boolean | null;
    error?: { __typename?: 'ErrorType'; id?: string | null; message: string } | null;
  } | null;
};

export const OrgUserAcceptInviteDocument = `
    mutation orgUserAcceptInvite($orgId: ID!) {
  orgUserAcceptInvite(orgId: $orgId) {
    ok
    error {
      id
      message
    }
  }
}
    `;

export const useOrgUserAcceptInviteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<OrgUserAcceptInviteMutation, TError, OrgUserAcceptInviteMutationVariables, TContext>,
) => {
  return useMutation<OrgUserAcceptInviteMutation, TError, OrgUserAcceptInviteMutationVariables, TContext>({
    mutationKey: ['orgUserAcceptInvite'],
    mutationFn: (variables?: OrgUserAcceptInviteMutationVariables) =>
      fetcher<OrgUserAcceptInviteMutation, OrgUserAcceptInviteMutationVariables>(
        OrgUserAcceptInviteDocument,
        variables,
      )(),
    ...options,
  });
};

useOrgUserAcceptInviteMutation.getKey = () => ['orgUserAcceptInvite'];

useOrgUserAcceptInviteMutation.fetcher = (
  variables: OrgUserAcceptInviteMutationVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<OrgUserAcceptInviteMutation, OrgUserAcceptInviteMutationVariables>(
    OrgUserAcceptInviteDocument,
    variables,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockOrgUserAcceptInviteMutation(
 *   ({ query, variables }) => {
 *     const { orgId } = variables;
 *     return HttpResponse.json({
 *       data: { orgUserAcceptInvite }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockOrgUserAcceptInviteMutation = (
  resolver: GraphQLResponseResolver<OrgUserAcceptInviteMutation, OrgUserAcceptInviteMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<OrgUserAcceptInviteMutation, OrgUserAcceptInviteMutationVariables>(
    'orgUserAcceptInvite',
    resolver,
    options,
  );
