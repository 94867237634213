import { generatePath } from 'react-router-dom';
import { GetSupplierOrdersQueryVariables } from '../../../../api-clients/falcon-api/graphql/queries/getSupplierOrders.generated';
import { OrdersTable, SkeletonRows, SortProps, TableHeadCell } from '../../../../components/OrdersTable';
import { PATHS } from '../../../../router/paths';
import { ScreenSearch } from '../../../../components/ScreenSearch/ScreenSearch';
import {
  SupplierFaxOrgOrderSort,
  SupplierFaxOrgOrderSortColumn,
} from '../../../../api-clients/falcon-api/hooks/useFaxExtractionResultSearch';
import { SupplierOrderListOrderStatus } from '../../../Supplier/components/SupplierOrderListOrderStatus';
import { SupplyStatus } from '../../../../api-clients/falcon-api/graphql/types.generated';
import { TablePaginationProvider } from '../../../../components/Table/TablePaginationProvider';
import { useCurrentOrgUser } from '../../../../router/useCurrentOrgUser';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import React, { useCallback, useReducer } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useDidUpdate from '../../../../hooks/useDidUpdate';
import useSupplierOrders, { DmeOrderForTable } from '../../../Supplier/hooks/useSupplierOrders';

interface Inputs {
  searchText: string;
  orderBy: SupplierFaxOrgOrderSort;
  page: number;
  statuses: SupplyStatus[];
}

const inputsDefaults: Inputs = {
  orderBy: '-created_at',
  page: 0,
  searchText: '',
  statuses: [
    SupplyStatus.Pending,
    SupplyStatus.Accepted,
    SupplyStatus.PartiallyCompleted,
    SupplyStatus.Shipped,
    SupplyStatus.Completed,
  ],
};

type InputsAction =
  | { type: 'PAGE'; page: number }
  | { type: 'RESET' }
  | { type: 'SORT'; sort: SupplierFaxOrgOrderSort }
  | { type: 'TEXT'; text: string };

function inputsReducer(state: Inputs, action: InputsAction) {
  switch (action.type) {
    case 'PAGE':
      return { ...state, page: action.page };
    case 'RESET':
      return inputsDefaults;
    case 'SORT':
      return state.orderBy === action.sort ? state : { ...state, page: 0, orderBy: action.sort };
    case 'TEXT':
      return state.searchText === action.text ? state : { ...state, page: 0, searchText: action.text };
    default:
      return state;
  }
}

const PAGE_SIZE = 6;

function useSearchVariables(
  orgId: string,
  supplierId: string,
  patientId: string | undefined,
  inputs: Inputs,
): GetSupplierOrdersQueryVariables {
  return {
    orgId,
    supplierId,
    patientId,
    searchText: inputs.searchText || undefined,
    statuses: inputs.statuses,
    orderBy: inputs.orderBy,
    first: PAGE_SIZE,
    offset: inputs.page * PAGE_SIZE,
  };
}

export const SupplierOrderSearch = ({
  selectedOrderId,
  selectedPatientId,
  onSelect,
}: {
  selectedOrderId: string | undefined;
  selectedPatientId: string | undefined;
  onSelect: (orderId: string | undefined) => void;
}) => {
  const currentOrgUser = useCurrentOrgUser();
  const orgId = currentOrgUser.org.id;
  const supplierId = currentOrgUser.org.supplier?.id ?? '';
  const [ordersInputs, dispatch] = useReducer(inputsReducer, inputsDefaults);

  const variables = useSearchVariables(orgId, supplierId, selectedPatientId, ordersInputs);
  const orders = useSupplierOrders(variables);

  useDidUpdate(() => dispatch({ type: 'RESET' }), [orgId]);

  return (
    <Box paddingBottom={1}>
      <SearchBar dispatch={dispatch} />

      <TableContainer>
        <SupplierOrdersTable
          dispatch={dispatch}
          onSelect={onSelect}
          orders={orders.data?.orders}
          selectedOrderId={selectedOrderId}
          sort={ordersInputs.orderBy}
        />
      </TableContainer>

      <TablePaginationProvider
        count={Math.ceil((orders.data?.totalCount ?? 0) / PAGE_SIZE)}
        onChangePage={(_event, page) => dispatch({ type: 'PAGE', page })}
        page={ordersInputs.page}
      />
    </Box>
  );
};

const SearchBar = React.memo(function SearchBar({ dispatch }: { dispatch: React.Dispatch<InputsAction> }) {
  return (
    <Box
      sx={{
        border: '1px solid #E0E0E0',
        borderBottom: 0,
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
      }}
    >
      <ScreenSearch
        onSearch={(text) => dispatch({ type: 'TEXT', text })}
        searchPlaceholderText="Search..."
        variant="toolbar"
      />
    </Box>
  );
});

const SupplierOrdersTable = React.memo(function FaxExtractionResultsTable({
  dispatch,
  orders,
  sort,
  selectedOrderId,
  onSelect,
}: {
  dispatch: React.Dispatch<InputsAction>;
  orders: DmeOrderForTable[] | undefined;
  sort: SupplierFaxOrgOrderSort;
  selectedOrderId: string | undefined;
  onSelect: (orderId: string | undefined) => void;
}) {
  const handleSort = useCallback((sort: SupplierFaxOrgOrderSort) => dispatch({ type: 'SORT', sort }), [dispatch]);
  const handleOnClick = (orderId: string) => () => onSelect(orderId);

  return (
    <OrdersTable>
      <TableHead>
        <HeadRow onSort={handleSort} sort={sort} />
      </TableHead>

      <TableBody>
        {orders ? (
          orders.map((order) => (
            <SupplierOrderRow
              key={order.id}
              onClick={handleOnClick(order.id)}
              order={order}
              selected={selectedOrderId === order.id}
            />
          ))
        ) : (
          <SkeletonRows cellCount={4} rowCount={5} />
        )}
      </TableBody>
    </OrdersTable>
  );
});

const HeadRow = React.memo(function HeadRow({ onSort, sort }: SortProps<SupplierFaxOrgOrderSortColumn>) {
  const cell = (label: React.ReactNode, ascSort?: SupplierFaxOrgOrderSortColumn) => (
    <TableHeadCell column={ascSort} onSort={onSort} sort={sort}>
      <Box whiteSpace="nowrap">{label}</Box>
    </TableHeadCell>
  );

  return (
    <TableRow>
      {cell('')}
      {cell('Friendly Id')}
      {cell('Created date', 'created_at')}
      {cell('Status')}
      {cell('Subcategory')}
    </TableRow>
  );
});

const empty = '–';

const SupplierOrderRow = React.memo(function OrderRow({
  order,
  selected,
  onClick,
}: {
  order: DmeOrderForTable;
  selected: boolean;
  onClick: () => void;
}) {
  const currentOrgUser = useCurrentOrgUser();
  const orgId = currentOrgUser.org.id;
  const orderLink = `
  ${generatePath(PATHS.SUPPLIER_ORDERS, {
    orgId,
  })}?${new URLSearchParams({ tab: '-1', orderId: order.id }).toString()}`;

  return (
    <Box
      component={TableRow}
      hover
      onClick={onClick}
      selected={selected}
      sx={{
        mixBlendMode: 'multiply',
      }}
    >
      <TableCell sx={{ padding: 0, paddingLeft: '10px', borderRight: 0 }}>
        <Radio checked={selected} onClick={(e) => e.preventDefault()} />
      </TableCell>
      <TableCell>
        <Link component="a" href={orderLink} onClick={(evt) => evt.stopPropagation()} target="_blank">
          {order.friendlyId ?? empty}
        </Link>
      </TableCell>
      <TableCell>{order.createdAt || empty}</TableCell>
      <TableCell>{order ? <SupplierOrderListOrderStatus order={order} /> : empty}</TableCell>
      <TableCell>{order.intakeSubcategory?.name ?? empty}</TableCell>
    </Box>
  );
});
