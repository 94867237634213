import { DetailCard, DetailItem } from './DetailCard';
import { displayDate } from '@tomorrow/ui/utils';
import { formatAddressOneLine } from '../utils/formatters/address';
import { formatInsurance } from '../utils/formatters/insurance';
import { getFormattedPhoneForDisplay, getPhoneNumbersDisplay } from '../utils/phoneUtils';
import { IntakePatient } from './PatientModal/PatientForm.helpers';
import { parseDate } from '@tomorrow/ui/utils';
import { RiInformationLine } from '@remixicon/react';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export const PatientDetailCard = ({
  patient,
  compact = true,
  showPatientName = true,
  endComponentSlot,
  sx,
}: {
  patient: IntakePatient | null;
  compact?: boolean;
  showPatientName?: boolean;
  endComponentSlot?: React.ReactNode;
  sx?: SxProps;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: '15px',
        borderRadius: '2px',
        ...sx,
      }}
    >
      {showPatientName && (
        <Typography fontWeight="600" variant="subtitle2">
          {patient?.firstName} {patient?.lastName}
        </Typography>
      )}
      <DetailCard
        sx={{
          gap: compact ? 0 : 0.5,
          padding: 0,
          bgcolor: 'inherit',
        }}
      >
        <DetailItem label="Primary insurance">{formatInsurance(patient?.primaryInsurance)}</DetailItem>
        <DetailItem label="Secondary insurance">{formatInsurance(patient?.secondaryInsurance)}</DetailItem>
        <DetailItem label="Waiver">{patient?.patientWaiver?.waiver?.name ?? '-'}</DetailItem>
        <DetailItem label="Shipping address">
          {patient?.shippingAddress ? formatAddressOneLine(patient.shippingAddress) : '-'}
        </DetailItem>
        <DetailItem label="Date of birth">{displayDate(parseDate(patient?.dateOfBirth)) ?? '-'}</DetailItem>
        <DetailItem label="Gender">{patient?.sex ?? '-'}</DetailItem>
        <DetailItem label="Phone">{patient ? getPhoneNumbersDisplay(patient) : '-'}</DetailItem>
        <DetailItem label="Preferred language">{patient?.languagePreference?.name ?? '-'}</DetailItem>

        {patient?.guardian?.firstName && (
          <DetailItem
            label={
              <span>
                LAR
                <Tooltip arrow enterTouchDelay={0} placement="top" title="Legally Authorized Representative">
                  <Box component="span" style={{ cursor: 'pointer' }}>
                    <RiInformationLine size="12px" />
                  </Box>
                </Tooltip>
              </span>
            }
          >
            {patient?.guardian.firstName} {patient?.guardian.lastName} -{' '}
            {patient?.guardian.phoneNumber ? getFormattedPhoneForDisplay(patient.guardian.phoneNumber) : '-'}
          </DetailItem>
        )}
        {endComponentSlot}
      </DetailCard>
    </Box>
  );
};
