import { ColorsV2 } from '../theme';
import { Document, DocumentProps, Page, PageProps } from 'react-pdf';
import { InView } from 'react-intersection-observer';
import { RiArrowLeftLine, RiArrowRightLine, RiDownloadLine } from '@remixicon/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import React, { createRef, forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import saveAs from 'file-saver';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const INITIAL_SCALE = 1.0;
const MAX_SCALE = 1.5;
const MIN_SCALE = 0.1;

/*
In order to use this component properly, you must create a wrapper component 
to bundle it with.

Reference: https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#configure-pdfjs-worker


// Example snippet using Vite bundler
import { pdfjs } from 'react-pdf';

// eslint-disable-next-line import/no-extraneous-dependencies
import pdfWorkerUrl from 'pdfjs-dist/build/pdf.worker.min.js?url';

import { PDFViewer as _PDFViewer } from '@tomorrow/ui';

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorkerUrl;

export const PDFViewer = _PDFViewer;
*/

// eslint-disable-next-line
function fixedForwardRef<T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode,
): (props: P & React.RefAttributes<T>) => React.ReactNode {
  return forwardRef(render) as any;
}

export type PDFViewerRef<T extends string = string> = {
  highlightRefs: Record<T, React.RefObject<HighlightOverlayRef>>;
  scrollToPage: (page: number, behavior?: ScrollBehavior) => void;
  highlightField: (highlightId: T, highlightEnabled: boolean) => React.RefObject<HighlightOverlayRef> | undefined;
};

interface HighlightData<T> {
  id: T;
  label: string;
  boundingBox: {
    left: number;
    top: number;
    width: number;
    height: number;
  };
  pageNumber: number;
}

export interface PDFViewerProps<T extends string> extends DocumentProps {
  fileName: string;
  showToolbar?: boolean;
  initialScale?: number;
  minScale?: number;
  maxScale?: number;
  highlightData?: HighlightData<T>[];
  highlightColor?: string;
  renderPage?: (content: React.ReactNode, pageIndex: number) => React.ReactNode;
  onPageRenderSuccess?: (page: PageProps) => void;
  onToolbarClick?: (type: 'download' | 'prev' | 'next' | 'scale') => void;
}

export const InternalPDFViewer = <T extends string>(
  {
    file,
    fileName,
    showToolbar = true,
    initialScale = INITIAL_SCALE,
    minScale = MIN_SCALE,
    maxScale = MAX_SCALE,
    highlightData,
    highlightColor = ColorsV2.green,
    onLoadSuccess,
    onPageRenderSuccess,
    renderPage,
    onToolbarClick,
    ...rest
  }: PDFViewerProps<T>,
  ref: React.ForwardedRef<PDFViewerRef<T>>,
) => {
  const [scale, setScale] = useState(initialScale);
  const [pages, setPages] = useState<number[]>([]);
  const [currentInViewPageIndex, setCurrentInViewPageIndex] = useState<number>(0);
  const [paginatedHighlights, setPaginatedHighlights] = useState<Record<number, HighlightData<T>[]>>({});

  const pageRefs = useMemo(() => pages.map(() => createRef<HTMLDivElement>()), [pages]);

  // Create refs for each highlight item mapped by item id
  const highlightRefs = useMemo(
    () =>
      highlightData?.reduce<Record<string, React.RefObject<HighlightOverlayRef>>>((acc, value) => {
        acc[value.id] = createRef();
        return acc;
      }, {}) ?? {},
    [highlightData],
  );

  const handlePageJumpClick = useCallback(
    (pageNum: number, behavior: ScrollBehavior = 'smooth') =>
      pageRefs[pageNum]?.current?.scrollIntoView({
        behavior,
        block: 'start',
      }),
    [pageRefs],
  );

  const handleOnPageRenderSuccess: PageProps['onRenderSuccess'] = (page) => {
    if (!highlightData) {
      return;
    }

    onPageRenderSuccess?.(page);

    setPaginatedHighlights((current) => ({
      ...current,
      [page.pageNumber]: highlightData
        .filter((a) => a.pageNumber === page.pageNumber)
        .map((a) => {
          const x1 = Math.floor(a.boundingBox.left * page.width);
          const y1 = Math.floor(a.boundingBox.top * page.height);
          const x2 = Math.floor((a.boundingBox.left + a.boundingBox.width) * page.width);
          const y2 = Math.floor((a.boundingBox.top + a.boundingBox.height) * page.height);

          return {
            id: a.id,
            label: a.label,
            boundingBox: new DOMRect(x1, y1, x2 - x1, y2 - y1),
            pageNumber: a.pageNumber,
          };
        }),
    }));
  };

  useImperativeHandle(
    ref,
    () => ({
      highlightRefs,
      scrollToPage: (page: number, behavior: ScrollBehavior = 'instant') => handlePageJumpClick(page, behavior),
      highlightField: (highlightId, highlightEnabled) => {
        const ref = highlightRefs[highlightId];

        if (ref?.current) {
          if (highlightEnabled) {
            ref.current.element?.scrollIntoView({
              behavior: 'instant',
              block: 'center',
            });

            ref.current.setVisible(true);
          } else {
            ref.current.setVisible(false);
          }
        }

        return ref;
      },
    }),
    [handlePageJumpClick, highlightRefs],
  );

  return (
    <Box
      sx={{ scrollbarWidth: 'thin', width: '100%', overflow: 'auto', gap: 1, display: 'flex', flexDirection: 'column' }}
    >
      {showToolbar && pages.length > 0 && (
        <Fade in>
          <Box
            display="flex"
            justifyContent="space-between"
            left="10px"
            margin="10px"
            marginBottom="0"
            position="sticky"
            top="10px"
            zIndex={100}
          >
            <Stack direction="row" spacing={1}>
              <ScaleButton
                maxScale={maxScale}
                minScale={minScale}
                onScaleChange={(scale) => {
                  setScale(scale);
                  onToolbarClick?.('scale');
                }}
                scale={scale}
              />
              {scale !== initialScale && (
                <ScaleResetButton
                  onClick={() => {
                    setScale(initialScale);
                    onToolbarClick?.('scale');
                  }}
                />
              )}
            </Stack>
            <Stack direction="row" spacing={1}>
              <PageButton
                direction="left"
                onClick={() => {
                  onToolbarClick?.('prev');

                  handlePageJumpClick(currentInViewPageIndex > 0 ? currentInViewPageIndex - 1 : currentInViewPageIndex);
                }}
              />
              <PageButton
                direction="right"
                onClick={() => {
                  onToolbarClick?.('next');

                  handlePageJumpClick(
                    currentInViewPageIndex < pages.length - 1 ? currentInViewPageIndex + 1 : currentInViewPageIndex,
                  );
                }}
              />
              <DownloadButton file={file} fileName={fileName} onClick={() => onToolbarClick?.('download')} />
            </Stack>
          </Box>
        </Fade>
      )}
      <Box
        sx={{
          display: 'flex',
          flexGrow: '1',
          margin: '0 auto',
          '& > div': {
            display: 'flex',
          },
        }}
      >
        <Document
          file={file}
          loading={<Typography mt={4}>Loading PDF...</Typography>}
          noData={<Typography mt={4}>No PDF file provided.</Typography>}
          renderMode="canvas"
          {...rest}
          onLoadSuccess={(data) => {
            const pages = Array.from({ length: data.numPages }, (_, i) => i);

            setPages(pages);
            onLoadSuccess?.(data);
          }}
        >
          <Fade in>
            <Stack padding={1} spacing={1}>
              {pages.map((value, index) => (
                <PageContainer
                  key={`page_${value}`}
                  ref={pageRefs[index]}
                  index={index}
                  onInViewChange={(inView) => inView && setCurrentInViewPageIndex(index)}
                  onRenderSuccess={handleOnPageRenderSuccess}
                  renderPage={renderPage}
                  scale={scale}
                >
                  {paginatedHighlights[index + 1]?.map((selectedHighlightItem) => (
                    <HighlightOverlay
                      key={selectedHighlightItem.id}
                      ref={highlightRefs[selectedHighlightItem.id]}
                      color={highlightColor}
                      height={selectedHighlightItem.boundingBox.height}
                      label={selectedHighlightItem.label}
                      width={selectedHighlightItem.boundingBox.width}
                      x={selectedHighlightItem.boundingBox.left}
                      y={selectedHighlightItem.boundingBox.top}
                    />
                  ))}
                </PageContainer>
              ))}
            </Stack>
          </Fade>
        </Document>
      </Box>
    </Box>
  );
};

interface PageContainerProps extends Pick<PageProps, 'scale' | 'onRenderSuccess'>, React.PropsWithChildren {
  index: number;
  onInViewChange: (inView: boolean) => void;
  renderPage?: (content: React.ReactNode, pageIndex: number) => React.ReactNode;
}
const RenderPageDefault = (content: React.ReactNode) => content;

const PageContainer = React.forwardRef<HTMLDivElement, PageContainerProps>(
  ({ index, scale, onInViewChange, onRenderSuccess, renderPage = RenderPageDefault, children }, ref) => {
    const [loaded, setLoaded] = useState(false);

    return (
      <InView onChange={(inView) => loaded && onInViewChange(inView)}>
        <div ref={ref}>
          {renderPage(
            <Box borderRadius="6px" boxShadow="0px 24.464px 97.858px 0px rgba(0, 0, 0, 0.07);" overflow="hidden">
              <Page
                loading={null}
                onLoadSuccess={() => setLoaded(true)}
                onRenderSuccess={onRenderSuccess}
                pageIndex={index}
                scale={scale}
              >
                {children}
              </Page>
            </Box>,
            index,
          )}
        </div>
      </InView>
    );
  },
);

export const PDFViewer = fixedForwardRef(InternalPDFViewer);

type HighlightOverlayRef = {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  element: HTMLDivElement | null;
};

type HighlightOverlayProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  label: string;
  color?: string;
};

const HIGHLIGHT_OFFSET_X = 7;
const HIGHLIGHT_OFFSET_Y = 5;

const HighlightOverlay = forwardRef<HighlightOverlayRef, HighlightOverlayProps>(
  ({ x, y, width, height, label, color = ColorsV2.green }, ref) => {
    const innerRef = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        setVisible,
        element: innerRef.current,
      }),
      [],
    );

    return (
      <Tooltip arrow title={label}>
        <Box
          ref={innerRef}
          sx={{
            visibility: visible ? 'visible' : 'hidden',
            zIndex: 10,
            position: 'absolute',
            left: x - HIGHLIGHT_OFFSET_X,
            top: y - HIGHLIGHT_OFFSET_Y,
            width: `${width + HIGHLIGHT_OFFSET_X * 2}px`,
            height: `${height + HIGHLIGHT_OFFSET_Y * 2}px`,
            border: `2px solid ${color}`,
            borderRadius: '5px',
          }}
        />
      </Tooltip>
    );
  },
);

type ScaleButtonProps = {
  minScale: number;
  maxScale: number;
  scale: number;
  onScaleChange: (scale: number) => void;
};

const ScaleButton = ({ minScale, maxScale, scale, onScaleChange }: ScaleButtonProps) => {
  const handleOnClick = (newScale: number) => () => {
    if (newScale < minScale || newScale > maxScale) {
      return;
    }

    onScaleChange(newScale);
  };

  const display = Math.round(scale * 1000) / 10;

  return (
    <Stack
      alignItems="center"
      bgcolor="white"
      borderRadius="6px"
      boxShadow="0px 2px 2px 0px rgba(0, 0, 0, 0.09)"
      direction="row"
      spacing="3px"
    >
      <Button
        disabled={scale <= minScale}
        onClick={handleOnClick(Math.round((scale - 0.1) * 10) / 10)}
        size="small"
        sx={{ borderRadius: 0, borderTopLeftRadius: 'inherit', borderBottomLeftRadius: 'inherit' }}
        variant="text"
      >
        -
      </Button>
      <span>{display}%</span>
      <Button
        disabled={scale >= maxScale}
        onClick={handleOnClick(Math.round((scale + 0.1) * 10) / 10)}
        size="small"
        sx={{ borderRadius: 0, borderTopRightRadius: 'inherit', borderBottomRightRadius: 'inherit' }}
        variant="text"
      >
        +
      </Button>
    </Stack>
  );
};

const ScaleResetButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Box
      sx={{
        bgcolor: 'white',
        borderRadius: '6px',
        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.09)',
      }}
    >
      <Button
        onClick={onClick}
        size="small"
        sx={{
          borderRadius: 'inherit',
          fontWeight: '400',
        }}
        variant="text"
      >
        Reset
      </Button>
    </Box>
  );
};

const PageButton = ({ onClick, direction }: { onClick: () => void; direction: 'left' | 'right' }) => {
  return (
    <Tooltip enterTouchDelay={0} title={`Jump to ${direction === 'left' ? 'previous' : 'next'} page`}>
      <Box
        sx={{
          bgcolor: 'white',
          borderRadius: '6px',
          boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.09)',
        }}
      >
        <IconButton
          onClick={onClick}
          sx={{
            borderRadius: 'inherit',
          }}
        >
          {direction === 'left' ? <RiArrowLeftLine size="14px" /> : <RiArrowRightLine size="14px" />}
        </IconButton>
      </Box>
    </Tooltip>
  );
};

type DownloadButtonProps = {
  file: DocumentProps['file'] | undefined;
  fileName: string;
  onClick?: () => void;
};

const DownloadButton = ({ file, fileName = 'PDF', onClick }: DownloadButtonProps) => {
  function handleDownloadClick() {
    if (!file) return;

    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    saveAs(file.toString(), fileName);

    onClick?.();
  }

  return (
    <Tooltip enterTouchDelay={0} title="Download PDF">
      <Box
        sx={{
          bgcolor: 'white',
          borderRadius: '6px',
          boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.09)',
        }}
      >
        <IconButton
          disabled={!file}
          onClick={handleDownloadClick}
          sx={{
            borderRadius: 'inherit',
          }}
        >
          <RiDownloadLine size="14px" />
        </IconButton>
      </Box>
    </Tooltip>
  );
};
