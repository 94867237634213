import { ColorsV2 } from '@tomorrow/ui/theme';
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Input } from '../../../../components/FormInputs';
import { InputSection, InputSubSection } from '../components/InputSection';
import { toDefaultFormValues, usePdfPreviewUrl } from './PreviewFaxBackStep.utils';
import { useForm } from 'react-hook-form';
import { useSendFaxbackCoversheetMutation } from '../../../../api-clients/falcon-api/graphql/mutations/sendFaxbackCoversheet.generated';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import OrderActionDialog from '../../../Supplier/components/SupplierOrderDrawer/components/OrderActionDialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { DocumentFieldId } from './DocumentStep.utils';
import type { FaxbackCoversheetInput } from '../../../../api-clients/falcon-api/graphql/types.generated';
import type { OrderWizardStepComponentProps, StepRef } from '../../IntakeFaxOrderWizard';
import type { PreviewFaxBackStepFormValues } from './PreviewFaxBackStep.utils';
import type { SupplierIntakeFaxOrderSeed } from '../SupplierFaxOrderWizard.utils';
import type { UseFormReturn } from 'react-hook-form';

export type PreviewFaxBackStepForm = UseFormReturn<PreviewFaxBackStepFormValues>;

export const PreviewFaxBackStep = memo(
  forwardRef<StepRef, OrderWizardStepComponentProps<SupplierIntakeFaxOrderSeed, DocumentFieldId>>(
    function PreviewFaxBackStep({ orderSeed, onOrderProduce, onNextStep, onOrderSubmit, logIntakeAction }, ref) {
      const [isDialogOpen, setDialogOpen] = useState(false);
      const sendFaxbackCoversheetMutation = useSendFaxbackCoversheetMutation();

      const form = useForm<PreviewFaxBackStepFormValues>({
        defaultValues: toDefaultFormValues(),
      });

      const saveOrder = useCallback(
        async function saveOrder(
          values: PreviewFaxBackStepFormValues,
          onSuccess: (orderSeed: SupplierIntakeFaxOrderSeed) => void,
        ) {
          if (!orderSeed.id) throw new Error('Missing order seed id');

          await sendFaxbackCoversheetMutation.mutateAsync({
            input: {
              additionalComments: values.additionalComments,
              unmetRequirementsOverride: values.unmetRequirementsOverride,
              orderSeedId: orderSeed.id,
            },
          });

          onSuccess(orderSeed);
        },
        [orderSeed, sendFaxbackCoversheetMutation],
      );

      const submitForm = useCallback(async () => {
        setDialogOpen(false);

        // Clear any existing errors before submitting
        form.clearErrors();

        await form.handleSubmit(async (values) => {
          onOrderSubmit();
          await saveOrder(values, onNextStep);
        })();
      }, [form, onNextStep, onOrderSubmit, saveOrder]);

      const handleFormSubmit = useCallback(() => {
        setDialogOpen(true);
      }, []);

      useImperativeHandle(
        ref,
        () => ({
          triggerFormSubmit: handleFormSubmit,
        }),
        [handleFormSubmit],
      );

      const [pdfPreviewUrlInput, setPdfPreviewUrlInput] = useState<FaxbackCoversheetInput>({
        orderSeedId: orderSeed.id ?? '',
      });

      const pdfPreviewUrl = usePdfPreviewUrl(pdfPreviewUrlInput);

      useEffect(() => {
        onOrderProduce((draft) => {
          draft.showFaxBackPreviewPdf = true;
          draft.faxBackPreviewPdfUrl = pdfPreviewUrl.data;
          draft.pdfLoading = pdfPreviewUrl.isLoading;

          return draft;
        });

        return () => {
          onOrderProduce((draft) => {
            draft.showFaxBackPreviewPdf = false;

            return draft;
          });
        };
      }, [onOrderProduce, pdfPreviewUrl.data, pdfPreviewUrl.isLoading]);

      const handleCloseDialog = () => {
        setDialogOpen(false);

        logIntakeAction('fax_order_intake_faxback_confirmation_closed');
      };

      return (
        <Box bgcolor={ColorsV2.white} flexGrow="1">
          <form onSubmit={handleFormSubmit}>
            <Stack flexGrow="1" padding="20px 40px" spacing={2}>
              <InputSection label="Send Fax">
                <Stack spacing={2}>
                  <InputSubSection label="Unmet Requirements Override">
                    <Input control={form.control} name="unmetRequirementsOverride" />
                  </InputSubSection>

                  <InputSubSection label="Additional Comments">
                    <Input control={form.control} name="additionalComments" />
                  </InputSubSection>
                </Stack>
              </InputSection>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  disabled={pdfPreviewUrl.isFetching}
                  onClick={() => {
                    if (!orderSeed.id) return;

                    const { unmetRequirementsOverride, additionalComments } = form.getValues();

                    setPdfPreviewUrlInput({
                      orderSeedId: orderSeed.id,
                      unmetRequirementsOverride,
                      additionalComments,
                    });

                    pdfPreviewUrl.refetch();

                    logIntakeAction('fax_order_intake_faxback_preview_click', {
                      comments: additionalComments,
                      override: unmetRequirementsOverride,
                    });
                  }}
                >
                  Preview Fax Document
                </Button>
              </Box>
            </Stack>
          </form>

          <OrderActionDialog onClose={handleCloseDialog} open={isDialogOpen}>
            <Stack spacing={2}>
              <div>
                <Typography fontWeight="600" paragraph variant="subtitle1">
                  Send Fax Document
                </Typography>
                <Typography color="textSecondary">
                  Just a heads up – you can preview the document before sending.
                </Typography>
              </div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
              >
                <Stack direction="row" flexWrap="wrap" justifyContent="flex-end" spacing={1}>
                  <Button aria-label="cancel" onClick={handleCloseDialog} variant="text">
                    Cancel
                  </Button>

                  <Button aria-label="confirm" type="submit">
                    Confirm
                  </Button>
                </Stack>
              </form>
            </Stack>
          </OrderActionDialog>
        </Box>
      );
    },
  ),
);
