import {
  GetIntakeSubcategoriesQuery,
  useGetIntakeSubcategoriesQuery,
} from '../graphql/queries/getIntakeSubcategories.generated';
import { keepPreviousData } from '@tanstack/react-query';
import { milliseconds } from 'date-fns';

export function useIntakeSubcategories() {
  return useGetIntakeSubcategoriesQuery(undefined, {
    staleTime: milliseconds({ hours: 1 }),
    placeholderData: keepPreviousData,
    meta: {
      errorMessage: 'Failed to fetch product subcategories',
    },
    select: transformGetIntakeSubcategoriesQuery,
  });
}

function transformGetIntakeSubcategoriesQuery({ intakeSubcategories }: GetIntakeSubcategoriesQuery) {
  return intakeSubcategories?.flatMap((subcategory) => subcategory ?? []) ?? [];
}
