import { ColorsV2 } from '../theme';
import { RiArrowDownLine } from '@remixicon/react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

export const SkipToMainContent = ({ href = '#main' }) => (
  <Link
    href={href}
    sx={{
      '&:focus, &:active': {
        transform: 'translateZ(0)',
        opacity: 1,
        outline: 0,
      },
      position: 'absolute',
      zIndex: 'tooltip',
      fontWeight: '600',
      color: 'white !important',
      top: '1px',
      left: '1px',
      paddingX: 1,
      paddingY: 0.3,
      transform: 'translate3d(0,-100%,0)',
      opacity: 0,
      borderRadius: '7px',
      backgroundColor: ColorsV2.blue,
      transition: 'transform .15s ease;',
    }}
    variant="subtitle2"
  >
    <Box alignItems="center" component="span" display="flex">
      Skip to main content{' '}
      <Box component="span" display="inline-flex" marginLeft={0.5}>
        <RiArrowDownLine size={14} />
      </Box>
    </Box>
  </Link>
);
