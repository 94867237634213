import * as Types from '../types.generated.js';

import { ProductFragmentDoc } from '../queries/getProducts.generated.js';
import { IntakePatientFragmentDoc } from './IntakePatientFragment.generated.js';
import {
  PatientFragmentDoc,
  PatientInsuranceFragmentDoc,
  AddressFragmentDoc,
  PatientSlimFragmentDoc,
  PatientSlimSansAddressFragmentDoc,
} from './PatientFragment.generated.js';
import { ProviderFragmentDoc } from './ProviderFragment.generated.js';
export type IntakeOrderFragment = {
  __typename?: 'OrderType';
  careManagerName?: string | null;
  dischargeAt?: string | null;
  emergencyContactRelationship?: string | null;
  friendlyId?: string | null;
  id: string;
  intakeType: Types.AppOrderIntakeTypeChoices;
  note?: string | null;
  shippingDetails?: string | null;
  serviceType?: Types.AppOrderServiceTypeChoices | null;
  urgent: boolean;
  appointment?: { __typename?: 'AppointmentType'; appointmentDate: string } | null;
  currentAnswer?: {
    __typename?: 'OrderIntakeAnswerType';
    productFormJson?: string | null;
    productAnswerJson?: string | null;
    clinicalFormJson?: string | null;
    clinicalAnswerJson?: string | null;
    intakeValidationRuleResults: Array<{
      __typename?: 'IntakeValidationRuleResultType';
      id: string;
      result: Types.AppIntakeValidationRuleResultResultChoices;
      intakeValidationRule: {
        __typename?: 'IntakeValidationRuleType';
        lowContextSuccessText?: string | null;
        lowContextWarningText?: string | null;
        lowContextErrorText?: string | null;
      };
      intakeQuestion: { __typename?: 'IntakeQuestionType'; questionJson: string };
    }>;
  } | null;
  currentSigners?: Array<{
    __typename?: 'OrderSignerType';
    id: string;
    emailAddress?: string | null;
    faxNumber?: string | null;
    fullName?: string | null;
    isRequestor: boolean;
    isSigner: boolean;
    isPrescriber: boolean;
    nameSigned?: string | null;
    phoneNumber?: string | null;
    smsNumber?: string | null;
    signedAt?: string | null;
    signingMethod: Types.AppOrderSignerSigningMethodChoices;
    signingRole: Types.AppOrderSignerSigningRoleChoices;
    supervisingPhysician?: string | null;
    user?: { __typename?: 'UserSlimType'; id: string } | null;
  } | null> | null;
  documents: Array<{
    __typename?: 'DocumentType';
    id: string;
    originalFileName?: string | null;
    url?: string | null;
    metadata?: string | null;
  }>;
  emergencyContact?: {
    __typename?: 'PersonType';
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
  } | null;
  falconStatus?: { __typename?: 'OrderStatusHistoryType'; status: Types.AppOrderStatusHistoryStatusChoices } | null;
  intakeCategory?: { __typename?: 'IntakeCategoryType'; id: string; name: string } | null;
  intakeSubcategory?: {
    __typename?: 'IntakeSubcategoryType';
    id: string;
    name: string;
    syncId: string;
    activeProductForm?: {
      __typename?: 'IntakeSubcategoryProductFormType';
      id: string;
      formJson?: string | null;
    } | null;
  } | null;
  lineItems: Array<{
    __typename?: 'OrderLineItemType';
    id: string;
    displayText: string;
    note?: string | null;
    quantity: number;
    productItem?: { __typename?: 'OrderProductItemType'; id: string } | null;
    patientPrescriptionItem?: {
      __typename?: 'PatientPrescriptionItemType';
      lengthOfNeed: number;
      diagnoses?: Array<{ __typename?: 'DiagnosisType'; code: string; description?: string | null } | null> | null;
    } | null;
    hcpcsCode?: {
      __typename?: 'HcpcsType';
      code: string;
      imgUrl?: string | null;
      friendlyDescription?: string | null;
    } | null;
  }>;
  productItems?: Array<{
    __typename?: 'OrderProductItemType';
    id: string;
    productForm?: string | null;
    productOptions?: string | null;
    product?: {
      __typename?: 'ProductType';
      description: string;
      id: string;
      imageUrl?: string | null;
      name: string;
      subproducts?: Array<{
        __typename?: 'SubproductType';
        hcpcs: { __typename?: 'HcpcsType'; code: string };
      } | null> | null;
    } | null;
    productCatalogValidationRuleResults: Array<{
      __typename?: 'ProductCatalogValidationRuleResultType';
      id: string;
      result: Types.AppIntakeValidationRuleResultResultChoices;
      intakeValidationRule: {
        __typename?: 'IntakeValidationRuleType';
        lowContextSuccessText?: string | null;
        lowContextWarningText?: string | null;
        lowContextErrorText?: string | null;
      };
      productCatalogQuestionVersion: { __typename?: 'ProductCatalogQuestionVersionType'; questionJson: string };
    }>;
  } | null> | null;
  patient: {
    __typename?: 'PatientType';
    id: string;
    emailAddress?: string | null;
    dateOfBirth?: string | null;
    firstName: string;
    middleName: string;
    lastName: string;
    sex?: Types.SexEnum | null;
    heightInInches?: number | null;
    weightInPounds?: number | null;
    homePhoneNumber?: string | null;
    mobilePhoneNumber?: string | null;
    workPhoneNumber?: string | null;
    patientWaiver?: {
      __typename?: 'PatientWaiverType';
      waiver?: { __typename?: 'WaiverType'; id: string; name: string } | null;
    } | null;
    guardian?: { __typename?: 'PersonType'; firstName: string; lastName: string; phoneNumber?: string | null } | null;
    languagePreference?: { __typename?: 'LanguageType'; code: string; name: string } | null;
    primaryCareProvider?: {
      __typename?: 'ProviderType';
      id: string;
      firstName: string;
      lastName: string;
      npi: string;
    } | null;
    primaryCareProviderContact?: {
      __typename?: 'ContactType';
      id: string;
      emailAddress?: string | null;
      phoneNumber?: string | null;
      smsNumber?: string | null;
      faxNumber?: string | null;
    } | null;
    primaryInsurance?: {
      __typename?: 'PatientInsuranceType';
      status: Types.AppPatientInsuranceStatusChoices;
      policyNumber: string;
      insurer: {
        __typename?: 'InsurerType';
        id: string;
        insurerCategoryEnum?: Types.AppInsurerInsurerCategoryDirectChoices | null;
        name: string;
        display: string;
        requiresTitleXix: boolean;
        parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
      };
    } | null;
    secondaryInsurance?: {
      __typename?: 'PatientInsuranceType';
      status: Types.AppPatientInsuranceStatusChoices;
      policyNumber: string;
      insurer: {
        __typename?: 'InsurerType';
        id: string;
        insurerCategoryEnum?: Types.AppInsurerInsurerCategoryDirectChoices | null;
        name: string;
        display: string;
        requiresTitleXix: boolean;
        parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
      };
    } | null;
    tertiaryInsurance?: {
      __typename?: 'PatientInsuranceType';
      status: Types.AppPatientInsuranceStatusChoices;
      policyNumber: string;
      insurer: {
        __typename?: 'InsurerType';
        id: string;
        insurerCategoryEnum?: Types.AppInsurerInsurerCategoryDirectChoices | null;
        name: string;
        display: string;
        requiresTitleXix: boolean;
        parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
      };
    } | null;
    shippingAddress?: {
      __typename?: 'AddressType';
      addressLine1: string;
      addressLine2: string;
      city: string;
      state: string;
      postalCode: string;
      id: string;
    } | null;
  };
  practice?: { __typename?: 'PracticeSlimType'; id: string; name: string } | null;
  primaryDocument?: {
    __typename?: 'DocumentType';
    id: string;
    originalFileName?: string | null;
    url?: string | null;
  } | null;
  providerContact?: {
    __typename?: 'ContactType';
    emailAddress?: string | null;
    faxNumber?: string | null;
    phoneNumber?: string | null;
    smsNumber?: string | null;
  } | null;
  referringProvider?: {
    __typename?: 'ProviderType';
    firstName: string;
    lastName: string;
    npi: string;
    emailAddress?: string | null;
    faxNumber?: string | null;
    id: string;
    phoneNumber?: string | null;
    smsNumber?: string | null;
  } | null;
  shippingAddress?: {
    __typename?: 'AddressType';
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postalCode: string;
    id: string;
  } | null;
  deferredSupplierSuggestion?: {
    __typename?: 'DeferredOrderSupplierSuggestionType';
    faxNumber: string;
    id: string;
    phoneNumber?: string | null;
    supplierName: string;
  } | null;
  supplierSuggestions?: Array<{
    __typename?: 'OrderSupplierSuggestionType';
    id: string;
    source?: Types.AppOrderSupplierSuggestionSourceChoices | null;
    supplier: { __typename?: 'OptimizedNetworkSupplierType'; name: string; phoneNumber?: string | null };
  } | null> | null;
  addresses?: Array<{
    __typename?: 'OrderAddressType';
    id: string;
    addressType: Types.AppOrderAddressAddressTypeChoices;
    address?: {
      __typename?: 'AddressType';
      addressLine1: string;
      addressLine2: string;
      city: string;
      state: string;
      postalCode: string;
      id: string;
    } | null;
  } | null> | null;
  user?: { __typename?: 'UserTypeForAdminView'; id: string } | null;
};

export type IntakeOrderSignerFragment = {
  __typename?: 'OrderSignerType';
  id: string;
  emailAddress?: string | null;
  faxNumber?: string | null;
  fullName?: string | null;
  isRequestor: boolean;
  isSigner: boolean;
  isPrescriber: boolean;
  nameSigned?: string | null;
  phoneNumber?: string | null;
  smsNumber?: string | null;
  signedAt?: string | null;
  signingMethod: Types.AppOrderSignerSigningMethodChoices;
  signingRole: Types.AppOrderSignerSigningRoleChoices;
  supervisingPhysician?: string | null;
  user?: { __typename?: 'UserSlimType'; id: string } | null;
};

export const IntakeOrderSignerFragmentDoc = `
    fragment IntakeOrderSignerFragment on OrderSignerType {
  id
  emailAddress
  faxNumber
  fullName
  isRequestor
  isSigner
  isPrescriber
  nameSigned
  phoneNumber
  smsNumber
  signedAt
  signingMethod
  signingRole
  supervisingPhysician
  user {
    id
  }
}
    `;
export const IntakeOrderFragmentDoc = `
    fragment IntakeOrderFragment on OrderType {
  careManagerName
  appointment {
    appointmentDate
  }
  currentAnswer {
    productFormJson
    productAnswerJson
    clinicalFormJson
    clinicalAnswerJson
    intakeValidationRuleResults {
      id
      result
      intakeValidationRule {
        lowContextSuccessText
        lowContextWarningText
        lowContextErrorText
      }
      intakeQuestion {
        questionJson
      }
    }
  }
  currentSigners {
    ...IntakeOrderSignerFragment
  }
  dischargeAt
  documents {
    id
    originalFileName
    url
    metadata
  }
  emergencyContact {
    firstName
    lastName
    phoneNumber
  }
  emergencyContactRelationship
  falconStatus {
    status
  }
  friendlyId
  id
  intakeCategory {
    id
    name
  }
  intakeSubcategory {
    id
    name
    syncId
    activeProductForm {
      id
      formJson
    }
  }
  intakeType
  lineItems {
    id
    displayText
    note
    quantity
    productItem {
      id
    }
    patientPrescriptionItem {
      diagnoses {
        code
        description
      }
      lengthOfNeed
    }
    hcpcsCode {
      code
      imgUrl
      friendlyDescription
    }
  }
  productItems {
    id
    productForm
    productOptions
    product {
      ...ProductFragment
    }
    productCatalogValidationRuleResults {
      id
      result
      intakeValidationRule {
        lowContextSuccessText
        lowContextWarningText
        lowContextErrorText
      }
      productCatalogQuestionVersion {
        questionJson
      }
    }
  }
  note
  patient {
    ...IntakePatientFragment
  }
  practice {
    id
    name
  }
  primaryDocument {
    id
    originalFileName
    url
  }
  providerContact {
    emailAddress
    faxNumber
    phoneNumber
    smsNumber
  }
  referringProvider {
    ...ProviderFragment
  }
  shippingAddress {
    ...AddressFragment
  }
  shippingDetails
  serviceType
  deferredSupplierSuggestion {
    faxNumber
    id
    phoneNumber
    supplierName
  }
  supplierSuggestions(taken: true) {
    id
    source
    supplier {
      name
      phoneNumber
    }
  }
  addresses {
    id
    address {
      ...AddressFragment
    }
    addressType
  }
  urgent
  user {
    id
  }
}
    `;
