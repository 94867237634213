import { DetailCard, DetailItem } from './DetailCard';
import { getFormattedPhoneForDisplay } from '../utils/phoneUtils';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import Typography from '@mui/material/Typography';

type Provider = {
  firstName: string | undefined;
  lastName: string | undefined;
  npi: string | undefined;
  emailAddress: string | undefined;
  phoneNumber: string | undefined;
  faxNumber: string | undefined;
};

export const ProviderDetailCard = ({
  provider,
  showProviderName = true,
  endComponentSlot,
  sx,
}: {
  provider: Provider;
  showProviderName?: boolean;
  endComponentSlot?: React.ReactNode;
  sx?: SxProps;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: '15px',
        borderRadius: '2px',
        ...sx,
      }}
    >
      {showProviderName && (
        <Typography fontWeight="600" variant="subtitle2">
          {provider?.firstName} {provider?.lastName}
        </Typography>
      )}
      <DetailCard
        sx={{
          padding: 0,
          bgcolor: 'inherit',
        }}
      >
        <DetailItem label="NPI">{provider.npi ?? '-'}</DetailItem>
        <DetailItem label="Phone">
          {provider?.phoneNumber ? getFormattedPhoneForDisplay(provider.phoneNumber) : '-'}
        </DetailItem>
        <DetailItem label="Fax">
          {provider?.faxNumber ? getFormattedPhoneForDisplay(provider.faxNumber) : '-'}
        </DetailItem>
        <DetailItem label="Email">{provider?.emailAddress ?? '-'}</DetailItem>
        {endComponentSlot}
      </DetailCard>
    </Box>
  );
};
