import { ColorsV2 } from '../theme';
import { RiArrowDownSLine } from '@remixicon/react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps as MuiAccordionSummaryProps } from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

export interface AccordionProps extends Omit<MuiAccordionProps, 'variant'> {
  headerContent: React.ReactNode;
  fullWidth?: boolean;
}

export const Accordion = ({ headerContent, fullWidth, children, ...rest }: AccordionProps) => {
  return (
    <StyledAccordion {...rest}>
      <StyledAccordionSummary
        expandIcon={<RiArrowDownSLine color={ColorsV2.gray_medium} size={16} />}
        fullWidth={fullWidth}
      >
        {typeof headerContent === 'string' ? <Typography fontWeight="600">{headerContent}</Typography> : headerContent}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled((props: MuiAccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  backgroundColor: 'inherit',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
});

interface StyledAccordionSummaryProps extends MuiAccordionSummaryProps {
  fullWidth?: boolean;
}

const StyledAccordionSummary = styled((props: StyledAccordionSummaryProps) => <MuiAccordionSummary {...props} />, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})((props) => ({
  padding: 0,
  minHeight: 0,
  justifyContent: 'flex-start',
  border: 'none',
  '&:hover': {
    '& .MuiAccordionSummary-content': {
      textDecoration: 'underline',
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: '3px',
  },
  '& .MuiAccordionSummary-content': {
    flexGrow: props.fullWidth ? 1 : 0,
    margin: 0,
  },
}));

const StyledAccordionDetails = styled(MuiAccordionDetails)({
  padding: 0,
});

export default Accordion;
