import { alpha, darken, ThemeOptions } from '@mui/material/styles';
import {
  autocompleteClasses,
  ComponentsOverrides,
  inputBaseClasses,
  inputLabelClasses,
  linearProgressClasses,
  outlinedInputClasses,
  selectClasses,
  Theme,
} from '@mui/material';
import { Colors, ColorsV2 } from './colors';
// eslint-disable-next-line no-restricted-imports
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pickersDayClasses } from '@mui/x-date-pickers/PickersDay';
import { pickersYearClasses } from '@mui/x-date-pickers';
import { radioClasses } from '@mui/material/Radio';
import { RiCalendarLine } from '@remixicon/react';
import { switchClasses } from '@mui/material/Switch';
import { tabClasses } from '@mui/material/Tab';
import theme from './theme';
import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';

type MuiSelectStyleOverridesWithRoot = ComponentsOverrides<Theme>['MuiSelect'] & {
  root: OverridesStyleRules<'root', 'MuiSelect', Theme>['root'];
};

export const components: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: {
      variant: 'primary',
    },
    variants: [
      {
        props: { variant: 'primary' },
        style: {
          backgroundColor: ColorsV2.neon_green,

          '&:hover': {
            backgroundColor: darken(ColorsV2.neon_green, 0.05),
          },
        },
      },
      {
        props: { variant: 'secondary' },
        style: ({ theme }) => ({
          border: '1px solid hsla(200, 11%, 64%, 0.5)',
          color: theme.palette.text.primary,
          '&:disabled': {
            color: 'rgba(0, 35, 52, 0.3)',
          },
        }),
      },
      {
        props: { variant: 'primary', color: 'warning' },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,

          '&:hover': {
            backgroundColor: darken(theme.palette.error.main, 0.05),
            color: theme.palette.common.white,
          },
        }),
      },
      {
        props: { variant: 'secondary', color: 'warning' },
        style: ({ theme }) => ({
          color: theme.palette.error.main,
          borderColor: theme.palette.error.main,

          '&:hover': {
            color: theme.palette.error.main,
          },
        }),
      },
      {
        props: { variant: 'text', color: 'warning' },
        style: ({ theme }) => ({
          color: theme.palette.error.main,

          '&:hover': {
            color: theme.palette.error.main,
          },
        }),
      },
    ],
    styleOverrides: {
      root: {
        color: ColorsV2.navy,
        textTransform: 'none',
        minWidth: 'auto',
        fontFamily: 'Inter',
        fontWeight: 600,
        borderRadius: 99,
      },
      sizeLarge: {
        height: '52px',
        padding: '12px 22px',
        fontSize: '18px',
      },
      sizeMedium: {
        height: '37px',
        padding: '8.5px 18px',
        fontSize: '14px',
      },
      sizeSmall: {
        height: '30px',
        padding: '5.5px 12px',
        fontSize: '13px',
      },
      text: ({ theme }) => ({
        ':hover': {
          backgroundColor: theme?.palette?.action?.hover,
        },
      }),
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
      colorPrimary: {
        backgroundColor: ColorsV2.white,
      },
    },
  },
  MuiStack: {
    defaultProps: {
      useFlexGap: true,
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        backgroundColor: 'rgba(0, 0, 0, 0.15)',

        ...(ownerState.invisible === true && {
          backgroundColor: 'transparent',
        }),
      }),
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        color: ColorsV2.navy,
      },
    },
  },
  MuiBadge: {
    defaultProps: {
      color: 'primary',
    },
    variants: [
      {
        props: { color: 'primary' },
        style: {
          '.MuiBadge-badge': {
            color: ColorsV2.white,
          },
        },
      },
    ],
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: '12px',
        backgroundColor: ColorsV2.navy,
      },
      arrow: {
        color: ColorsV2.navy,
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
  },
  MuiLinearProgress: {
    variants: [
      {
        props: { color: 'primary' },
        style: {
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: ColorsV2.gray_light,
          },
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: ColorsV2.neon_green,
          },
        },
      },
    ],
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        height: 'auto',
        width: 'auto',
      },
      switchBase: {
        [`&.${switchClasses.track}`]: {
          backgroundColor: `${ColorsV2.gray_light} !important`,
        },
        [`&.${switchClasses.checked}`]: {
          transform: 'translateX(19px)',
          '& + $track': {
            backgroundColor: `${ColorsV2.gray_medium} !important`,
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        marginLeft: '5px',
        backgroundColor: ColorsV2.white,
        height: '18px',
        width: '18px',
        marginTop: '6px',
      },
      track: {
        borderRadius: '100px',
        height: '24px',
        width: '42px',
      },
    },
  },
  MuiContainer: {
    defaultProps: {
      maxWidth: false,
    },
  },
  MuiPaper: {
    styleOverrides: {
      rounded: {
        borderRadius: '3px',
      },
    },
  },
  MuiTabs: {
    defaultProps: {
      textColor: 'secondary',
    },
    styleOverrides: {
      root: {
        minHeight: '19px',
      },
      indicator: {
        backgroundColor: Colors.neonGreen,
      },
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        flexDirection: 'row',
        minHeight: '19px',
        marginRight: '8px',
        padding: '10px',
        borderRadius: '5px',
        textTransform: 'none',
        minWidth: 'auto',
        fontSize: '13px',
        fontWeight: 600,
        color: ColorsV2.navy,
        [`&.${tabClasses.selected}`]: {
          backgroundColor: alpha(ColorsV2.navy, 0.09),

          '&:hover': {
            backgroundColor: alpha(ColorsV2.navy, 0.09),
          },
        },
        '&.Mui-focusVisible': {
          backgroundColor: alpha(ColorsV2.navy, 0.07),
        },
        '&:hover': {
          backgroundColor: alpha(ColorsV2.navy, 0.07),
        },
      },
      labelIcon: {
        minHeight: '20px',
      },
      iconWrapper: {
        fontSize: 13,
        alignItems: 'flex-start',
        fontWeight: 600,
        flexDirection: 'row',
        '& :first-of-type svg': {
          marginRight: '9px',
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        color: ColorsV2.navy,
      },
    },
  },
  MuiModal: {
    defaultProps: {
      disableEnforceFocus: true,
    },
  },
  MuiRadio: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      colorPrimary: {
        color: ColorsV2.gray_medium,
        [`&.${radioClasses.disabled}`]: {
          color: Colors.disabledRadio,
        },
        '&:hover': {
          color: ColorsV2.gray_medium,
        },
      },
      root: {
        [`&.${radioClasses.checked} .MuiSvgIcon-root:nth-of-type(2)`]: {
          transform: 'scale(0.8)',
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: '16px',
      },
      inputSizeSmall: {
        fontSize: '13px',
      },
      input: ({ ownerState }) => {
        if (!ownerState) {
          return undefined;
        }

        return {
          '&::placeholder': {
            color: ColorsV2.gray_medium,
            opacity: 1,
          },

          [`&.${inputBaseClasses.inputAdornedEnd}`]: {
            paddingRight: '0px',
          },

          [`.${inputBaseClasses.disabled} &`]: {
            pointerEvents: 'auto',
            cursor: 'not-allowed',
          },
        };
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: ({ ownerState }) => {
        return {
          [`& .${selectClasses.select}`]: {
            padding: ownerState.size === 'small' ? '11px 14px 5px 14px' : '15px 14px 5px 14px',
            ...(!!ownerState.label && {
              padding: ownerState.size === 'small' ? '16.5px 14px 5px 14px' : '21px 14px 5px 14px',
            }),
          },
        };
      },
    } as MuiSelectStyleOverridesWithRoot,
  },
  MuiOutlinedInput: {
    defaultProps: {
      notched: false,
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        if (!theme) {
          return {};
        }

        return {
          [`&.${outlinedInputClasses.disabled}`]: {
            color: ColorsV2.navy,
            WebkitTextFillColor: ColorsV2.navy,
            opacity: 0.6,
            background: ColorsV2.gray_light,
          },

          '&:hover': {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: Colors.borderDeselected,
            },
          },

          [`&.${outlinedInputClasses.focused}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: ColorsV2.gray_dark,
              borderWidth: '1px',
            },
          },

          [`&.${outlinedInputClasses.error}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.error.main,
            },
          },

          [`&.${outlinedInputClasses.error}:hover`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: darken(theme.palette.error.main, 0.2),
            },
          },

          [`&.${outlinedInputClasses.error}.${outlinedInputClasses.focused}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.error.main,
            },
          },

          [`&.${outlinedInputClasses.adornedEnd}`]: {
            paddingRight: ownerState.size === 'small' ? '15.5px' : '18.5px',
          },

          backgroundColor: ColorsV2.white,
          borderColor: 'rgba(0, 35, 52, 0.2)',
          borderRadius: '2px',
          textOverflow: 'ellipsis',
        };
      },
      notchedOutline: {
        borderWidth: '1px',
        borderColor: Colors.borderDeselected,
      },
      multiline: {
        padding: 0,
      },
      inputSizeSmall: {
        height: '43px',

        [`&.${selectClasses.select}`]: {
          height: '43px',
        },
      },
      input: ({ ownerState }) => {
        if (!ownerState) {
          return undefined;
        }

        return {
          boxSizing: 'border-box',
          height: '52px',

          [`&.${outlinedInputClasses.disabled}`]: {
            color: ColorsV2.navy,
            WebkitTextFillColor: ColorsV2.navy,
          },
        };
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        [`& .${outlinedInputClasses.input}`]: {
          ...((ownerState.label ?? ownerState.inputProps?.['label'] ?? ownerState.multiline) && {
            padding: '16.5px 14px 5px 14px',
          }),
        },
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        [`& .${outlinedInputClasses.root}`]: {
          [`& .${autocompleteClasses.input}`]: {
            height: '34px',
          },

          [`&.${inputBaseClasses.sizeSmall}`]: {
            [`& .${autocompleteClasses.input}`]: {
              height: '31px',
            },
          },
        },
      },

      listbox: {
        border: '1px solid',
        borderColor: theme.colors.border,
        boxShadow: `0 0 0 1px ${theme.colors.selectBorder}`,
        borderRadius: '5px',
        maxHeight: '33vh',
      },
      popper: {
        zIndex: '1400',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      sizeSmall: {
        [`&.${inputLabelClasses.outlined}`]: {
          fontSize: '13px',
          transform: 'translate(14px, 12.5px) scale(1)',
          color: ColorsV2.gray_medium,

          [`&.${inputLabelClasses.shrink}`]: {
            fontSize: '13px',
            transform: 'translate(15px, 5px) scale(0.75)',
            color: ColorsV2.gray_dark,
          },
        },
      },
      root: {
        color: ColorsV2.gray_dark,

        [`&.${inputLabelClasses.focused}`]: {
          color: ColorsV2.gray_dark,
        },

        [`&.${inputLabelClasses.error}`]: {
          color: ColorsV2.gray_dark,
        },

        [`&.${inputLabelClasses.disabled}`]: {
          opacity: 0.4,
          color: ColorsV2.gray_dark,
        },

        [`&.${inputLabelClasses.outlined}`]: {
          fontSize: '16px',
          transform: 'translate(14px, 15px) scale(1)',
          color: ColorsV2.gray_medium,

          [`&.${inputLabelClasses.shrink}`]: {
            fontSize: '13px',
            transform: 'translate(15px, 6px) scale(0.75)',
            color: ColorsV2.gray_dark,
          },
        },
      },
    },
  },
  MuiFormHelperText: {
    defaultProps: {
      variant: 'standard',
    },
    styleOverrides: {
      root: {
        fontSize: '12px',
        textTransform: 'none',
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        width: '100%',
        wordBreak: 'break-word',
      },
      label: {
        marginRight: 'auto',
        whiteSpace: 'normal',
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paperWidthXs: ({ ownerState }) => ({
        maxWidth: ownerState.fullScreen ? undefined : '520px',
      }),
      paperWidthSm: ({ ownerState }) => ({
        maxWidth: ownerState.fullScreen ? undefined : '590px',
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        paddingTop: '10px',
        paddingBottom: '5px',
        paddingLeft: '40px',
        paddingRight: '40px',
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        '&:first-of-type': {
          paddingTop: '25px',
        },
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingTop: '25px',
        paddingBottom: '25px',
      },
      dividers: {
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingTop: '25px',
        paddingBottom: '25px',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        paddingLeft: '40px',
        paddingRight: '40px',
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      // Workaround to resolve issues with select inside dialogs caused by workaround above
      root: {
        zIndex: '1400 !important' as any,
      },
    },
  },
  MuiDatePicker: {
    defaultProps: {
      closeOnSelect: true,
      slots: {
        openPickerIcon: () => <RiCalendarLine color={ColorsV2.navy} size="16px" />,
        actionBar: () => null,
        toolbar: () => null,
      },
    },
  },
  MuiMobileDatePicker: {
    defaultProps: {
      slotProps: {
        textField: {
          InputProps: {
            endAdornment: <RiCalendarLine color={ColorsV2.navy} size="16px" />,
          },
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      root: {
        backgroundColor: '#264756',

        '*': {
          color: ColorsV2.white,
        },
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        [`&.${pickersDayClasses.selected}`]: {
          backgroundColor: '#264756',
          color: ColorsV2.white,

          '&:hover': {
            backgroundColor: darken('#264756', 0.2),
          },

          '&:focus': {
            backgroundColor: '#264756',
          },
        },
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      yearButton: {
        [`&.${pickersYearClasses.selected}`]: {
          backgroundColor: '#264756',
          color: ColorsV2.white,

          '&:hover': {
            backgroundColor: darken('#264756', 0.2),
          },

          '&:focus': {
            backgroundColor: '#264756',
          },
        },
      },
    },
  },
};
