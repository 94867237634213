import { forwardRef, useMemo } from 'react';
import { theme, UserAvatarColors } from '../theme';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

interface UserAvatarProps extends AvatarProps {
  user?: {
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  };
}

export const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(({ user, ...props }, ref) => {
  const { avatarColor, userInitials } = useMemo(() => {
    if (!user) {
      return {
        avatarColor: undefined,
        userInitials: undefined,
      };
    }

    return {
      avatarColor: getUserAvatarColor(user.lastName),
      userInitials: getUserInitials(user.firstName, user.lastName, user.email),
    };
  }, [user]);

  return (
    <Avatar
      {...props}
      ref={ref}
      sx={{
        height: '28px',
        width: '28px',
        backgroundColor: avatarColor,
        ...props.sx,
      }}
    >
      {!!userInitials && (
        <Typography color={theme.colors.white} textTransform="uppercase">
          {userInitials}
        </Typography>
      )}
    </Avatar>
  );
});

export function getUserInitials(
  firstName: string | null | undefined,
  lastName: string | null | undefined,
  email: string | null | undefined,
) {
  const firstLetter = firstName?.charAt(0) ?? email?.charAt(0) ?? '';

  let secondLetter = lastName?.charAt(0) ?? '';

  // check if the user has parens in their last name
  if (secondLetter.charCodeAt(0) < 65) {
    secondLetter = '';
  }

  return `${firstLetter}${secondLetter}`;
}

export function getUserAvatarColor(lastName: string | null | undefined): string {
  if (!lastName) {
    return UserAvatarColors.orange;
  }

  // map the 13 colors to the 26 letters of the alphabet
  const colorIndex = Math.floor((lastName.toLowerCase().charCodeAt(0) - 97) / 2);
  const key = Object.keys(UserAvatarColors)[colorIndex] as keyof typeof UserAvatarColors;

  return UserAvatarColors[key];
}

// For when we need to clamp the number of avatars displayed in a group
export function clampAvatars<T>(avatars: T[], options: { max?: number; total?: number } = { max: 6 }) {
  const { max = 5, total } = options;
  const totalAvatars = total ?? avatars.length;

  let clampedMax = max < 2 ? 2 : max;

  if (totalAvatars === clampedMax) {
    clampedMax += 1;
  }

  clampedMax = Math.min(totalAvatars + 1, clampedMax);

  const maxAvatars = Math.min(avatars.length, clampedMax - 1);
  const surplus = Math.max(totalAvatars - clampedMax, totalAvatars - maxAvatars, 0);

  return { avatars: avatars.slice(0, maxAvatars), surplus };
}
