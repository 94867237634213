import { alpha } from '@mui/material/styles';
import { theme } from '@tomorrow/ui/theme';
import Chip from '@mui/material/Chip';
import React from 'react';

interface StatusBadgeProps {
  color?: string;
  icon?: React.ReactElement;
  label: React.ReactNode;
}

const StatusBadge = React.forwardRef((props: StatusBadgeProps, ref: React.Ref<HTMLDivElement>) => {
  const { color, icon, label, ...rest } = props;

  return (
    <span {...rest} ref={ref}>
      <Chip
        clickable={false}
        icon={icon}
        label={label}
        sx={{
          backgroundColor: color ? alpha(color, 0.1) : 'rgba(0, 0, 0, 0)',
          borderRadius: '3px',
          height: '25px',

          '& .MuiChip-icon': {
            color: color ?? theme.colors.label,
            marginRight: '0',
            width: '15px',
            height: '15px',
          },
          '& .MuiChip-label': (th) => ({
            ...th.typography.body2,
            color: theme.colors.label,
            padding: '3px 5px',
            textTransform: 'capitalize',
          }),
        }}
      />
    </span>
  );
});

export default StatusBadge;
