import { theme } from '@tomorrow/ui/theme';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import React, { useState } from 'react';

export interface Option {
  disabled?: boolean;
  label?: React.ReactNode;
  value?: string | number;
}

type SelectProps = MuiSelectProps & {
  errorText?: string;
  options?: Option[];
};

export const Select = React.forwardRef<unknown, SelectProps>((props, ref) => {
  const { errorText, options, ...selectProps } = props;
  const { children, disabled, error, id, label, MenuProps, placeholder, required, size = 'small', value } = selectProps;
  const labelId = `${id}-label`;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FormControl disabled={disabled} error={error} fullWidth required={required} size={size} variant="outlined">
      <InputLabel id={labelId}>{label}</InputLabel>
      <MuiSelect
        {...selectProps}
        inputRef={ref}
        labelId={labelId}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            sx: {
              boxShadow: `0 0 0 1px ${theme.colors.selectBorder}`,
              borderRadius: '5px',
              border: `1px solid ${theme.colors.border}`,
              maxHeight: '33%',
              marginTop: '0',
              '& .MuiList-root': {
                padding: '8px 0',
              },

              '& .MuiMenuItem-root': {
                '&.alternative': {
                  width: '331px',
                  padding: '16px 30px 16px 20px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'block',
                  fontSize: '16px',

                  '&.Mui-selected': {
                    background: 'rgba(9, 177, 117, 0.05)',
                    fontWeight: 600,
                    '&::after': {
                      content:
                        'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik00LjMzNTk0IDkuMzMzOThMNy42NjkyNyAxMi42NjczIiBzdHJva2U9IiM3RUIwQjUiIHN0cm9rZS13aWR0aD0iMS4yNSIvPg0KPHBhdGggZD0iTTYuODM1OTQgMTIuNjY2TDEzLjUwMjYgNS45OTkzNSIgc3Ryb2tlPSIjN0VCMEI1IiBzdHJva2Utd2lkdGg9IjEuMjUiLz4NCjwvc3ZnPg==")',
                      position: 'absolute',
                      right: '23px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    },
                  },
                },
                '&.small': {
                  color: '#5D7179',
                  margin: 0,
                  width: 'inherit',
                  padding: '13px 30px 13px 20px',
                  '& .MuiInputBase-input': {
                    color: '#5D7179',
                  },
                },
              },
            },
          },
          ...MenuProps,
        }}
        native={false}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        size={size}
        value={value || (label ? '' : 'noselection')}
      >
        {!label && !value && !isOpen && (
          <option disabled value="noselection">
            {`${placeholder ?? ''}${placeholder && required ? ' *' : ''}` || 'Select'}
          </option>
        )}
        {options?.map((option) => (
          <MenuItem key={option.value} disabled={option.disabled} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
        {children}
      </MuiSelect>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
});
