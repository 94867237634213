import { z } from 'zod';

const createEnv = () => {
  const clientPrefix = 'REACT_APP_';
  const runtimeEnv = import.meta.env;
  const isProd = runtimeEnv.MODE === 'production';

  const EnvSchema = z.object({
    REACT_APP_ENV: z.enum(['production', 'staging', 'preview', 'qa', 'development']),
    REACT_APP_ECOMMERCE_GRAPHQL_API_URL: z.string().url().min(1),
    REACT_APP_COMMIT_REF: z.string().min(1).optional(),
    REACT_APP_AUTH0_AUDIENCE: z.string().min(1),
    REACT_APP_AUTH0_DOMAIN: z.string().min(1),
    REACT_APP_AUTH0_CLIENT_ID: z.string().min(1),
    REACT_APP_MIXPANEL_TOKEN: z.string().min(1).optional(),
    REACT_APP_DATADOG_RUM_APPLICATION_ID: isProd ? z.string().min(1) : z.string().min(1).optional(),
    REACT_APP_DATADOG_RUM_CLIENT_TOKEN: isProd ? z.string().min(1) : z.string().min(1).optional(),
    REACT_APP_DATADOG_RUM_SAMPLE_RATE: z.number().int().min(0).default(100),
    REACT_APP_DATADOG_LOGS_SAMPLE_RATE: z.number().int().min(0).default(100),
    REACT_APP_CONTENTFUL_SPACE_ID: z.string().min(1),
    REACT_APP_CONTENTFUL_ACCESS_TOKEN: z.string().min(1),
    REACT_APP_CONTENTFUL_ENVIRONMENT: z.string().min(1),
    REACT_APP_GOOGLE_ANALYTICS_KEY: z.string().min(1).optional(),
    REACT_APP_SMARTY_STREETS_PUBLIC_KEY: z.string().min(1),
    REACT_APP_SENTRY_DSN: z.string().optional(),
    REACT_APP_SENTRY_RRWEB_REPLAYS_DISABLED: z.boolean().default(false),
    REACT_APP_CHAMELEON_KEY: z.string().optional(),
  } satisfies Record<`${typeof clientPrefix}${string}`, z.ZodTypeAny>);

  const envVars = Object.entries(runtimeEnv).reduce<Record<string, string>>((acc, curr) => {
    const [key, value] = curr;
    if (key.startsWith(clientPrefix)) {
      acc[key] = value;
    }

    return acc;
  }, {});

  const parsedEnv = EnvSchema.safeParse(envVars);

  if (!parsedEnv.success) {
    throw new Error(
      `Invalid env provided.
The following variables are missing or invalid:
${Object.entries(parsedEnv.error.flatten().fieldErrors)
  .map(([k, v]) => `- ${k}: ${v.toString()}`)
  .join('\n')}
`,
    );
  }

  return parsedEnv.data;
};

export const env = createEnv();
