import { CareCoordinatorOmniDrawer } from './CareCoordinatorOmniDrawer';
import { FeatureFlagged } from '../../router/FeatureFlagged';
import { Layout } from '../../layouts/Layout';
import { lazily } from 'react-lazily';
import { Navigate, Route } from 'react-router-dom';
import { NavigateToFirstOrgByType } from '../../router/NavigateToFirstOrgByType';
import { OrgTypes } from '../../api-clients/falcon-api/graphql/types.generated';
import { OrgUserContainer } from '../../router/OrgUserContainer';
import { PATHS } from '../../router/paths';
import { retry } from '../../router/retry';

const { default: CareCoordinatorSettings } = lazily(() => retry(() => import('./[orgId]/settings')));
const { CareCoordinatorHeader } = lazily(() => retry(() => import('./CareCoordinatorHeader')));
const { Notifications } = lazily(() => retry(() => import('./[orgId]/settings/notifications')));
const { default: MyTeam } = lazily(() => retry(() => import('./[orgId]/settings/my-team')));
const { IntakeWizardStep } = lazily(() => retry(() => import('../../components/intake-wizard/IntakeWizard')));
const { CareCoordinatorOrderWizard } = lazily(() =>
  retry(() => import('../../screens/IntakeOrderWizard/CareCoordIntake/CareCoordinatorOrderWizard')),
);
const { PatientList } = lazily(() => retry(() => import('../../components/PatientList/PatientList')));
const { CareCoordinatorOrderList } = lazily(() =>
  retry(() => import('../../components/CareCoordinators/CareCoordinatorOrderList')),
);

export const careCoordinatorRoutes = (
  <Route path="care-coordinators/*">
    <Route element={<NavigateToFirstOrgByType orgType={OrgTypes.CareCoordination} />} index />

    <Route element={<OrgUserContainer orgType={OrgTypes.CareCoordination} />} path=":orgId/*">
      <Route element={<Layout />}>
        <Route element={<CareCoordinatorOrderWizard />} path="referrals/:orderId/intake/*">
          <Route element={<IntakeWizardStep />} path=":step" />
          <Route element={<Navigate replace to="member" />} index />
        </Route>
      </Route>

      <Route element={<Layout drawer={<CareCoordinatorOmniDrawer />} header={<CareCoordinatorHeader banner />} />}>
        <Route element={<Navigate replace to="referrals" />} index />
        <Route element={<CareCoordinatorOrderList />} path="referrals" />
        <Route element={<FeatureFlagged enabled={<PatientList />} flag="rp_patients_screen" />} path="members" />
        <Route element={<Navigate replace to={PATHS.NOT_FOUND} />} path="*" />
      </Route>

      <Route element={<Layout drawer={<CareCoordinatorOmniDrawer />} header={<CareCoordinatorHeader />} />}>
        <Route element={<CareCoordinatorSettings />} path="settings/*">
          <Route element={<Notifications />} path="notifications" />
          <Route element={<MyTeam />} path="my-team" />
          <Route element={<Navigate replace to="notifications" />} index />
        </Route>
      </Route>
    </Route>
    <Route element={<Navigate replace to=".." />} path="*" />
  </Route>
);
